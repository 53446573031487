<template>
  <h6
      v-if="
                    variety.color || variety.attributes != ''
                  "
  >
                  <span v-if="variety.color">
                    رنگ : {{ variety.color.name }}
                    {{ variety.attributes != "" ? "  |  " : "" }}
                  </span>
    <span v-if="variety.attributes != ''">
                    <span
                        v-for="(attr, index) in variety.attributes"
                        :key="index"
                    >
                      {{ attr.label + ": " + attr.pivot.value }}
                      {{
                        index != variety.attributes.length - 1
                            ? "  |  "
                            : ""
                      }}
                    </span>
                  </span>
  </h6>
</template>

<script>
export default {
  name: "VarietyInfoText",
  props: {
    variety: Object
  }
}
</script>

<style scoped>

</style>
