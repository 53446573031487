<template>
  <main class="mt-5 admin-slider">
        <span id="add-btn">
            <button :disabled="hasPermission('write_slider') ? false : true" @click="newSlider" v-b-modal.slider-modal
                    class="btn btn-success">اسلایدر جدید</button>
        </span>
    <b-tooltip v-if="!hasPermission('write_slider')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">اسلایدر</h5>
      </div>
      <div class="card-body ">
        <table :style="disabled ? 'opacity: 0.8' : ''"
               class="table table-responsive-sm table-bordered table-hover">
          <thead>
          <tr>
            <th scope="col"><i class="fas fa-arrows-alt-v"></i> شناسه</th>
            <th scope="col">عنوان</th>
            <th scope="col">تصویر</th>
            <th scope="col">وضعیت</th>
            <th scope="col">تاریخ ثبت</th>
            <!-- <th scope="col">فایل ها</th> -->
            <th scope="col">ویرایش</th>
            <th scope="col">حذف</th>
          </tr>
          </thead>
          <draggable v-model="sliders" tag="tbody" @change="sortSlider" v-bind="dragOptions">
            <tr class='flip-list' v-for="(item, index) in sliders" :key="item.id">
              <td scope="row">{{ item.id }}</td>
              <td scope="row">{{ item.title }}</td>
              <td scope="row">
                <a v-if="item.image" target="_blank">
                  <ImageHolder style="max-height: 70px" v-if="item.image" :image="item.image">
                    <img :src="item.image.url">
                  </ImageHolder>
                </a>
              </td>

              <td scope="row">
                <span v-if="item.status == 1 " class="badge badge-success status">فعال</span>
                <span v-else-if="item.status == 0" class="badge badge-danger status">غیرفعال</span>
              </td>
              <td scope="row">{{ item.created_at | persianDate }}</td>
              <td>
                                <span :id="'edit-btn-' + item.id">
                                <a :disabled="disabled || !hasPermission('modify_slider') ? true : false "
                                   class="action-btns1  pt-1 px-2" @click="editItem(index)">
                                    <i class="feather feather-edit  text-primary"></i>
                                </a>
                                </span>
                <b-tooltip v-if="!hasPermission('modify_slider')" :target="'edit-btn-' + item.id"
                           triggers="hover">
                  شما به این بخش دسترسی ندارید
                </b-tooltip>

              </td>
              <td>
                                <span :id="'delete-btn-' + item.id">
                                    <a :disabled="!hasPermission('delete_slider') ? true : false "
                                       class="action-btns1  pt-1 px-2 " @click="deleteItem(item.id)">
                                        <i class="feather feather-trash-2 text-danger"></i>
                                    </a>
                                  </span>
                <b-tooltip v-if="!hasPermission('delete_slider')" :target="'delete-btn-' + item.id" triggers="hover">
                  شما به این بخش دسترسی ندارید
                </b-tooltip>
              </td>
            </tr>
          </draggable>
        </table>
        <Loading v-if="sliders == null"/>
        <div class="alert alert-danger text-right" v-if="sliders == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </div>
    </div>
    <slider-form :mode="mode" :linkables="linkables" :group="group" v-model="slider"
                 :labelMultiselect="modelLable"></slider-form>
    <!-- <SliderForm :mode="mode" :linkables="linkables" :group="group" v-model="slider"
        :labelMultiselect="modelLable" /> -->
  </main>
</template>
<script>
import SliderImpl from '@@/core/pages/slider/Slider/SliderImpl'
export default {
  extends: SliderImpl
}
</script>

<style scoped src="@@/core/pages/slider/Slider/SliderStyle.css"></style>
