<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش  سفارش" : " سفارش جدید" }}
        </h5>
        <div style="flex:1"></div>
        <Back />
      </div>
      <div class="card-body">
        <orderForm
          ref="orderForm"
          :mode="$route.params.id ? 'edit' : 'create'"
          :url="url"
          v-model="order"
          :customers="customers"
          :shippings="shippings"
          :provinces="provinces"
        />
      </div>
    </div>
  </main>
</template>
<script>
import OrderCreateEditImpl from '@@/core/pages/order/OrderCreateEdit/OrderCreateEditImpl'
export default {
  extends: OrderCreateEditImpl
}
</script>
