<template>
  <div class="app sidebar-mini" :class="sidebarStatus()">

    <!---Global-loader-->
    <!-- <div id="global-loader" >
      <img src="@@/assets/admin/images/svgs/loader.svg" alt="loader">
    </div>
   -->
    <div class="page">
      <div class="page-main">

        <!--aside open-->
        <Sidebar></Sidebar>
        <!--aside closed-->

        <div class="app-content main-content">
          <div class="side-app">
            <!--app header-->
            <Header></Header>
            <!--/app header-->
            <router-view :key="$route.path"></router-view>
          </div>
        </div><!-- end app-content-->
      </div>

      <!--Footer-->
      <Footer></Footer>
      <!-- End Footer-->

      <!--Sidebar-right-->
        <Notifications></Notifications>
      <!--/Sidebar-right-->

      <!--Clock-IN Modal -->
      <div class="modal fade" id="clockinmodal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"><span class="feather feather-clock  mr-1"></span>Clock In</h5>
              <button class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="countdowntimer"><span id="clocktimer" class="border-0"></span></div>
              <div class="form-group">
                <label class="form-label">Note:</label>
                <textarea class="form-control" rows="3">Some text here...</textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-outline-primary" data-dismiss="modal">Close</button>
              <button class="btn btn-primary">Clock In</button>
            </div>
          </div>
        </div>
      </div>
      <!-- End Clock-IN Modal  -->

      <!--Change password Modal -->
      <div class="modal fade" id="changepasswordnmodal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">تغییر رمز عبور</h5>
              <button class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label class="form-label">رمز عبور قبلی</label>
                <input type="password" class="form-control" placeholder="پسورد قبلی" :disabled="disabled" v-model="oldPassword">
              </div>
              <div class="form-group">
                <label class="form-label">رمز عبور جدید</label>
                <input type="password" class="form-control" placeholder="پسورد جدید" :disabled="disabled" v-model="password">
              </div>
              <div class="form-group">
                <label class="form-label">تکرار رمز عبور جدید</label>
                <input type="password" class="form-control" placeholder="تکرار پسورد جدید" :disabled="disabled" v-model="confirmPassword">
              </div>
            </div>
            <div class="modal-footer">
              <a href="#" class="btn btn-outline-primary" data-dismiss="modal">لغو</a>
              <a @click="changePassword" class="btn btn-primary" :disabled="disabled">ثبت</a>
            </div>
          </div>
        </div>
      </div>
      <!-- End Change password Modal  -->

    </div>

    <!-- Back to top -->
    <a href="#top" id="back-to-top"><span class="feather feather-chevrons-up"></span></a>
    <Gallery ref="gallery" :gallery="images" />
  </div>
</template>

<script>
import IndexImpl from '@@/core/pages/Index/IndexImpl'
export default {
  extends: IndexImpl
}
</script>
<style src="@@/core/pages/Index/IndexStylePublic.css"></style>
<style lang="scss">
#test{
  color:red;
}
</style>
