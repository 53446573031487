import Loading from "@@/core/components/shared/Loading/Loading";
import Back from "@@/core/components/shared/Back/Back";
import {
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
  BModal,
  BTable,
  BTooltip,
} from "bootstrap-vue";

export default {
  components: {
    Loading,
    Back,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BModal,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      provinces: null,
      cities: [],
      url: "admin/shippings",
      shipping: null,
      fields: [
        {
          key: "province",
          label: "استان",
        },
        {
          key: "cities",
          label: "شهرها",
        },
        {
          key: "price",
          label: "هزینه ارسال",
        },
      ],
      cities_fields: [
        {
          key: "name",
          label: "شهر",
        },
        {
          key: "price",
          label: "هزینه ارسال (تومان)",
        },
      ],
      disabled: false,
      selected_cities: [],
    };
  },
  mounted() {
    this.loadCities();
  },
  methods: {
    citiesPrice(item) {
      console.log(item);
      this.selected_cities = item;
      this.$root.$emit("bv::show::modal", "cities-modal");
    },
    async loadCities() {
      try {
        const response = await this.$axios.get("admin/cities?all=1");
        this.cities = response.data.data.cities.map((item) => {
          item.pivot = {
            price: 1000,
          };
          return item;
        });
        this.$route.params.id ? this.loadProvinces() : "";
      } catch (error) {
        this.$root.notify(error);
      }
    },
    async loadProvinces() {
      try {
        const response = await this.$axios.get(
          this.url + `/${this.$route.params.id}`
        );
        let hasCities = response.data.data.shipping.cities;
        let provinces = response.data.data.shipping.provinces;
        let cities =
          provinces != ""
            ? provinces.map((item) => {
                item.cities = this.cities.filter(
                  (c) => c.province_id == item.id
                );
                item.selected_cities =
                  hasCities != ""
                    ? hasCities.filter((el) => el.province_id == item.id)
                    : null;
                return item;
              })
            : "";

        this.provinces = cities;
      } catch (error) {
        this.$root.notify(error);
      }
    },
    async submit() {
      this.disabled = true;
      try {
        let cities = [];
        console.log(this.provinces);
        this.provinces.forEach((p) => {
          if (p.selected_cities)
            p.selected_cities.forEach((c) => cities.push(c));
        });
        const formData = new FormData(this.$refs.form);
        cities.forEach((item, index) => {
          formData.append(`cities[${index}][id]`, item.id);
          formData.append(`cities[${index}][price]`, item.pivot.price);
        });
        const response = await this.$axios.post(
          this.url + `/${this.$route.params.id}/assign-cities`,
          formData
        );
        this.$root.notify(response.data.message, "success");
        // this.$root.$emit('bv::hide::modal', 'shipping-modal')
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
  },
};
