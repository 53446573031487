<template>
  <b-modal
    :ok-disabled="disabled"
    :cancel-disabled="disabled"
    @ok="submit"
    class="in-form-modal"
    ok-title="ثبت"
    cancel-title="لغو"
    id="advertise-modal"
    :title="mode == 'edit' ? 'ویرایش بنر' : 'بنر جدید'"
  >
    <form ref="form" @submit.prevent="submit">
      <!-- <b-form-group v-if="positionId == null">
        <label for="title"> جایگاه <i class="text-danger">*</i></label>
        <multiselect
          v-model="advertise.position"
          placeholder="انتخاب کنید"
          :allow-empty="false"
          open-direction="bottom"
          :options="positions"
          label="label"
          track-by="id"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions"> موردی یافت نشد </template>
          <template slot="noResult"> موردی یافت نشد </template>
        </multiselect>
      </b-form-group> -->

      <b-form-group>
        <label for="link"> لینک <i class="text-danger">*</i></label>
        <multiselect
          v-model="advertise.linkable_type"
          @input="loadModels(advertise.linkable_type)"
          placeholder="انتخاب کنید"
          :allow-empty="false"
          open-direction="bottom"
          :options="linkables"
          label="label"
          track-by="unique_type"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions"> موردی یافت نشد </template>
          <template slot="noResult"> موردی یافت نشد </template>
        </multiselect>
        <input
          v-if="advertise.linkable_type != null"
          type="hidden"
          name="linkable_type"
          :value="
            advertise.linkable_type.linkable_type != 'link_url'
              ? advertise.linkable_type.linkable_type
              : ''
          "
        />
      </b-form-group>
      <b-form-group
        v-if="
          advertise.linkable_type != null &&
            advertise.linkable_type.models != null &&
            advertise.linkable_type.models != 'link_url'
        "
      >
        <label for="item"> آیتم <i class="text-danger">*</i></label>
        <multiselect
          v-model="advertise.linkable_id"
          placeholder="انتخاب کنید"
          :allow-empty="false"
          open-direction="bottom"
          :options="advertise.linkable_type.models"
          :label="modelLable == '' ? labelMultiselect : modelLable"
          track-by="id"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions"> موردی یافت نشد </template>
          <template slot="noResult"> موردی یافت نشد </template>
        </multiselect>
        <input
          type="hidden"
          name="linkable_id"
          :value="advertise.linkable_id != null ? advertise.linkable_id.id : ''"
        />
      </b-form-group>
      <b-form-group
        v-if="
          advertise.linkable_type != null &&
            advertise.linkable_type.linkable_type == 'link_url'
        "
      >
        <label for=""> لینک <i class="text-danger">*</i></label>
        <b-form-input
          v-model="advertise.link"
          name="link"
          id="link"
          :disabled="disabled"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="" label-for="start-date">
        <label for=""> تاریخ شروع <i class="text-danger">*</i></label>
        <date-picker
          v-model="advertise.start"
          format="YYYY-MM-DD"
          display-format="jYYYY-jMM-jDD"
        />
      </b-form-group>
      <b-form-group>
        <label for="end-date"> تاریخ پایان <i class="text-danger">*</i></label>
        <date-picker
          v-model="advertise.end"
          format="YYYY-MM-DD"
          display-format="jYYYY-jMM-jDD"
        />
      </b-form-group>
      <b-form-group>
        <label for="image"> تصویر <i class="text-danger">*</i></label>
        <b-form-file
          placeholder="فایلی انتخاب نشده"
          name="image"
          :disabled="disabled"
        ></b-form-file>
        <ImageHolder class="mt-2" v-if="advertise.image && advertise.image.url">
          <img :src="advertise.image.url" />
        </ImageHolder>
      </b-form-group>
      <b-form-checkbox
        class="mb-3"
        id="newtab"
        :disabled="disabled"
        v-model="advertise.new_tab"
        value="1"
        unchecked-value="0"
      >
        <span class="pr-5">تب جدید</span>
      </b-form-checkbox>
      <input type="submit" style="visibility: hidden; position: absolute" />
    </form>
  </b-modal>
</template>
<script>
import AdvertisementFormImpl from '@@/core/components/advertise/AdvertisementForm/AdvertisementFormImpl'
export default {
  extends : AdvertisementFormImpl
};
</script>
