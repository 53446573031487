<template>
  <b-modal :ok-disabled="disabled"
           :cancel-disabled="disabled"
           @ok.prevent="submit"
           class="in-form-modal"
           ok-title="ثبت"
           cancel-title="لغو"
           id="product-change-quantity-modal"
           :title="type == 'increment' ? 'اضافه کردن به انبار' : 'کم کردن از انبار'">
    <b-form-input type="number" v-model="quantity" />
  </b-modal>
</template>

<script>
import {BFormInput, BModal} from 'bootstrap-vue'

export default {
  name: "ProductChangeQuantity",
  components: {BFormInput, BModal},
  data() {
    return {
      disabled: false,
      quantity: 1
    }
  },
  props: {
    type: String,
    variety: Object,
  },
  methods: {
    async submit() {
      try {
        this.disabled = true
        const response = await this.$axios.post("admin/stores", {
          variety_id: this.variety.id, type: this.type, quantity: this.quantity
        })
        console.log(response.data.data)
        this.variety.quantity = response.data.data.store.balance
        this.$root.$emit('bv::hide::modal', 'product-change-quantity-modal')
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.disabled = false
      }
    }
  }
}
</script>

<style scoped>

</style>
