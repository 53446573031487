<template>
  <form ref="form" @submit.prevent="submit" class="row">
    <b-form-group class="col-md-6">
      <label for="products"> نام <i class="text-danger">*</i></label>
      <b-form-input name="name" v-model="productSet.name"></b-form-input>
    </b-form-group>
    <b-form-group class="col-md-6">
      <label for="products"> محصول <i class="text-danger">*</i></label>
      <multiselect
        v-model="productSelect"
        placeholder="انتخاب کنید"
        :allow-empty="false"
        open-direction="bottom"
        :options="products"
        label="label"
        track-by="id"
        :searchable="true"
        :multiple="false"
        :close-on-select="true"
        :show-labels="false"
        :disabled="disabled"
        @input="setProduct"
      >
        <template slot="noOptions">
          موردی یافت نشد
        </template>
        <template slot="noResult">
          موردی یافت نشد
        </template>
      </multiselect>
    </b-form-group>
    <b-table v-if="productSet.products != '' " class="mt-3" responsive hover bordered :items="productSet.products" :fields="fields">
                <template v-slot:cell(id)="data">
                    {{data.item.id}}
                    <input type="hidden" :name="'product_ids[]'" :value="data.item.id">
                </template>
                <template v-slot:cell(delete)="data">
                    <a class="action-btns1  pt-1 px-2 " @click="deleteItem(data.index)">
                        <i class="feather feather-trash-2 text-danger"></i>
                    </a>
                </template>
    </b-table>
    <div class="col-md-12 text-center mt-5">
                <button :disabled="disabled" type="submit" class="btn btn-success">
                    {{ mode == 'edit' ? ' ویرایش ست' : ' ثبت ست' }}</button>
            </div>
  </form>
</template>
<script>
import SetFormImpl from "@@/core/components/set/SetForm/SetFormImpl";
export default {
  extends: SetFormImpl,
};
</script>
