import {getUrlSegments} from "@@/core/libraries/VueMethods";

export default {
  name: "Back",
  props: {
    url: {
      default: null,
      type: String
    },
    segments: {
      default: 2,
      type: Number
    }
  },
  methods: {
    getBackUrl() {
      if (this.url) {
        return this.url
      }
      let currentUrl = this.$route.path;

      return getUrlSegments(currentUrl, 0, this.segments - 1);
    },
    cancel() {
      if (this.$parent.$data.axiosCancelSource) {
        this.$parent.$data.axiosCancelSource.cancel()
      }
    }
  }
}
