import OrderStatus from "@@/core/components/order/OrderStatus/OrderStatus";
import {BTooltip} from 'bootstrap-vue'

export default {
  name: "OrderInfo",
  props: {
    order: Object
  },
  components: {
    OrderStatus, BTooltip
  },
  methods: {
    updateOrderStatus() {
      this.$emit('update-order-status')
    },
    showCustomer(customer) {
      this.$emit('show-customer', customer)
    }
  }
}
