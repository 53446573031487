import {BFormGroup} from "bootstrap-vue";

export default {
  components: {
    BFormGroup
  },
  props: {
    value: Object,
    products: Array,
    disabled: Boolean,
    productOnly: { // Dont select variety
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      realValue: this.value,
      disabledBtn: false,
      varieties: [],
      showVarieties: false,
    };
  },
  watch: {
    value(newVal) {
      this.realValue = newVal;
    },
    realValue(newVal) {
      this.$emit("input", newVal);
    },
    varieties(newValue) {
      let varieties = newValue;
      varieties.forEach((v) => {
        if (v.attributes.length == 1) {
          v.title =
              (v.color != null ? v.color.name + " - " : "") +
              v.attributes[0].pivot.value;
        } else if (v.attributes.length > 1) {
          let attr = "";
          v.attributes.forEach((a) => {
            attr = attr + "-" + a.pivot.value;
          });
          v.title = (v.color != null ? v.color.name : "") + attr;
        } else if (v.attributes.length == 0 && v.color != null) {
          v.title = v.color.name;
        }
        v.label = "شناسه : " + v.id + " | " + v.title;
        return v;
      });
    },
  },
  methods: {
    setVarieties(item) {
      this.disabledBtn = false;
      this.varieties = [];
      this.realValue.selectedVariety = null;
      let varieties = item.varieties;

      varieties.forEach((variety, index) => {
        variety.index = index;
      });
      if (varieties.length > 1) {
        this.varieties = varieties;
        this.showVarieties = true;
      } else if (varieties.length == 1) {
        if (varieties[0].color == null && varieties[0].attributes.length == 0) {
          this.realValue.selectedVariety = varieties[0];
          this.realValue.varietyQuantity = varieties[0].quantity;
          this.showVarieties = false;
        } else {
          this.varieties = varieties;
          this.realValue.selectedVariety = null;
          this.realValue.varietyQuantity = 0;
          this.showVarieties = true;
        }
      } else if (varieties.length == 0) {
        this.realValue.selectedVariety = "";
        this.showVarieties = false;
        this.disabledBtn = true;
      }
    },
    setQuantity(item) {
      this.realValue.varietyQuantity = item.quantity;
    },
  },
};
