<template>
  <button
      type="button"
      class="btn btn-success mt-5 mb-2"
      @click="newOrder"
      v-b-modal.new-item
  >
    + افزودن به سفارش
  </button>
</template>

<script>
import {VBModal} from 'bootstrap-vue'

export default {
  name: "OrderAddButton",
  directives: {
    'b-modal': VBModal
  },
  methods: {
    newOrder() {
      this.$emit('new-order')
    }
  }
}
</script>

<style scoped>

</style>
