import {BFormGroup, BFormInput, BFormTextarea, BModal} from "bootstrap-vue";

export default {
  components: {
    BModal, BFormGroup, BFormInput, BFormTextarea
  },
  props: {
    value: Object,
    type: {
      default: 'deposit',
      type: String
    }
  },
  data() {
    return {
      disabled: false,
      wallet: this.value,
      description: ''
    };
  },
  watch: {
    wallet(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.wallet = newVal;
      this.description = ''
    },
  },
  methods: {
    async addWallet() {
      this.disabled = true;
      try {
        const response = await this.$axios.post(`admin/customer/${this.type}`, {
          customer_id: this.wallet.customer_id,
          amount: this.wallet.amount,
          description: this.description
        });

        this.$root.notify(response.data.message, "success");
        this.$root.$emit('bv::hide::modal', 'customer-wallet')
        this.$emit("set-new-wallet", {amount: this.wallet.amount, description: this.description});

        this.description = ''
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
  },
}
