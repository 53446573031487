<template>
  <main class="mt-5">
    <span id="add-btn">
      <button
        @click="newAdvertise()"
        :disabled="hasPermission('write_advertise') ? false : true"
        v-b-modal.position
        class="btn btn-success"
      >
        بنر جدید
      </button>
    </span>
    <b-tooltip
      v-if="!hasPermission('write_advertise')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip>

    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">
          بنرهای {{ position ? position.label : "" }}
        </h5>
        <div style="flex:1"></div>
        <Back />
      </div>

      <div class="card-body" v-if="position">
        <template v-if="position.advertisements != ''">
          <form @submit.prevent="updatePossibility">
            <div class="row">
              <div
                class="col-md-3 position-relative mt-3  "
                v-for="(advertisement, index) in position.advertisements"
                :key="index"
              >
                <span
                  class="delete-icon rounded-circle bg-danger "
                  @click="deleteAdvertisement(advertisement.id)"
                >
                  <i class="fe fe-x text-white"></i>
                </span>
                <img
                  class="admin-advertisement-img"
                  :src="advertisement.picture"
                  alt=""
                  style="width: 100%; max-height:300px;"
                />
                <div class="d-flex mt-3 align-items-end justify-content-center">
                  <div>
                    <input
                      type="hidden"
                      :name="`banner_ids[${index}]`"
                      :value="advertisement.id"
                    />
                    <label for="banner-label">درصد احتمال</label>
                    <b-form-input
                      id="banner-label"
                      :name="`banner_possibility[${index}]`"
                      v-model="advertisement.possibility"
                    ></b-form-input>
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary mr-2"
                      @click="editAdvertise(index)"
                    >
                      ویرایش بنر
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center mt-5">
              <button
                class="btn btn-success"
                type="submit"
                :disabled="disabled"
              >
                اعمال تغییرات
              </button>
            </div>
          </form>
        </template>
        <div v-else class="alert alert-danger">
          موردی یافت نشد
        </div>
      </div>
      <div v-else>
        <loading></loading>
      </div>
    </div>
    <advertise-modal
      v-model="advertise"
      :linkables="linkables"
      :positions="positions"
      :mode="mode"
      :labelMultiselect="modelLable"
      @set-advertise="setAdvertise"
      :positionId="$route.params.id"
    ></advertise-modal>
  </main>
</template>
<script>
import PositionAdvertisementsImpl from '@@/core/pages/advertise/PositionAdvertisements/PositionAdvertisementsImpl'
export default {
  extends : PositionAdvertisementsImpl
}
</script>

<style scoped src="@@/core/pages/advertise/PositionAdvertisements/PositionAdvertisementsStyle.css"></style>