<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">
          {{newsletters ? newsletters.title : ''}}
        </h5>
        <div style="flex:1"></div>
        <Back />
      </div>
      <div class="card-body">
        <p v-if="newsletters" v-html="newsletters.body"></p>
      </div>
    </div>
  </main>
</template>
<script>
import NewslettersShowImpl from '@@/core/pages/newsletters/NewslettersShow/NewslettersShowImpl'
export default {
  extends: NewslettersShowImpl
}
</script>
