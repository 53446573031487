<template>
  <div id="print">
    <section class="print-orders" v-for="order in orders" :key="order.id">
      <order-print-item :order="order" :settings="settings"></order-print-item>
    </section>
  </div>
</template>
<script>
import OrderPrintImpl from '@@/core/pages/order/OrderPrint/OrderPrintImpl'
export default {
  extends: OrderPrintImpl
}
</script>
<style src="@@/core/pages/order/OrderPrint/OrderPrintStyle.css" scoped></style>
