<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">لیست فروش</h5>
        <div style="flex:1"></div>
        <div class="d-flex" style="gap: 0.5rem">
          <Refresh />
          <Back url="/admin/shop" />
        </div>
      </div>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate}}
            </template>
            <template v-slot:cell(more)="data">
              <div class="d-flex align-items-center justify-content-center">
                <router-link
                    :to="'/admin/shop/orders/' + data.item.id"
                    class="action-btns1  pt-1 px-1">
                  <i style="vertical-align: text-top" class="fe fe-eye   text-primary"></i>
                </router-link>
                <button
                    :style="!hasPermission('delete_mini_order') ? 'background: gray' : 'background: transparent'"
                    :disabled="!hasPermission('delete_mini_order') ? true : false"
                    class="action-btns1  pt-1 px-1 "
                    @click="deleteItem(data.item.id)"
                >
                  <i style="vertical-align: text-top" class="feather feather-trash-2 text-danger"></i>
                </button>
                <button v-if="data.item.description" @click="showDescription(data.item)" class="action-btns1  pt-1 px-1 ">
                  <i style="vertical-align: text-top" class="feather feather-clipboard"></i>
                </button>
              </div>
            </template>
            <template v-slot:cell(type)="data">
              <span v-if="data.item.type == 'increment'" class="badge badge-success status">افزایش</span>
              <span v-else class="badge badge-danger status">کاهش</span>
            </template>
            <template v-slot:cell(total)="data">
              <span v-if="data.item.total >= 0" class="text-success">
                {{ data.item.total | price }}
              </span>
              <span v-else style="direction: ltr" dir="ltr" class="text-danger">-{{ data.item.total | price }}</span>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination v-else-if="items.data != ''" :disabled="disabled" :limit="2" :data="items"
                      @pagination-change-page="changePage">
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {BTable} from "bootstrap-vue";
import mixins from '@@/core/mixins/mixins'
import Loading from "@@/core/components/shared/Loading/Loading";
import {priceFilter} from "@@/core/libraries/filters";
import Back from '@@/core/components/shared/Back/Back'
import Refresh from "@@/core/components/shared/Refresh/Refresh";

export default {
  name: "ShopIndex",
  mixins: [mixins],
  components: {Refresh, BTable, Loading, Back},
  data() {
    return {
      url: 'admin/mini_orders',
      fields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'mini_order_items_count',
          label: 'تعداد آیتم ها'
        },
        {
          key: 'total',
          label: 'جمع کل'
        },
        {
          key: 'more',
          label: 'عملیات',
          formatter: priceFilter,
        },
        {
          key: 'created_at',
          label: 'تاریخ ایجاد'
        }
      ]
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    showDescription(item) {
      window.swal({
        icon: "info",
        showConfirmButton: true,
        text: item.description,
        button: "بستن",
      })
    }
  }
}
</script>

<style scoped>
.order-product-img {
  max-width: 50px;
  margin: auto;
}

.table-title {
  font-weight: bold;
}
</style>
<style>
.card.little.info {
  background: linear-gradient(30deg, #d2e8ff 0%, #d5e6fc 35%, #eaf5ff 100%);
}

.card.little.online {
  background: linear-gradient(30deg, #c8ffc8 0%, #c8ffc8 35%, #e0ffe0 100%);
}

.card.little.physical {
  background: linear-gradient(30deg, #ffffe3 0%, #ffffbc 35%, #ffffc5 100%);
}

.card.little .card-header {
  font-weight: bold;
}

</style>
