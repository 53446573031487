import {BForm, BFormCheckbox, BFormGroup, BFormInput} from "bootstrap-vue";

export default {
  components: {
    BFormInput, BFormGroup, BFormCheckbox, BForm
  },
  props: {
    mode: {
      default: 'create',
      type: String
    },
    url: String,
    specification: Object
  },
  data() {
    return {
      disabled: true,
      items: [{
        value: 'select',
        name: 'تک مقدار'
      },
        {
          value: 'multi_select',
          name: 'چند مقدار'
        },
        {
          value: 'text',
          name: 'متنی'
        },
      ],
      btnDisabled: false
    }
  },
  methods: {
    setDisabled(val) {
      this.disabled = val
    },
    addValue(index) {
      this.specification.values.splice(index + 1, 0, {
        value: ''
      })
    },
    async submit(e) {
      e.preventDefault()
      this.disabled = true

      try {
        let formData = new FormData(e.target);
        formData.append('type', this.specification.type ? this.specification.type.value : '');
        formData.append('_method', this.mode == 'edit' ? 'put' : 'post');
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response, 'success');
        this.$router.push('/admin/specifications');
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    },
  }
}
