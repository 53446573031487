import "@@/core/assets/css/print.css";
import { Printd } from "printd";
import styles from "./getStyles";
import configProvider from "@@/core/configProvider";
import { BTable } from "bootstrap-vue";
import VueBarcode from "vue-barcode";

export default {
  components: {
    BTable,
    VueBarcode,
  },
  props: {
    order: Object,
    settings: Array,
  },
  configProvider,
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "sku",
          label: "کد انبار",
        },
        {
          key: "product",
          label: "محصول",
        },
        {
          key: "amount",
          label: "قیمت ",
        },
        {
          key: "discount_amount",
          label: "تخفیف",
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "total_price",
          label: "مبلغ کل",
        },
      ],
    };
  },
  watch: {
    order: {
      immediate: true,
      handler() {
        if (this.order) {
          // this.order.active_items = this.order.items.filter(i => i.status)
          this.order.active_items = this.order.items;
        }
      },
    },
  },
  computed: {
    items() {
      let items = [];
      // this.order.
    },
    invoice() {
      let invoice = this.order.invoices.filter(
        (invoice) => invoice.status == "success"
      );
      if (invoice != "") {
        return invoice[0];
      } else {
        return null;
      }
    },
    invoicePayment() {
      let invoicePayment = this.invoice
        ? this.invoice.payments.filter((payment) => payment.status == "success")
        : [];
      if (invoicePayment != "") {
        return invoicePayment[0];
      } else {
        return null;
      }
    },
    address() {
      return JSON.parse(this.order.address);
    },
    total() {
      return this.order.items
        .filter((i) => i.status)
        .reduce((sum, item) => {
          sum += (item.amount + item.discount_amount) * item.quantity;
          return sum;
        }, 0);
    },
    totalPrice() {
      if (this.order.discount_amount) {
        return (
          this.total + this.order.shipping_amount - this.order.discount_amount
        );
      } else {
        return this.total + this.order.shipping_amount;
      }
    },
    totalDiscount() {
      return this.order.active_items
        .filter((i) => i.status)
        .reduce((sum, item) => {
          sum += item.discount_amount * item.quantity;
          return sum;
        }, 0);
    },
  },
  methods: {
    rowClass(item, type) {
      if (item.status === 0) return "warnignRow";
    },
    orderPrint() {
      const d = new Printd();
      let _styles;
      if (configProvider.get("print_font")) {
        _styles =
          styles +
          "@font-face{font-family:'yekan-bakh';src:url(" +
          configProvider.get("print_font") +
          ") format('woff');font-weight:normal;font-style:normal;}";
      } else {
        _styles = styles;
      }
      d.print(document.getElementById("print"), [_styles]);
    },
  },
};
