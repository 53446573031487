import Loading from "@@/core/components/shared/Loading/Loading";

export default {
    name: "Notifications",
    components:{
        Loading
    },
    data() {
        return {
            loading: false,
            reachedEnd: false,
        }
    },
    computed: {
        items() {
            return this.$store.getters["admin/getNotifications"]
        },
        notifications() {
            if(this.items) {
              return this.items.map(item => {
                  return item.getNotification()
              })
          }
        },
        unreadNotifications() {
          return this.$store.state.admin.total_unread_notifications
        }
    },
    methods:{
        toggleSidebarNotification() {
            window.$('.sidebar-right').button('toggle')
          },
          onScroll(e) {
            if (((e.target.scrollHeight - e.target.scrollTop) < 700) && !this.reachedEnd && !this.loading) {
              this.loadItems()
            }
          },
          async loadItems() {
            let lastCreatedAt = !this.items.length ? null : this.items[this.items.length - 1].created_at;
            let month = Number.parseInt(this.moment(lastCreatedAt).format('M'));
            if (month < 10) {
              month = '0' + month
            }
            lastCreatedAt = this.moment(lastCreatedAt).format(`YYYY-${month}-DD HH:mm:ss`)
            this.loading = true;
            try {
              const response = await this.$axios.get('admin/notifications', {
                params: {
                  last_created_at: lastCreatedAt
                }
              })
              let notifications = response.data.data.notifications;
              if (notifications.length === 0) {
                this.reachedEnd = true
              } else {
                this.$store.commit('admin/loadMoreNotifications', notifications)
              }
            } catch (error) {
              this.reachedEnd = true
              this.$root.warningNotify('مشکلی در دریافت اعلانات بوجود آمد')
            }
            this.loading = false;

          }
    }
}
