export default {
  name: "ImageHolder",
  props: {
    image: {
      default: null,
      type: Object
    }
  },
  data() {
    return {
      showImage: false
    }
  },
  mounted() {
    this.$refs.imgHolder.firstChild.addEventListener('load', () => {
      this.fixHeight()

    })
  },
  methods: {
    fixHeight() {
      let el = this.$refs.imgHolder;
      el.style.height = el.scrollHeight + `px`;
      setTimeout(() => {
        this.showImage = true
      },150)
    },
    showImageModal() {
      if (!this.image) return;
      this.$root.$emit('openGallery', {image: this.image})
    }
  },

}
