<template>
  <b-modal ok-only ok-title="بستن" ok-variant="warning" id="orders-info" title="سفارش ها">
    <template v-if="ordersInfo">
      <OrderStatusesView no-empty :order-statuses="ordersInfo.statuses" />
      <h5 class="mt-3">شناسه سفارشات: </h5>
      <a target="_blank" :href="'/admin/order/' + order.id"
         v-for="order in ordersInfo.orders" class="btn btn-outline-primary mr-1">{{ order.id }}</a>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from 'bootstrap-vue'
import OrderStatusesView from "@@/core/components/report/OrderStatusesView";
export class OrdersInfo {
  constructor(orders, orderStatuses) {
    this.orders = orders;
    this.statuses = orderStatuses
  }
}
export default {
  name: "OrdersInfoModal",
  components: {OrderStatusesView, BModal},
  props: {
    ordersInfo: OrdersInfo
  }
}
</script>

<style scoped>

</style>
