<template>
  <div class="row">
    <b-form-group class="col-6">
      <label class="" for="products" >
        استان <i class="text-danger">*</i></label>
      <multiselect
          @open="getAreas"
          v-model="selectedProvince"
          placeholder="انتخاب کنید"
          :allow-empty="true"
          open-direction="bottom"
          :options="provinces"
          label="name"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
      >
        <template slot="noOptions">
          انتخاب کنید
        </template>
        <template slot="noResult">
          <span v-if="isRequesting" v-text="'در حال بارگزاری...'" />
          <span v-else v-text="'موردی یافت نشد'"></span>
        </template>
      </multiselect>
    </b-form-group>
    <b-form-group class="col-6">
      <label class="" for="products" >
        شهر <i class="text-danger">*</i></label>
      <multiselect
          v-if="selectedProvince"
          v-model="selectedCity"
          placeholder="انتخاب کنید"
          :allow-empty="true"
          open-direction="bottom"
          :options="cities"
          label="name"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
      >
        <template slot="noOptions">
          انتخاب کنید
        </template>
        <template slot="noResult">
          <span v-if="isRequesting" v-text="'در حال بارگزاری...'" />
          <span v-else v-text="'موردی یافت نشد'"></span>
        </template>
      </multiselect>
      <p v-else>لطفا ابتدا یک استان انتخاب کنید</p>
    </b-form-group>
  </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
export default {
  name: "AreaSearch",
  components: {BFormGroup},
  data() {
    return {
      isRequesting: false,
      provinces: [],
      selectedProvince: null,
      selectedCity: null,
    }
  },
  props: {
    disabled: Boolean,
    value: Object
  },
  watch: {
    selectedProvince() {
      this.selectedCity = null
      this.emit()
    },
    selectedCity() {
      this.emit()
    }
  },
  computed: {
    cities() {
      return this.selectedProvince ? this.selectedProvince.cities : [];
    }
  },
  methods: {
    emit() {
      this.$emit('input', {province: this.selectedProvince, city: this.selectedCity})
    },
    async getAreas() {
      try {
        this.isRequesting = true
        const response = await this.$axios.get('admin/provinces?all=1')
        this.provinces = response.data.data.provinces
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.isRequesting = false
      }
    }
  }
}
</script>

<style scoped>

</style>
