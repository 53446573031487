import { render, staticRenderFns } from "./Color.vue?vue&type=template&id=48065866&scoped=true&"
import script from "./Color.vue?vue&type=script&lang=js&"
export * from "./Color.vue?vue&type=script&lang=js&"
import style0 from "@@/core/pages/Color/ColorStyle.css?vue&type=style&index=0&id=48065866&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48065866",
  null
  
)

export default component.exports