import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from '@@/core/mixins/mixins'
import {BTooltip} from "bootstrap-vue";

export default {
    name: "Instagram",
    components: {
        Loading,
        BTooltip
    },
    mixins: [mixins],
    data() {
        return {
            url: 'admin/instagram',
        }
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        updateInstagram() {
            this.disabled = true
            this.$axios.post('admin/instagram',{
                _method: 'put'
            }).then(response => {
                this.items.data = response.data.data.instagram
            }).catch(error => {
                this.$root.notify(error)
            }).finally(() =>{
                this.disabled = false
            })
        }
    }
}
