import Loading from '@@/core/components/shared/Loading/Loading'
import advertiseModal from '@@/core/components/advertise/AdvertisementForm/AdvertisementForm'
import ImageHolder from '@@/core/components/shared/ImageHolder/ImageHolder'
import mixins from "@@/core/mixins/mixins";
import {BTooltip, BTable, VBModal} from "bootstrap-vue";

export default {
  name: "Advertise",
  components: {
    Loading,
     advertiseModal,
    ImageHolder,BTooltip, BTable
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/advertise",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "picture",
          label: "تصویر",
        },
        {
          key: "possibility",
          label: "درصد احتمال",
        },
        {
          key: "position_advertise",
          label: "جایگاه",
        },
        {
          key: "start",
          label: "تاریخ شروع",
        },
        {
          key: "end",
          label: "تاریخ پایان",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      linkables: [],
      positions: [],
      advertise: {},
      mode: "create",
      modelLable: "",
    };
  },
  mounted() {
    this.loadItems();
    this.loadPositionsAll();
  },
  watch: {},
  methods: {
    async loadPositionsAll() {
      const response = await this.$axios.get(
        `admin/positions?all=1&widgets=["linkables"]`
      );

      this.positions = response.data.data.positions;
      this.linkables = response.data.data.widgets.linkables;
    },
    newAdvertise() {
      this.advertise = {
        position: null,
        image: "",
        url: null,
        linkable_type: "",
        linkable_id: "",
        start: "",
        end: "",
        new_tab: 0,
      };
      this.mode = "create";
      this.$root.$emit("bv::show::modal", "advertise-modal");
    },
    editItem(index) {
      let advertise = { ...this.items.data[index] };
      advertise.index = index;
    advertise.position = advertise.position_advertise
      if (advertise.url == null) {
        advertise.linkable_type = this.linkables.filter(
          (item) => item.unique_type == advertise.unique_type
        )[0];

        advertise.linkable_id != null &&
        advertise.linkable_type.models
          ? (advertise.linkable_id = advertise.linkable_type.models.filter(
              (item) => item.id == advertise.linkable_id
            )[0])
          : "";

        if (
          advertise.linkable_type.models != null &&
          advertise.linkable_type.models[0].title
        ) {
          this.modelLable = "title";
        } else if (
          advertise.linkable_type.models != null &&
          advertise.linkable_type.models[0].name
        ) {
          this.modelLable = "name";
        }
      } else {
        advertise.linkable_type = {
          linkable_type: "link_url",
          unique_type: "link_url",
          label: "لینک دلخواه",
          models: "link_url",
        };
      }
      this.mode = "edit";

      advertise.new_tab
        ? (advertise.new_tab = 1)
        : (advertise.new_tab = 0);

      this.advertise = advertise
      this.$root.$emit("bv::show::modal", "advertise-modal");
    },
    setAdvertise(advertise) {
      console.log(advertise);
      if (this.mode == "create") {
        this.items.data.unshift(advertise);
      } else {
        this.$set(
          this.items.data,
          this.advertise.index,
          advertise
        );
      }
    },
  },
};
