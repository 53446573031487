<template>
  <div>
    <span class="badge badge-info status" :style="{backgroundColor: getStatusColor(status)}"
      >
      {{getStatusLabel(status)}}
      </span
    >
  </div>
</template>
<script>
import OrderStatusImpl from '@@/core/components/order/OrderStatus/OrderStatusImpl'
export default {
  extends: OrderStatusImpl
}
</script>
