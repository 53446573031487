import { render, staticRenderFns } from "./SliderGroup.vue?vue&type=template&id=8722f39e&scoped=true&"
import script from "./SliderGroup.vue?vue&type=script&lang=js&"
export * from "./SliderGroup.vue?vue&type=script&lang=js&"
import style0 from "@@/core/pages/slider/SliderGroup/SliderGroupStyle.css?vue&type=style&index=0&id=8722f39e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8722f39e",
  null
  
)

export default component.exports