<template>
  <div>
    <b-form-group :class="{'col-md-6': !fullWidth, 'col-12': fullWidth}" id="product-select">
      <label class="pb-1" for="products" v-if="showLabel">
        محصول <i class="text-danger">*</i></label
      >
      <multiselect
          @search-change="getProducts"
          v-model="productSelect"
          :placeholder="showLabel ? 'انتخاب کنید' : 'انتخاب محصول'"
          :allow-empty="false"
          open-direction="bottom"
          :options="products"
          label="label"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
          @input="setVarieties"
      >
        <template slot="noOptions">
          جستجو کنید
        </template>
        <template slot="noResult">
          <span v-if="isRequestingProducts" v-text="'در حال جستجو...'" />
          <span v-else v-text="'موردی یافت نشد'"></span>
        </template>
      </multiselect>
    </b-form-group>
    <b-form-group :class="{'col-md-6': !fullWidth, 'col-12': fullWidth}" v-if="!productOnly && varieties != '' && showVarieties">
      <label class="pb-1" for="variety" v-if="showLabel">
        تنوع <i class="text-danger">*</i></label
      >
      <multiselect
          :placeholder="showLabel ? 'انتخاب کنید' : 'انتخاب تنوع'"
          :allow-empty="false"
          open-direction="bottom"
          :options="varieties"
          label="label"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
          @input="selectVariety($event)"
          :value="selectedVariety"
      >
        <template slot="noOptions">
          موردی یافت نشد
        </template>
        <template slot="noResult">
          موردی یافت نشد
        </template>
      </multiselect>
    </b-form-group>
  </div>
</template>

<script>
import {BFormGroup, BFormInput, BFormTextarea, BTable} from "bootstrap-vue";
import ProductSearchImpl from '@@/core/components/shared/ProductSearch/ProductSearchImpl'


export default {
  extends: ProductSearchImpl
}
</script>

<style scoped>

</style>
