import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import {
  BTooltip,
  BTable,
  BButton,
  BCollapse,
  VBToggle,
  BCard,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";

export default {
  name: "Set",
  components: {
    Loading,
    BTooltip,
    BTable,
    BButton,
    BCollapse,
    BCard,
    BForm,
    BFormGroup,
    ProductSearch,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/product_sets",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "name",
          label: "نام",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      filter: {
        selected_product: "",
        start_date: "",
        end_date: "",
      },
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {},
  methods: {
    selectProduct(product) {
      this.filter.selected_product = product ? product.id : "";
    },
    async filterItem(page = 1) {
      this.searchParams = {
        product_id: this.filter.selected_product,
        start_date: this.filter.start_date ? this.filter.start_date : "",
        end_date: this.filter.end_date ? this.filter.end_date : "",
      };
    },
  },
};
