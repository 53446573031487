<template>
  <div class="card mt-3">
    <div class="card-header border-0 mb-3">
      <h5 class="card-title">سایز چارت</h5>
    </div>
    <div class="card-body pt-0">
      <transition-group :duration="400" name="fade">
        <div v-for="(sizeChart, index) in realValue" :key="index">
          <b-form-group class="col-md-12" >
            <button @click="deleteSizeCart(index)" class="btn btn-danger">حذف سایزچارت</button>
            <label class="d-block mt-3"> عنوان</label>
            <b-form-input v-model="realValue[index].title" />
          </b-form-group>
          <SizeChartForm :size-chart-types-props="sizeChartTypes" :prop-disabled="false"
                         v-model="realValue[index]" />
        </div>
      </transition-group>
      <button @click="addSizeChart" class="btn btn-success">افزودن سایزچارت</button>
    </div>
  </div>
</template>

<script>
import ProductSizeChartImpl from '@@/core/components/product/ProductSizeChart/ProductSizeChartImpl'

export default {
  extends: ProductSizeChartImpl
}
</script>
