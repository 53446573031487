import Ckeditor from '@@/core/components/shared/Ckeditor/Ckeditor'
import configProvider from "@@/core/configProvider";
import {BFormGroup, BFormTextarea} from "bootstrap-vue";

export default {
  name: "ProductDescription",
  configProvider,
  components: {Ckeditor, BFormGroup, BFormTextarea},
  props: {
    value: Object,
  },
  data() {
    return {
      realValue: this.value
    }
  },
  watch: {
    value: function (newVal) {
      this.realValue = newVal
    },
    realValue: function (newVal) {
      this.$emit('input', newVal)
    }
  },
}
