import {Treeselect} from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {BFormInput, BFormGroup} from "bootstrap-vue";

export default {
  components: {Treeselect, BFormInput, BFormGroup},
  name: "Discount",
  props: {
    value: Object,
  },
  watch: {
    value(newVal) {
      this.realValue = newVal
    },
    realValue: {
      handler() {
        this.$emit('input', this.realValue)
      },
      deep: true
    }
  },
  data() {
    return {
      realValue: this.value,
      type: this.value ? this.value.discountType : 'none',
      discountOptions: [
        {
          id: 'none',
          label: 'ندارد',
        },
        {
          id: 'flat',
          label: 'قیمت ثابت',
        },
        {
          id: 'percentage',
          label: 'درصد',
        },
      ]
    }
  },
  methods: {
    setType(val) {
      this.type = val;
      this.$set(this.realValue, 'discountType', val)
    },
    discountChanged(val) {
      if (val > 100) {
        this.realValue.discount = 100;
      } else if (val < 1) {
        this.realValue.discount = 1;
      }
    },
  }

}
