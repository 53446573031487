import { render, staticRenderFns } from "./Faq.vue?vue&type=template&id=072cfcbd&scoped=true&"
import script from "./Faq.vue?vue&type=script&lang=js&"
export * from "./Faq.vue?vue&type=script&lang=js&"
import style0 from "@@/core/pages/Faq/FaqStyle.css?vue&type=style&index=0&id=072cfcbd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072cfcbd",
  null
  
)

export default component.exports