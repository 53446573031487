import SizeChartForm from "@@/core/components/size/SizeChartForm/SizeChartForm";
import {BFormGroup, BFormInput} from "bootstrap-vue";

export default {
  name: "ProductSizeChart",
  components: {SizeChartForm,BFormGroup, BFormInput},
  props: {
    value: Array,
    categories: Array,
    sizeChartTypes: Array
  },
  data() {
    return {
      realValue: this.value
    }
  },
  watch: {
    value: function (newVal) {
      this.realValue = newVal
    },
    realValue: function (newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    addSizeChart() {
      this.$emit('add')
    },
    deleteSizeCart(index) {
      this.realValue = this.realValue.filter((val, _index) => {
        return index != _index;
      })
    }
  }
}
