<template>
  <div class="border rounded mt-4" v-if="invoices.length">
    <h5 class="bg-light text-center rounded py-2 font-bold">
      اطلاعات پرداخت
    </h5>
    <div v-for="(invoice, index) in invoices" class="row px-4 pb-4">
      <h5 v-b-toggle="'invoice-' + index" v-if="invoices.length > 1" class="bg-light-custom text-center rounded py-2 font-bold w-100">
        پرداخت شماره {{ index + 1 }} <span v-if="invoice.by_admin">(ویرایش شده توسط ادمین)</span>
      </h5>
      <b-collapse visible :id="'invoice-' + index">
        <div class="row">
          <div class="col-md-4 d-flex align-items-center ">
            <h6 class="font-bold">وضعیت پرداخت :</h6>
            <h6 class="pr-1">
                <span
                    v-if="invoice.status == 'failed'"
                    class="badge badge-danger status"
                >
                  ناموفق
                </span>
              <span
                  v-else-if="invoice.status == 'success'"
                  class="badge badge-success status"
              >
                  موفق
                </span>
              <span
                  v-else-if="invoice.status == 'pending'"
                  class="badge badge-danger status"
              >
                  در حال انتظار
                </span>
            </h6>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <h6 class="font-bold">زمان پرداخت :</h6>
            <h6 class="pr-1">
              {{ invoice.created_at | persianDate }}
            </h6>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <h6 class="font-bold">روش پرداخت :</h6>
            <h6 class="pr-1" v-if="invoice.type == 'gateway'">
              درگاه {{ getInvoiceSuccessPayment(invoice) ? getInvoiceSuccessPayment(invoice).gateway_label : "" }}
            </h6>
            <h6 class="pr-1" v-else-if="invoice.type == 'wallet'">
              کیف پول
            </h6>
            <h6 class="pr-1" v-else-if="invoice.type == 'both'">
              کیف پول و درگاه {{ getInvoiceSuccessPayment(invoice) ? getInvoiceSuccessPayment(invoice).gateway_label : "" }}
            </h6>
          </div>
          <div class="col-md-4 d-flex align-items-center mt-2">
            <h6 class="font-bold">کد رهگیری :</h6>
            <h6
                class="pr-1"
                v-if="invoice.type == 'gateway' && getInvoiceSuccessPayment(invoice)"
            >
              {{
                getInvoiceSuccessPayment(invoice).tracking_code
                    ? getInvoiceSuccessPayment(invoice).tracking_code
                    : "ندارد"
              }}
            </h6>
            <h6 class="pr-1" v-else>
              ندارد
            </h6>
          </div>
          <div class="col-md-4 d-flex align-items-center mt-2">
            <h6 class="font-bold">مبلغ پرداختی :</h6>

            <h6 class="pr-1" :class="{'text-danger': invoice.amount < 0}"><span>{{ invoice.amount | price }}</span><span v-if="invoice.amount < 0">-</span> تومان</h6>
          </div>
          <template v-if="invoice.type === 'both'">
            <div class="col-md-4 d-flex align-items-center mt-2">
              <h6 class="font-bold">مبلغ پرداختی از کیف پول :</h6>

              <h6 class="pr-1">{{ invoice.wallet_amount | price }} تومان</h6>
            </div>
            <div class="col-md-4 d-flex align-items-center mt-2">
              <h6 class="font-bold">مبلغ پرداختی از درگاه :</h6>
              <h6 class="pr-1">{{ invoice.amount - invoice.wallet_amount | price }} تومان</h6>
            </div>
          </template>
        </div>
      </b-collapse>
    </div>
    <template v-if="invoices.length > 1">
      <div class="mr-4 mb-3">
        <span class="font-weight-bold">مجموع پرداختی ها: </span>
        <span>{{ sumPaidInvoices | price }} تومان</span>
      </div>
    </template>
  </div>

</template>

<script>
import OrderInvoicesImpl from "@@/core/components/order/OrderInvoices/OrderInvoicesImpl";

export default {
  name: "OrderInvoices",
  extends: OrderInvoicesImpl
}
</script>

<style scoped src="@@/core/components/order/OrderInvoices/OrderInvoicesStyle.css"></style>
