<template>
  <main class="mt-5">
    <span id="add-btn">
      <button
        :disabled="hasPermission('write_advertise') ? false : true"
        @click="newAdvertise()"
        v-b-modal.position
        class="btn btn-success"
      >
        بنر جدید
      </button>
    </span>
    <b-tooltip
      v-if="!hasPermission('write_advertise')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip>

    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">بنرها</h5>
        <div style="flex:1"></div>
      </div>

      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(picture)="data">
              <ImageHolder style="max-height: 100px" v-if="data.item.picture">
                <img :src="data.item.picture" />
              </ImageHolder>
            </template>
            <template v-slot:cell(position_advertise)="data">
              {{
                data.item.position_advertise
                  ? data.item.position_advertise.label
                  : ""
              }}
            </template>
            <template v-slot:cell(start)="data">
              {{ moment(data.item.start).format("YYYY/MM/DD ") }}
            </template>
            <template v-slot:cell(end)="data">
              {{ moment(data.item.end).format("YYYY/MM/DD ") }}
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <a
                  :disabled="!hasPermission('modify_advertise') ? true : false"
                  class="action-btns1  pt-1 px-2"
                  @click="editItem(data.index)"
                >
                  <i class="feather feather-edit  text-primary"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('modify_advertise')"
                :target="'edit-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  :disabled="!hasPermission('delete_advertise') ? true : false"
                  class="action-btns1  pt-1 px-2 "
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('delete_advertise')"
                :target="'delete-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
    <advertise-modal
      v-model="advertise"
      :linkables="linkables"
      :positions="positions"
      :mode="mode"
      :labelMultiselect="modelLable"
      @set-advertise="setAdvertise"
      :positionId="$route.params.id"
    ></advertise-modal>
  </main>
</template>
<script>
import AdvertiseImpl from '@@/core/pages/advertise/Advertise/AdvertiseImpl'
export default {
  extends: AdvertiseImpl
}
</script>
