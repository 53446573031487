<template>
    <b-modal id="role-modal" :title="mode === 'edit' ? 'ویرایش نقش' : 'ثبت نقش' " :ok-disabled="disabled"
        :cancel-disabled="disabled" @ok="submit" class="in-form-modal" ok-title="ثبت" cancel-title="لغو" size="lg">
        <form ref="form" @submit.prevent="submit">
            <b-form-group >
                <label for="name"> نام <i class="text-danger">*</i></label>
                <b-form-input id="title" name="name" :value="role.name"></b-form-input>
            </b-form-group>
            <b-form-group >
                <label for="permissions"> دسترسی ها<i class="text-danger">*</i></label>
                <multiselect v-model="role.permissions" placeholder="انتخاب کنید" open-direction="bottom"
                    :options="permissions" label="label" track-by="id" :searchable="true" :multiple="true"
                    :close-on-select="false" :show-labels="false" :disabled="disabled">
                    <template slot="noOptions">
                        موردی یافت نشد
                    </template>
                    <template slot="noResult">
                        موردی یافت نشد
                    </template>
                </multiselect>
                <template v-if="role.permissions != null && role.permissions != ''">
                    <input type="hidden"  v-for="(item, index) in role.permissions" :key="item.id" :name="'permissions['+index+']'" :value="item.id">
                </template>
            </b-form-group>
        </form>
    </b-modal>
</template>
<script>
 import RoleFormImpl from '@@/core/components/role/RoleForm/RoleFormImpl'
 export default {
     extends: RoleFormImpl
 }
</script>