<template>
  <div class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title">مشخصات محصول</h5>
    </div>
    <div class="card-body">
      <div class="alert alert-danger" v-if="realSpecifications.length === 0">موردی یافت نشد</div>
      <table class="table table-responsive-sm table-bordered table-hover" v-else>
        <thead>
        <tr>
          <th>نام</th>
          <th>مقدار</th>
        </tr>
        </thead>
        <tr v-for="spec in realSpecifications" :key="spec.name + spec.real_label">
          <td>{{ spec.name }}</td>
          <td>
            <textarea v-if="spec.type === 'text'" v-model="realValue[spec.id]" class="form-control"></textarea>
            <treeselect v-else :options="getRealValues(spec.values)" :searchable="true"
                        v-model="realValue[spec.id]"
                        @select="(node, id) => selected(spec, node, id)"
                        @deselect="(node, id) => deselected(spec, node, id)"
                        noResultsText="موردی یافت نشد"
                        :multiple="spec.type === 'multi_select'"
                        noOptionsText="موردی وجود ندارد"
                        placeholder="انتخاب مقدار">
              <div slot="value-label" slot-scope="{ node }">{{ node.label }}</div>
            </treeselect>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import ProductSpecificationsImpl from '@@/core/components/product/ProductSpecifications/ProductSpecificationsImpl'

export default {
  extends: ProductSpecificationsImpl
}
</script>

<style scoped>
.table > tr td:nth-child(1) {
  width: 200px;;
}
</style>
