import OrderPrintItem from "@@/core/components/order/OrderPrintItem/OrderPrintItem";
import { BTable } from "bootstrap-vue";
import { Printd } from "printd";
import configProvider from "@@/core/configProvider";
import styles from "./getStyles";

export default {
  components: {
    BTable,
    OrderPrintItem,
  },
  data() {
    return {
      orders: [],
      settings: [],
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "customer",
          label: "گیرنده",
        },
        {
          key: "city",
          label: "شهر",
        },
        {
          labeley: "پرداخت",
          key: "payment",
        },
        {
          label: "بسته بندی",
          key: "input1",
        },
        {
          key: "input2",
          label: "ارسال",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query) {
      this.getOrders(this.$route.query.ids);
      this.loadSettings();
    }
  },
  computed: {},
  methods: {
    orderPrint() {
      const d = new Printd();
      let _styles;
      if (configProvider.get("print_font")) {
        _styles =
          styles +
          "@font-face{font-family:'yekan-bakh';src:url(" +
          configProvider.get("print_font") +
          ") format('woff');font-weight:normal;font-style:normal;}";
      } else {
        _styles = styles;
      }
      d.print(document.getElementById("print"), [_styles]);
    },
    address(order) {
      return JSON.parse(order.address);
    },
    async getOrders(ids) {
      let ordersId = ids.split(",");
      try {
        const response = await this.$axios.post("admin/orders/print/details", {
          ids: ordersId,
        });

        let orders = response.data.data.orders.filter((item) => {
          return item.reserved_id == null;
        });
        orders.forEach((order) => {
          order.items.forEach((item) => {
            item.extra = JSON.parse(item.extra);
          });

          if (order.reserved == 1) {
            order.reservations.forEach((item) => {
              item.items.forEach((p) => {
                p.extra = JSON.parse(p.extra);
                order.items.push(p);
              });
            });
          }
          return order;
        });
        this.orders = orders;
      } catch (error) {
        this.$root.notify(error);
      }
    },
    loadSettings() {
      this.$axios.get("admin/settings/shop_info").then((response) => {
        this.settings = response.data.data.settings;
      });
    },
  },
};
