<template>
  <main class="mt-5">
    <span id="add-btn">
      <button
        :disabled="hasPermission('write_advertise') ? false : true"
        @click="createItem"
        v-b-modal.position
        class="btn btn-success"
      >
        جایگاه جدید
      </button>
    </span>
    <b-tooltip
      v-if="!hasPermission('write_advertise')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip>

    <FormModal
      :edit-model="edit"
      :mode="mode"
      @create="onCreate"
      @edit="onEdit"
      :url="url"
      :modal-name="$options.name"
      :create-rules="createRules"
      :edit-rules="editRules"
    ></FormModal>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">جایگاه ها</h5>
        <div style="flex:1"></div>
      </div>

      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(advertisements)="data">
              <router-link
                :to="{
                  name: 'position.advertisements',
                  params: { id: data.item.id },
                }"
                class="action-btns1  pt-1 px-2"
              >
                <i class="fe fe-menu text-info"></i>
              </router-link>
            </template>
            <template v-slot:cell(status)="data">
              <span
                v-if="data.item.status == 1"
                class="badge badge-success status"
                >فعال</span
              >
              <span
                v-else-if="data.item.status == 0"
                class="badge badge-danger status"
                >غیرفعال</span
              >
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <a
                  :disabled="!hasPermission('modify_advertise') ? true : false"
                  class="action-btns1  pt-1 px-2"
                  @click="editItem(data.index)"
                >
                  <i class="feather feather-edit  text-primary"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('modify_advertise')"
                :target="'edit-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import PositionImpl from '@@/core/pages/advertise/Position/PositionImpl'
export default {
  extends: PositionImpl
}
</script>