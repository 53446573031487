import {BButton, BCard, BCollapse, BFormGroup, BFormInput, BTable, BTooltip, BFormCheckbox} from "bootstrap-vue";
import mixins from "@@/core/mixins/mixins";
import FormModal from "@@/core/libraries/form/FormModal";
import CustomerSearch from "@@/core/components/shared/CustomerSearch/CustomerSearch";

export default {
  components: {BButton, BCard, BCollapse, BFormGroup, BFormInput, BTable, BTooltip, BFormCheckbox, FormModal, CustomerSearch},
  mixins: [mixins],
  data() {
    return {
      url: 'admin/withdraws',
      filter: {
        selectedCustomer: null
      },
      fields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'amount',
          label: 'مبلغ (تومان)'
        },
        {
          key: 'customer',
          label: 'مشتری'
        },
        {
          key: 'status',
          label: 'وضعیت'
        },
        {
          key: 'created_at',
          label: 'تاریخ'
        },
        {
          key: 'action',
          label: 'عملیات'
        },
      ]
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    doFilter() {
      let query = {search1: this.filter.selectedCustomer ? this.filter.selectedCustomer.id : null, searchBy1: 'customer_id'}
      this.$router.push({path: this.$route.path,
        query: {search: JSON.stringify(query)}
      })
      this.loadItems()
    },
    removeFilter() {
      this.$router.push({path: this.$route.path, query: {}})
      this.loadItems()
    }
  }
}
