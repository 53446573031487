<template>
  <div class="row">
    <div class="col-6 d-flex">
      <span class="ml-2">از</span>
      <date-picker
        :type="fromType"
        v-model="startDate"
        :format="fromFormatType"
        :display-format="dfFType"
      />
    </div>
    <div class="col-6 d-flex">
      <span class="ml-2">تا</span>
      <date-picker
        name="noname"
        :type="toType"
        v-model="endDate"
        :format="toFormatType"
        :display-format="dfTType"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DatePickerRange",
  props: {
    value: Object,
    fromFormatType: {
      type: String,
      default: "YYYY-MM-DD HH:mm",
    },
    toFormatType: {
      type: String,
      default: "YYYY-MM-DD HH:mm",
    },
    fromType: {
      type: String,
      default: "datetime",
    },
    toType: {
      type: String,
      default: "datetime",
    },
    dfFType: {
      type: String,
      default: "jYYYY-jMM-jDD HH:mm",
    },
    dfTType: {
      type: String,
      default: "jYYYY-jMM-jDD HH:mm",
    },
  },
  data() {
    return {
      startDate: "",
      endDate: null,
    };
  },
  watch: {
    value() {
      this.startDate = this.value.startDate;
      this.endDate = this.value.endDate;
    },
    startDate() {
      this.emit();
    },
    endDate() {
      this.emit();
    },
  },
  methods: {
    emit() {
      this.$emit("input", { startDate: this.startDate, endDate: this.endDate });
    },
  },
};
</script>

<style scoped></style>
