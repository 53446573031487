<template>
  <div>
    <b-form-group>
      <label for="products"> محصول <i class="text-danger">*</i></label>
      <multiselect
        v-model="realValue.selectedProduct"
        placeholder="انتخاب کنید"
        :allow-empty="false"
        open-direction="bottom"
        :options="products"
        label="label"
        track-by="id"
        :searchable="true"
        :multiple="false"
        :close-on-select="true"
        :show-labels="false"
        :disabled="disabled"
        @input="setVarieties"
      >
        <template slot="noOptions">
          موردی یافت نشد
        </template>
        <template slot="noResult">
          موردی یافت نشد
        </template>
      </multiselect>
    </b-form-group>
    <b-form-group v-if="!productOnly && realValue.selectedProduct != null && showVarieties">
      <label for="variety"> تنوع <i class="text-danger">*</i></label>
      <multiselect
        v-model="realValue.selectedVariety"
        placeholder="انتخاب کنید"
        :allow-empty="false"
        open-direction="bottom"
        :options="varieties"
        label="label"
        track-by="id"
        :searchable="true"
        :multiple="false"
        :close-on-select="true"
        :show-labels="false"
        :disabled="disabled"
        @input="setQuantity"
      >
        <template slot="noOptions">
          موردی یافت نشد
        </template>
        <template slot="noResult">
          موردی یافت نشد
        </template>
      </multiselect>
    </b-form-group>
  </div>
</template>
<script>
import ProductSelectImpl from '@@/core/components/shared/ProductSelect/ProductSelectImpl'
export default {
  extends: ProductSelectImpl
}
</script>
