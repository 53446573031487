<template>
  <main class="mt-5">
    <FormModal :edit-model="edit" :mode="mode" @create="onCreate" @edit="onEdit" :url="url" :modal-name="$options.name"
               :create-rules="createRules" :edit-rules="editRules" />
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold"> برداشت های کیف پول</h5>
        <div style="flex:1"></div>
      </div>
      <div class="card-body">
        <form @submit.prevent="doFilter">
          <div class="row">
            <div class="col-4">
              <CustomerSearch :disabled="disabled" v-model="filter.selectedCustomer" />
            </div>
            <div class="col-4 pr-3 d-flex flex-column justify-content-end">
              <div style="align-self: flex-start">
                <button  type="submit" class="btn btn-primary mb-4">فیلتر</button>
                <button @click="removeFilter" style="align-self: flex-start" class="btn btn-warning mb-4 mr-3">برداشتن فیلتر</button>
              </div>
            </div>
          </div>
        </form>

        <b-table  v-if="items.data"
                  hover
                  bordered
                  responsive
                  :items="items.data"
                  :fields="fields"
        >
          <template v-slot:cell(amount)="data">
            {{data.item.amount | price}}
          </template>
          <template v-slot:cell(customer)="data">
            <router-link :to="`/admin/customers/show/${data.item.customer.id}`">{{ data.item.customer.mobile }}</router-link>
          </template>
          <template v-slot:cell(type)="data">
            <span class="badge badge-success" v-if="data.item.type == 'deposit'">افزایش کیف پول</span>
            <span class="badge badge-danger" v-else-if="data.item.type == 'withdraw'">برداشت از کیف پول</span>
          </template>
          <template v-slot:cell(status)="data">
            <span class="badge badge-primary" v-if="data.item.status === 'pending'">در انتظار تکمیل</span>
            <span class="badge badge-success" v-else-if="data.item.status === 'paid'">پرداخت شده</span>
            <span class="badge badge-danger" v-else-if="data.item.status === 'canceled'">لغو شده</span>
            <span class="badge badge-primary" v-else>{{ data.item.status }}</span>
          </template>
          <template v-slot:cell(description)="data">
            {{data.item.meta ? data.item.meta.description : ''}}
          </template>
          <template v-slot:cell(created_at)="data">
            {{data.item.created_at | persianDate}}
          </template>
          <template v-slot:cell(action)="data">
            <button
                :disabled="!hasPermission('modify_withdraw') ? true : false"
                @click="editItem(data.index)"
                class="action-btns1  pt-1 px-2"
            >
              <i class="feather feather-edit  text-primary"></i>
            </button>
          </template>
        </b-table>
        <pagination v-if="items.data" :disabled="disabled" :limit="2" :data="items"
                    @pagination-change-page="changePage">
        </pagination>
        <div v-else class="alert alert-danger">
          موردی یافت نشد
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import WithdrawImpl from "@@/core/pages/withdraw/WithdrawImpl";

export default {
  name: "Withdraw",
  mixins: [WithdrawImpl]
}
</script>

<style scoped>

</style>
