<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 v-if="$route.params.post" class="card-title font-bold">
          نظرات مطلب {{ postDetails.title }} 
        </h5>
        <h5 v-else-if="$route.params.id" class="card-title font-bold">
          نظرات مطلب {{ items.commentable ? items.commentable.title : "" }} <span class="font-bold">- پاسخ ها به نظر {{items.name}} </span>
        </h5>
        <h5 v-else class="card-title font-bold">نظرات مطالب</h5>
        <div style="flex:1"></div>
        <Back v-if="$route.params.id"  />
      </div>

      <div class="card-body">
        <div>
          <b-table
            hover
            bordered
            :items="$route.params.id ? items.children : items.data"
            :fields="fields"
          >
            <template v-slot:cell(status)="data">
              <span
                v-if="data.item.status == 'pending'"
                class="badge badge-warning status"
                >در انتظار بررسی</span
              >
              <span
                v-else-if="data.item.status == 'unapproved'"
                class="badge badge-danger status"
                >تایید نشده</span
              >
              <span
                v-else-if="data.item.status == 'approved'"
                class="badge badge-success status"
                >تایید شده</span
              >
            </template>
            <template v-slot:cell(body)="data">
              {{ data.item.body.substr(0, 50) }}
              {{ data.item.body.length > 50 ? "..." : "" }}
            </template>
            <template v-slot:cell(post)="data">
              {{ data.item.commentable ? data.item.commentable.title : "" }}
            </template>
            <template v-slot:cell(name)="data">
              {{
                data.item.creator != null
                  ? data.item.creator.name
                  : data.item.name
              }}
            </template>
            <template v-slot:cell(email)="data">
              {{
                data.item.creator != null
                  ? data.item.creator.email
                  : data.item.email
              }}
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <a
                :disabled="!hasPermission('modify_comment') ? true : false"
                  @click="editComment(data.index)"
                  class="action-btns1  pt-1 px-2"
                >
                  <i class="feather feather-edit  text-primary"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('modify_comment')"
                :target="'edit-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(comments)="data">
              <router-link
                :to="{ name: 'show.comment', params: { id: data.item.id } }"
                class="action-btns1  pt-1 px-2  position-relative"
              >
                <i class="fe fe-message-square text-warning"></i>
                  <span class="answer-count bg-warning text-white">{{data.item.children.length}}</span>
              </router-link>
            </template>
            <template v-slot:cell(answer)="data">
              <a
                class="action-btns1  pt-1 px-2"
                @click="answerComments(data.index)"
              >
                <i class="fe fe-message-circle text-info"></i>
              
              </a>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  :disabled="!hasPermission('delete_comment') ? true : false"
                  class="action-btns1  pt-1 px-2 "
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('delete_comment')"
                :target="'delete-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
          <div
            v-if="$route.params.id && items.children == ''"
            class="alert alert-danger"
          >
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
    <AnswerModal :id="comment.id" :body="comment.body" />
    <EditModal
      :comment="comment"
      :mode="$route.params.id ? 'children' : 'parent'"
    />
  </main>
</template>
<script>
import PostCommentsImpl from '@@/core/pages/post/PostComments/PostCommentsImpl'
export default {
  extends: PostCommentsImpl
}
</script>

<style scoped src="@@/core/pages/post/PostComments/PostCommentsStyle.css"></style>