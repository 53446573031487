<template>
  <router-link @click.native="cancel" :to="getBackUrl()" class="btn btn-outline-info"><span class="fa fa-arrow-left"></span></router-link>
</template>

<script>
import BackImpl from '@@/core/components/shared/Back/BackImpl'
export default {
  extends: BackImpl
}
</script>

<style scoped src="@@/core/components/shared/Back/BackStyle.css"></style>