<template>
  <b-modal
    :ok-disabled="disabled"
    :cancel-disabled="disabled"
    @ok="submit"
    class="in-form-modal"
    ok-title="ثبت"
    cancel-title="لغو"
    id="menu-modal"
    :title="mode == 'edit' ? 'ویرایش منو' : 'منو جدید'"
  >
    <form ref="form" @submit.prevent="submit">
      <b-form-group>
        <label for="title"> عنوان <i class="text-danger">*</i></label>
        <b-form-input
          id="title"
          name="title"
          v-model="menu_item.title"
          :disabled="disabled"
        ></b-form-input>
      </b-form-group>
      <b-row>
        <b-col md="6">
          <b-form-checkbox
            class="mb-3"
            id="status"
            :disabled="disabled"
            v-model="menu_item.status"
            value="1"
            unchecked-value="0"
          >
            <span class="pr-5">وضعیت</span>
          </b-form-checkbox>
        </b-col>
        <b-col md="6">
          <b-form-checkbox
            class="mb-3"
            id="new-tab"
            :disabled="disabled"
            v-model="menu_item.new_tab"
            value="1"
            unchecked-value="0"
          >
            <span class="pr-5">تب جدید</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-form-group label="پدر">
        <treeselect
          :options="realOptions"
          v-model="menu_item.parent_id"
          :searchable="true"
          :show-count="true"
          noChildrenText="فرزندی وجود ندارد"
          noOptionsText="موردی وجود ندارد"
          noResultsText="موردی یافت نشد"
          :default-expand-level="1"
          label="title"
        >
          <label
            slot="option-label"
            slot-scope="{
              node,
              shouldShowCount,
              count,
              labelClassName,
              countClassName,
            }"
            :class="labelClassName"
          >
            {{ node.label }}
            <span v-show="false" v-if="shouldShowCount" :class="countClassName"
              >({{ count }})</span
            >
          </label>
        </treeselect>
      </b-form-group>
      <input
        type="hidden"
        name="parent_id"
        :value="menu_item.parent_id != 0 ? menu_item.parent_id : ''"
      />
      <b-form-group>
        <label for="type"> لینک <i class="text-danger">*</i></label>
        <multiselect
          v-model="menu_item.linkable_type"
          @input="loadModels(menu_item.linkable_type)"
          placeholder="انتخاب کنید"
          :allow-empty="false"
          open-direction="bottom"
          :options="linkables"
          label="label"
          track-by="unique_type"
          :searchable="true"
          :show-labels="false"
          :close-on-select="true"
          :disabled="disabled"
        >
          <template slot="noOptions">
            موردی یافت نشد
          </template>
          <template slot="noResult">
            موردی یافت نشد
          </template>
        </multiselect>
        <input
          v-if="menu_item.linkable_type != null"
          type="hidden"
          name="linkable_type"
          :value="
            menu_item.linkable_type.linkable_type != 'link_url'
              ? menu_item.linkable_type.linkable_type
              : ''
          "
        />
      </b-form-group>

      <b-form-group
        v-if="
          menu_item.linkable_type != null &&
            !linkUrl &&
            menu_item.linkable_type.models != null
        "
      >
        <label for=""> آیتم <i class="text-danger">*</i></label>
        <multiselect
          v-model="menu_item.linkable_id"
          placeholder="انتخاب کنید"
          :allow-empty="false"
          open-direction="bottom"
          :options="menu_item.linkable_type.models"
          :label="modelLable == '' ? labelMultiselect : modelLable"
          track-by="id"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions">
            موردی یافت نشد
          </template>
          <template slot="noResult">
            موردی یافت نشد
          </template>
        </multiselect>
        <input
          type="hidden"
          name="linkable_id"
          :value="menu_item.linkable_id != null ? menu_item.linkable_id.id : ''"
        />
      </b-form-group>
      <b-form-group
        v-if="
          menu_item.linkable_type != null &&
            menu_item.linkable_type.linkable_type == 'link_url'
        "
      >
        <label for=""> لینک <i class="text-danger">*</i></label>
        <b-form-input
          v-model="menu_item.link"
          name="link"
          id="link"
          :disabled="disabled"
        ></b-form-input>
      </b-form-group>
      <!-- <b-form-group>
        <label for="type"> تصویر </label>
        <b-form-file
          v-model="menuImage"
          :state="Boolean(menuImage)"
          placeholder="تصویر منو را وارد کنید"
          drop-placeholder="به اینجا بکشید"
          accept="image/*"
        >
        </b-form-file>
      </b-form-group> -->
      <b-form-group v-if="$options.configProvider.get('menu.hasIcon')">
        <label for=""> آیکون <i class="text-danger">*</i></label>
        <b-form-file
          placeholder="فایلی انتخاب نشده"
          name="icon"
          type="file"
          :disabled="disabled"
        ></b-form-file>
      </b-form-group>
      <input type="submit" style="visibility: hidden;position: absolute" />
    </form>
  </b-modal>
</template>
<script>
import MenuFormImpl from "@@/core/components/menu/MenuForm/MenuFormImpl";
export default {
  extends: MenuFormImpl,
};
</script>
