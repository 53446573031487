import Back from '@@/core/components/shared/Back/Back'
import SizeForm from '@@/core/components/size/SizeChartForm/SizeChartForm'
import {BForm, BFormGroup, BFormInput} from "bootstrap-vue";

export default {
  name: 'SizeChartCreateEdit',
  components: {
    Back,
    SizeForm,
    BForm, BFormGroup, BFormInput
  },
  data() {
    return {
      data: {
        chart: [
          ['', '', ''],
          ['', '', ''],
          ['', '', ''],
        ],
        type_id: null,
      },
      title: '',
      url: '',
      axiosCancelSource: null,
      disabled: false
    }
  },
  computed: {
    mode() {
      return this.$route.params.id ? 'edit' : 'create'
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.url = 'admin/size_chart/' + this.$route.params.id
      this.getSizeChart()
    } else {
      this.$refs.sizeForm.setDisabled(false)
      this.url = 'admin/size_chart'
    }
  },
  methods: {
    async getSizeChart() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(this.url, {
          cancelToken: this.axiosCancelSource.token
        })
        this.data.chart = response.data.data['sizeChart'].chart
        this.data.type_id = response.data.data['sizeChart'].type_id
        this.title = response.data.data['sizeChart'].title
        this.$refs.sizeForm.setDisabled(false)
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error)
      }
    },
    async submit() {
      this.disabled = true
      try {
        const response = await this.$axios.post(this.url, {
          chart: this.data.chart,
          title: this.$refs.title.$el.value,
          type_id: this.data.type_id,
          _method: this.mode == 'edit' ? 'put' : 'post'
        });
        this.$root.notify(response.data.message, 'success')
        this.$router.push('/admin/size-chart')
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    },
  }
}
