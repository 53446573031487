import { render, staticRenderFns } from "./SizeChartForm.vue?vue&type=template&id=d93d8dbe&scoped=true&"
import script from "./SizeChartForm.vue?vue&type=script&lang=js&"
export * from "./SizeChartForm.vue?vue&type=script&lang=js&"
import style0 from "@@/core/components/size/SizeChartForm/SizeChartFormStyle.css?vue&type=style&index=0&id=d93d8dbe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d93d8dbe",
  null
  
)

export default component.exports