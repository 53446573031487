<template>
    <div class="page login-bg">
        <div class="page-single">
            <div class="container">
                <div class="row">
                    <div class="col mx-auto">
                        <div class="row justify-content-center">
                            <div class="col-md-7 col-lg-5">
                                <div class="card">
                                    <div class="p-4 pt-6 text-center">
                                        <h1 class="mb-2 font-bold">ورود</h1>
                                        <p class="text-muted">ورود به پنل مدیریت</p>
                                    </div>
                                    <ValidationObserver v-slot="{ handleSubmit }">
                                        <form @submit.prevent="(e) => handleSubmit(() => submitLogin(e))"
                                            class="card-body pt-3" id="login">
                                            <ValidationProvider name="username" class="mb-3 text-right" rules="required"
                                                v-slot="v">
                                                <div class="form-group">
                                                    <label class="form-label">نام کاربری</label>
                                                    <input :disabled="sendingRequest" class="form-control" type="text"
                                                        @change="v.validate" name="username" placeholder="نام کاربری">
                                                    <span
                                                        class="text-right text-error text-danger mt-2">{{ v.errors[0] }}</span>
                                                </div>

                                            </ValidationProvider>
                                            <ValidationProvider name="password" class="mb-3 text-right" rules="required"
                                                v-slot="v">
                                                <div class="form-group">
                                                    <label class="form-label">رمز عبور</label>
                                                    <input :disabled="sendingRequest" class="form-control"
                                                        name="password" @change="v.validate" placeholder="رمزعبور"
                                                        type="password">
                                                    <span
                                                        class="text-right text-error text-danger mt-2">{{ v.errors[0] }}</span>
                                                </div>

                                            </ValidationProvider>
                                            <div class="form-group">
                                                <label class="custom-control custom-checkbox">
                                                    <input :disabled="sendingRequest" type="checkbox"
                                                        class="custom-control-input" name="example-checkbox1"
                                                        value="option1">
                                                    <span class="custom-control-label">مرا به خاطر بسپار</span>
                                                </label>
                                            </div>
                                            <div class="submit">
                                                <button :disabled="sendingRequest" type="submit"
                                                    class="btn btn-primary btn-block">ورود</button>
                                            </div>
                                        </form>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import LoginImpl from '@@/core/pages/auth/Login/LoginImpl'
    export default {
        extends: LoginImpl
    }
</script>
