import Loading from '@@/core/components/shared/Loading/Loading'
import attributeModal from '@@/core/components/attribute/AttributeForm/AttributeForm'
import mixins from '@@/core/mixins/mixins'
import {BButton, BCard, BCollapse, BFormGroup, BFormInput, BTable, BTooltip, VBModal, VBToggle} from "bootstrap-vue";

export default {
  name: "Attribute",
  components: {
    Loading,
    attributeModal,
    BTooltip, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput
  },
  directives: {
    'b-toggle': VBToggle, 'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/attributes',
      fields: [{
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'name',
          label: 'نام'
        },
        {
          key: 'label',
          label: 'لیبل'
        },
        {
          key: 'type',
          label: 'نوع'
        },
        {
          key: 'status',
          label: 'وضعیت'
        },
        {
          key: 'show_filter',
          label: 'نمایش در فیلتر'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      submit_url: 'admin/attributes',
      mode: 'create',
      attribute: {
        name: '',
        label: '',
        type: '',
        show_filter: 0,
        status: 0,
        values: [{
          value: ''
        }],
        style: {
          value: 'انتخابی',
          name: 'کومبو'
        }
      },
      select_type: null,
      filter: {
        name: '',
        type: null,
        show_filter: null,
        status: null
      },
      status_options: [{
        name: 'فعال',
        value: '1'
      },
        {
          name: 'غیرفعال',
          value: '0'
        },
        {
          name: 'همه',
          value: ''
        },
      ],
      type_options: [{
        name: 'انتخابی',
        value: 'select'
      },
        {
          name: 'متنی',
          value: 'text'
        },
        {
          name: 'همه',
          value: ''
        },
      ]
    }
  },
  mounted() {
    this.loadItems()
  },
  watch: {
    'filter.status': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.status = this.status_options.find(item => {
          return item.value == newVal
        })
      }
    },
    'filter.show_filter': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.show_filter = this.status_options.find(item => {
          return item.value == newVal
        })
      }
    },
    'filter.type': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.type = this.type_options.find(item => {
          return item.value == newVal
        })
      }
    },
  },
  methods: {
    async editItem(id, index) {
      this.disabled = true

      try {
        this.attribute = {
          ...this.items.data.find((item) => {
            return item.id == id;
          })
        }
        this.mode = 'edit'
        this.submit_url = this.url + `/${id}`
        this.attribute.type == 'select' ? this.attribute.type = {
          value: 'select',
          name: 'انتخابی'
        } : this.attribute.type = {
          value: 'text',
          name: 'متنی'
        }
        switch (this.attribute.style) {
          case 'select':
            this.attribute.style = {
              value: 'select',
              name: 'کومبو'
            }
            break;
          case 'box':
            this.attribute.style = {
              value: 'box',
              name: 'مربعی'
            }
            break;
          case 'image': {
            this.attribute.style = {
              value: 'box',
              name: 'مربعی با عکس'
            }
          }
        }
        this.$root.$emit('bv::show::modal', 'attribute-modal')
        this.attribute.index = index
      } catch (error) {
        this.$root.notify(error)
      } finally {
        this.disabled = false
      }
    },
    newAttribute() {
      this.submit_url = this.url
      this.mode = 'create'
      this.attribute = {
        name: '',
        label: '',
        type: '',
        show_filter: 0,
        status: 0,
        values: [{
          value: ''
        }]
      }
    },
    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.name,
        searchBy1: 'name',
        search2: this.filter.type ? this.filter.type.value : '',
        searchBy2: 'type',
        search3: this.filter.show_filter ? this.filter.show_filter.value : '',
        searchBy3: 'show_filter',
        search4: this.filter.status ? this.filter.status.value : '',
        searchBy4: 'status'
      }
    }
  }
}
