import Discount from '@@/core/components/product/shared/Discount/Discount'
import {BFormGroup, BFormInput} from "bootstrap-vue";

export default {
  name: "VarietyInformation",
  components: {Discount, BFormGroup, BFormInput},
  props: {
    value: Object,
    gifts: Array
  },
  data() {
    return {
      realValue: this.value,
    }
  },
  watch: {
    value: function (newVal) {
      this.realValue = newVal
    },
    realValue: function (newVal) {
      this.$emit('input', newVal);
    }
  },
}
