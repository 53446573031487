import {BFormGroup, BFormInput, BTable} from "bootstrap-vue";

export default {
  components: {
    BFormGroup, BFormInput, BTable,
  },
  name: 'SetForm',
  props: {
    value: Object,
    mode: {
      default: 'create',
      type: String
    },
    products: Array,
    url: String
  },
  data() {
    return {
      productSet: this.value,
      disabled: false,
      productSelect: null,
      fields: [{
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'title',
          label: ' عنوان'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
    }
  },
  watch: {
    productSet(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.productSet = newVal;
    },
  },
  methods: {
    setDisabled(val) {
      this.disabled = val
    },
    setProduct(item) {
      let product = item
      const findProduct = this.productSet.products.find((item) => {
        return item.id == product.id;
      })
      if (findProduct) {
        this.$root.notify('این محصول قبلا انتخاب شده است.', 'warning')
      } else {
        this.productSet.products.push(product)
      }

      this.productSelect = ''
    },
    deleteItem(index) {
      window.swal({
        title: "آیتم حذف شود؟",
        text: "این عملیات غیر قابل بازگشت خواهد بود",
        icon: "warning",

        showCancelButton: true,
        showConfirmButton: true,
        dangerMode: true,
        buttons: ["خیر", "بله حذف کن"]
      })
          .then((willDelete) => {
            if (willDelete) {
              this.$root.$delete(this.productSet.products, index)
            }
          })
          .catch(() => {

          })
    },
    async submit(e) {
      e.preventDefault()
      this.disabled = true

      try {
        let formData = new FormData(e.target)
        // formData.append('type', this.specification.type ? this.specification.type.value : '')
        formData.append('_method', this.mode == 'edit' ? 'put' : 'post')
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response, 'success')
        this.$router.push('/admin/sets')
      } catch (error) {
        this.$root.notify(error)
      } finally {
        this.disabled = false
      }
    },
  },
}
