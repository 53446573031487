<template>
  <b-form-group>
    <label>
      دسته بندی <i class="text-danger">*</i></label>
    <multiselect
        @open="getCategories"
        v-model="selectedCategories"
        placeholder="انتخاب کنید"
        :allow-empty="true"
        open-direction="bottom"
        :options="options"
        label="label"
        track-by="id"
        :searchable="true"
        :multiple="true"
        :close-on-select="false"
        :show-labels="false"
        :disabled="disabled"
    >
      <template slot="noOptions">
        انتخاب کنید
      </template>
      <template slot="noResult">
        <span v-if="isRequesting" v-text="'در حال بارگزاری...'" />
        <span v-else v-text="'موردی یافت نشد'"></span>
      </template>
    </multiselect>
  </b-form-group>


</template>

<script>
import {BFormGroup} from 'bootstrap-vue'

export default {
  name: "CategoriesSearch",
  components: {BFormGroup},
  data() {
    return {
      categories: null,
      isRequesting: true,
      selectedCategories: []
    }
  },
  props: {
    disabled: Boolean
  },
  watch: {
    selectedCategories() {
      this.$emit('input', this.selectedCategories)
    }
  },
  computed: {
    options() {
      let arr = [];
      if (!this.categories) {
        return []
      }
      this.categories.forEach(c => {
        c.label = c.title;
        arr.push(c)
        c.children.forEach(child => {
          this.makeCategories(child, c, arr)
        })
      })
      return arr
    }
  },
  methods: {
    makeCategories(cat, parent = null, arr = []) {
      cat.label = parent ? parent.label + '--' + cat.title  : cat.title;
      arr.push(cat)
      cat.children.forEach(child => {
        this.makeCategories(child, cat, arr)
      })

      return arr
    },
    async getCategories() {
      if (this.categories) {
        return
      }
      try {
        const response = await this.$axios.get('admin/categories?all=1');
        this.categories = response.data.data.categories;
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.isRequesting = false
      }
    }
  }
}
</script>

<style scoped>

</style>
