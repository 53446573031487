import Back from '@@/core/components/shared/Back/Back';
import orderForm from '@@/core/components/order/OrderForm/OrderForm';

export default {
  components: {
    Back,
    orderForm,
  },
  data() {
    return {
      title: "",
      url: "",
      axiosCancelSource: null,
      order: {
        customer: null,
        address:null,
        shipping: null,
        discount_amount: 0,
        description: "",
        varieties: [],
      },
      customers: [],
      shippings: [],
      provinces: []
    };
  },
  mounted() {
     this.loadData();
    if (this.$route.params.id) {
      this.url = "admin/orders/" + this.$route.params.id;
      this.getOrder();
    } else {
      this.$refs.orderForm.setDisabled(false);
      this.url = "admin/orders";
    }

  },
  methods: {
    async getOrder() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(this.url, {
          cancelToken: this.axiosCancelSource.token,
        });
        this.order = response.data.data.order;

        this.$refs.orderForm.setDisabled(false);
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error);
      }
    },
    async loadData() {
      const response = await this.$axios.get("admin/orders/create", {
        params: {
          without_customers: true
        }
      });
      this.customers = response.data.data.customers;
      this.shippings = response.data.data.shippings;
      // let products = response.data.data.list_products.filter(p => p.total_quantity > 0)
      // this.products = products.map(product => {
      //   product.varieties.forEach(variety => {
      //     variety.product = cloneDeep(product)
      //   });
      //   return product
      // });
      // this.products = products

      this.provinces = response.data.data.provinces;
    },
  },
};
