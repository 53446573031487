<template>
  <textarea :id="id" :value="oldValue"></textarea>
</template>

<script>
import CkeditorImpl from '@@/core/components/shared/Ckeditor/CkeditorImpl'
export default {
  extends: CkeditorImpl
}
</script>

<style scoped>

</style>