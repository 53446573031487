<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">{{ $route.params.id ? 'ویرایش سایز چارت' : 'سایز چارت جدید' }}</h5>
        <div style="flex:1"></div>
        <Back/>
      </div>
      <div class="card-body">
        <b-form @submit.prevent="submit">
        <b-form-group class="col-md-12" >
          <label for="title"> عنوان <i class="text-danger">*</i></label>
          <b-form-input :disabled="disabled" ref="title" id="title" :value="title"></b-form-input>
        </b-form-group>
        <SizeForm v-model="data" ref="sizeForm" />
          <div class="col-md-12 text-center mt-5">
            <button :disabled="disabled" type="submit" class="btn btn-success">
              {{ mode == 'edit' ? ' ویرایش سایز چارت' : ' ثبت سایز چارت' }}
            </button>
          </div>
        </b-form>
      </div>
    </div>
  </main>
</template>
<script>
import SizeChartCreateEditImpl from '@@/core/pages/sizeChart/SizeChartCreateEdit/SizeChartCreateEditImpl'
export default {
  extends: SizeChartCreateEditImpl
}
</script>