<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">فروشگاه حضوری</h5>
      </div>
      <div class="card-body">
        <router-link to="/admin/shop/create" class="btn btn-success">خرید جدید</router-link>
        <router-link to="/admin/shop/orders" class="btn btn-secondary mr-3">لیست فروش</router-link>
        <div style="height: 400px" class="mt-3">
          <Chart1 mode="offline" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Chart1 from "@@/core/components/dashboard/Chart1/Chart1";
export default {
  name: "Shop",
  components: {Chart1}
}
</script>

<style scoped>

</style>
