<template>
  <div>
    <!--Page header-->
    <div class="page-header d-xl-flex d-block mt-0 mb-3">
      <div class="page-leftheader">
        <h4 class="page-title">
          <span class="font-weight-normal text-muted ml-2">داشبورد</span>
        </h4>
      </div>
      <div v-if="10 / 2 === 2" class="page-leftheader mr-md-auto">
        <div
          class="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right"
        >
          <div class="d-flex">
            <div class="header-datepicker ml-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="feather feather-calendar"></i>
                  </div>
                </div>
                <input
                  class="form-control fc-datepicker"
                  placeholder="19 Feb 2020"
                  type="text"
                />
              </div>
            </div>
            <div class="header-datepicker ml-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <i class="feather feather-clock"></i>
                  </div>
                </div>
                <!-- input-group-prepend -->
                <input
                  id="tpBasic"
                  type="text"
                  placeholder="09:30am"
                  class="form-control input-small"
                />
              </div>
            </div>
            <!-- wd-150 -->
          </div>
          <div class="d-lg-flex d-block">
            <div class="btn-list">
              <button
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#clockinmodal"
              >
                Clock In
              </button>
              <button
                class="btn btn-light"
                data-toggle="tooltip"
                data-placement="top"
                title="E-mail"
              >
                <i class="feather feather-mail"></i>
              </button>
              <button
                class="btn btn-light"
                data-placement="top"
                data-toggle="tooltip"
                title="Contact"
              >
                <i class="feather feather-phone-call"></i>
              </button>
              <button
                class="btn btn-primary"
                data-placement="top"
                data-toggle="tooltip"
                title="Info"
              >
                <i class="feather feather-info"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Page header-->

    <!--Row-->
    <div class="row">
      <div class="col-xl-9 col-md-12 col-lg-12">
        <div class="row">
          <div class="numbers col-xl-4 col-lg-6 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <div class="mt-0 text-right">
                      <span class="fs-14 font-weight-semibold"
                        >تعداد کل سفارشات</span
                      >
                      <h3 v-if="flags.ordersCount" class="mb-0 mt-1 mb-2">
                        <template
                          v-if="!loader.ordersCount && data.ordersCount"
                        >
                          {{ data.ordersCount.orders_count | price }}
                        </template>
                        <template v-else>
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </template>
                      </h3>

                      <!--                      <span class="text-muted">-->
                      <!--                                  <span class="text-success fs-12 mt-2 ml-1"><i-->
                      <!--                                      class="feather feather-arrow-up-right ml-1 bg-success-transparent p-1 brround"></i>124</span>-->
                      <!--                                  for last month-->
                      <!--                                </span>-->
                    </div>
                  </div>
                  <div class="col-4">
                    <div
                      class="icon1 bg-success-transparent my-auto  float-left"
                    >
                      <i class="feather feather-users"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dashboardItemHandler">
             
              <button
                class="btn btn-primary"
                @click="showAndFetchData('ordersCount')"
              >
                <i class="fa fa-refresh"></i>
              </button>
            </div>
          </div>
          <div class="numbers col-xl-4 col-lg-6 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <div class="mt-0 text-right">
                      <span class="fs-14 font-weight-semibold"
                        >تعداد سفارشات امروز</span
                      >
                      <h3 v-if="flags.todayOrdersCount" class="mb-0 mt-1 mb-2">
                        <template
                          v-if="
                            !loader.todayOrdersCount && data.todayOrdersCount
                          "
                        >
                          {{ data.todayOrdersCount.today_orders_count | price }}
                        </template>
                        <template v-else>
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </template>
                      </h3>
                      <!--                      <span class="text-muted">-->
                      <!--                                  <span class="text-danger fs-12 mt-2 ml-1"><i-->
                      <!--                                      class="feather feather-arrow-down-left ml-1 bg-danger-transparent p-1 brround"></i>13</span>-->
                      <!--                                  for last month-->
                      <!--                                </span>-->
                    </div>
                  </div>
                  <div class="col-4">
                    <div
                      class="icon1 bg-primary-transparent my-auto  float-left"
                    >
                      <i class="feather feather-box"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dashboardItemHandler">
             
              <button
                class="btn btn-primary"
                @click="showAndFetchData('todayOrdersCount')"
              >
                <i class="fa fa-refresh"></i>
              </button>
            </div>
          </div>
          <div class="numbers col-xl-4 col-lg-12 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <div class="mt-0 text-right">
                      <span class="fs-14 font-weight-semibold"
                        >میزان فروش امروز</span
                      >
                      <h3
                        v-if="flags.salesAmountByToday"
                        class="mb-0 mt-1  mb-2"
                      >
                        <template
                          v-if="
                            !loader.salesAmountByToday &&
                              data.salesAmountByToday
                          "
                        >
                          {{
                            data.salesAmountByToday.sales_amount_by_today
                              .amount | price
                          }}
                          <span style="font-size: 12px">تومان</span>
                        </template>
                        <template v-else>
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </template>
                      </h3>
                    </div>
                    <!--                    <span class="text-muted">-->
                    <!--                                <span class="text-danger fs-12 mt-2 ml-1"><i-->
                    <!--                                    class="feather feather-arrow-up-right ml-1 bg-danger-transparent p-1 brround"></i>21.1% </span>-->
                    <!--                                for last month-->
                    <!--                              </span>-->
                  </div>
                  <div class="col-4">
                    <div
                      class="icon1 bg-secondary-transparent brround my-auto  float-left"
                    >
                      <i class="feather feather-dollar-sign"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dashboardItemHandler">
             
              <button
                class="btn btn-primary"
                @click="showAndFetchData('salesAmountByToday')"
              >
                <i class="fa fa-refresh"></i>
              </button>
            </div>
          </div>
          <div
            
            class="col-xl-12 col-md-12 col-lg-12 chart1-container dCard"
          >
          <div class="dashboardItemHandler" style="justify-content: flex-start;">
              <button
                class="btn btn-primary"
                @click="flags.chart1=!flags.chart1"
              >
                <i v-if="!flags.chart1" class="fa fa-eye"></i>
                <i v-else class="fa fa-eye-slash"></i>
              </button>
             
            </div>
            <div v-if="!flags.chart1" class="card ">
              <div class="card-header border-0 responsive-header">
                <h4 class="card-title">آمار فروش</h4>
              </div>
            </div>
            <Chart1 v-if="flags.chart1" />
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-12 col-lg-12 logs">
        <div class="dashboardItemHandler">
          
          <button
            class="btn btn-primary"
            @click="showAndFetchData('logs')"
          >
            <i class="fa fa-refresh"></i>
          </button>
        </div>
        <div class="card overflow-hidden">
          <div class="card-header border-0">
            <h4 class="card-title">آخرین فعالیت ها</h4>
          </div>
          <!--  v-if="flags.logs" -->
          <div v-if="flags.logs" class="pt-2">
            <template v-if="!loader.logs && data.logs">
              <ActivityLog :logs="data.logs.logs" />
            </template>
            <template v-else>
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div id="chart-2" class="col-12 col-md-4 mt-5 dCard">
        <div class="dashboardItemHandler">
          
          <button
            class="btn btn-primary"
            @click="showAndFetchData('orderByStatus')"
          >
            <i class="fa fa-refresh"></i>
          </button>
        </div>
        <i
          onclick="document.getElementById('chart-2').classList.toggle('col-md-4')"
          style="position:absolute;z-index: 1;right: 20px;top: 50px"
          class="feather feather-maximize"
        ></i>
        <div
          v-if="
            (!flags.orderByStatus && !data.orderByStatus) ||
              loader.orderByStatus
          "
          class="card"
        >
          <div class="card-header border-0">
            <h4 class="card-title">وضعیت سفارشات</h4>
          </div>
          <template v-if="loader.orderByStatus">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </template>
        </div>
        <template
          v-if="
            data.orderByStatus && flags.orderByStatus && !loader.orderByStatus
          "
        >
          <Chart2 :data="data.orderByStatus.orders_by_status" />
        </template>
      </div>
      <div class="col-xl-4 col-md-12 col-lg-6 mt-5 dCard">
        <div class="dashboardItemHandler">
       
          <button
            class="btn btn-primary"
            @click="showAndFetchData('lastLogins')"
          >
            <i class="fa fa-refresh"></i>
          </button>
        </div>
        <div class="card">
          <div class="card-header border-0">
            <h4 class="card-title">آخرین ورود ها به سیستم</h4>
            <div class="card-options">
              <div>
                <a
                  href="#"
                  class="btn btn-outline-light"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  مشاهده همه
                </a>
              </div>
            </div>
          </div>
          <div v-if="flags.lastLogins" class="card-body">
            <template v-if="!loader.lastLogins && data.lastLogins">
              <ul class="timeline">
                <li
                  v-for="login in data.lastLogins.last_logins"
                  :key="login.id"
                >
                  <a class="font-weight-semibold fs-15 mr-3">ورود به سیستم</a>
                  <a class="text-muted float-left fs-13">{{
                    moment(login.created_at).fromNow()
                  }}</a>
                  <p class="mb-0 pb-0 text-muted pt-1 fs-11 mr-3">
                    {{ login.tokenable.mobile }}
                  </p>
                  <span class="text-muted  mr-3 fs-11">
                    {{
                      moment(login.created_at).format("YYYY/MM/D HH:mm")
                    }}</span
                  >
                </li>
                <!--              <li class="primary">-->
                <!--              </li>-->
                <!--              <li class="pink">-->
                <!--              </li>-->
                <!--              <li class="success">-->
                <!--              </li>-->
              </ul>
            </template>
            <template v-else>
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- visit section start -->

      <div class="col-xl-4 col-md-12 col-lg-6 mt-5 dCard">
        <div class="dashboardItemHandler">
          
          <button
            class="btn btn-primary"
            @click="showAndFetchData('siteViews')"
          >
            <i class="fa fa-refresh"></i>
          </button>
        </div>
        <div class="card">
          <div class="card-header border-0">
            <h4 class="card-title">آخرین بازدید های سایت</h4>
            <div class="card-options">
              <div>
                <router-link
                  to="/admin/reports/visit"
                  class="btn btn-outline-light"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  مشاهده همه
                </router-link>
              </div>
            </div>
          </div>
          <div v-if="flags.siteViews" id="lastVisit" class="card-body">
            <template v-if="!loader.siteViews && data.siteViews">
              <ul class="timeline">
                <template
                  v-for="(count, date, index) in data.siteViews.site_views"
                >
                  <li v-if="index < 7" :key="index">
                    <a class="font-weight-semibold fs-15 mr-3">{{
                      new Date(date).toLocaleDateString("fa")
                    }}</a>

                    <span class=" mr-3 fs-16"> {{ count }}</span>
                  </li>
                </template>
              </ul>
            </template>
            <template v-else>
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- visit section ended -->
      <div class="col-xl-6 col-md-12 col-lg-12 mt-5 dCard">
        <div class="dashboardItemHandler">
          
          <button
            class="btn btn-primary"
            @click="showAndFetchData('genderStatistics')"
          >
            <i class="fa fa-refresh"></i>
          </button>
        </div>
        <div class="card chart-donut1">
          <div class="card-header  border-0">
            <h4 class="card-title">کاربران</h4>
          </div>
          <div v-if="flags.genderStatistics" class="card-body">
            <template v-if="!loader.genderStatistics && data.genderStatistics">
              <div id="employees" class="mx-auto apex-dount"></div>
              <div
                class="sales-chart pt-5 pb-3 d-flex mx-auto text-center justify-content-center "
              >
                <div class="d-flex ml-5">
                  <span class="dot-label bg-primary ml-2 my-auto"></span>مرد
                </div>
                <div class="d-flex ml-5">
                  <span class="dot-label bg-secondary  ml-2 my-auto"></span>زن
                </div>
                <div class="d-flex">
                  <span class="dot-label bg-warning  ml-2 my-auto"></span>انتخاب
                  نشده
                </div>
              </div>
            </template>
            <template v-else>
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-12  mt-5 dCard">
        <div class="dashboardItemHandler">
          
          <button
            class="btn btn-primary"
            @click="showAndFetchData('comments')"
          >
            <i class="fa fa-refresh"></i>
          </button>
        </div>
        <div class="card">
          <div class="card-header border-bottom-0">
            <h3 class="card-title">نظرات جدید</h3>
            <!--            <div class="card-options">-->
            <!--              <div class="dropdown"><a href="#" class="btn btn-outline-light dropdown-toggle" data-toggle="dropdown"-->
            <!--                                       aria-expanded="false"> See All <i class="feather feather-chevron-down"></i> </a>-->
            <!--                <ul class="dropdown-menu dropdown-menu-left" role="menu">-->
            <!--                  <li><a href="#">Monthly</a></li>-->
            <!--                  <li><a href="#">Yearly</a></li>-->
            <!--                  <li><a href="#">Weekly</a></li>-->
            <!--                </ul>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <template v-if="flags.comments">
            <template v-if="!loader.comments && data.comments">
              <div class="tab-menu-heading table_tabs mt-2 p-0 ">
                <div class="tabs-menu1">
                  <!-- Tabs -->
                  <ul class="nav panel-tabs">
                    <li class="mr-4">
                      <a class="active" href="#tab5" data-toggle="tab"
                        >نظرات محصولات ({{
                          data.comments.comments.new_product_comments_count
                        }})</a
                      >
                    </li>
                    <li>
                      <a href="#tab6" data-toggle="tab"
                        >نظرات بلاگ ({{
                          data.comments.comments.new_blog_comments_count
                        }})</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="panel-body tabs-menu-body table_tabs1 p-0 border-0">
                <div class="tab-content">
                  <div class="tab-pane active" id="tab5">
                    <div
                      class="table-responsive recent_jobs pt-2 pb-2 pl-2 pr-2 card-body"
                    >
                      <table class="table mb-0 text-nowrap">
                        <tbody>
                          <tr
                            v-for="comment in data.comments.comments
                              .product_comments"
                            :key="comment.id"
                            class="border-bottom"
                          >
                            <td>
                              <div class="d-flex">
                                <img
                                  :src="
                                    comment.product.images.length
                                      ? comment.product.images[0].url
                                      : require('@@/assets/admin/images/users/16.jpg')
                                  "
                                  alt="img"
                                  class="avatar avatar-md brround ml-3"
                                />
                                <div class="mr-3 mt-0 mt-sm-1 d-block">
                                  <router-link
                                    :to="
                                      '/admin/products/edit/' +
                                        comment.product.id
                                    "
                                    class="mb-0 cursor-pointer"
                                    >{{ comment.product.title }}</router-link
                                  >
                                  <div class="clearfix"></div>
                                  <small class="text-muted">{{
                                    comment.creator.full_name
                                  }}</small>
                                </div>
                              </div>
                            </td>
                            <td class="text-left fs-13">
                              {{ moment(comment.created_at).fromNow() }}
                            </td>
                            <td class="text-left fs-13">
                              <b-form-rating
                                v-model="comment.rate"
                                variant="warning"
                                :readonly="true"
                                class="border-0 comment-rate pb-0 pt-1 "
                              >
                              </b-form-rating>
                            </td>
                            <td class="text-right">
                              <a
                                @click="showProductComment(comment)"
                                class="action-btns cursor-pointer"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Contact"
                                ><i class="feather feather-eye text-primary"></i
                              ></a>
                              <!--                        <a href="#" class="action-btns" data-toggle="tooltip" data-placement="top" title="Mail"><i-->
                              <!--                            class="feather feather-mail  text-primary"></i></a>-->
                              <!--                        <a href="#" class="action-btns" data-toggle="tooltip" data-placement="top" title="Delete"><i-->
                              <!--                            class="feather feather-trash-2 text-danger"></i></a>-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <ProductCommentShow
                        v-if="showingProductComment"
                        @statusChanged="onProductCommentStatusChanged"
                        :comment="showingProductComment"
                      />
                    </div>
                  </div>
                  <div class="tab-pane" id="tab6">
                    <div
                      class="table-responsive recent_jobs pt-2 pb-2 pl-2 pr-2 card-body"
                    >
                      <table class="table mb-0 text-nowrap">
                        <tbody>
                          <tr
                            v-for="comment in data.comments.comments
                              .blog_comments"
                            :key="comment.id"
                            class="border-bottom"
                          >
                            <td>
                              <div class="d-flex">
                                <div class="table_img brround bg-light ml-3">
                                  <span class="bg-light brround fs-12"
                                    ><img
                                      v-if="comment.commentable.image"
                                      :src="comment.commentable.image.url"
                                      alt=""
                                  /></span>
                                </div>
                                <div class="ml-3 mt-3 d-block">
                                  <h6 class="mb-0 fs-13 font-weight-semibold">
                                    {{ comment.commentable.title }}
                                  </h6>
                                  <div class="clearfix"></div>
                                  <small class="text-muted">{{
                                    comment.name
                                  }}</small>
                                </div>
                              </div>
                            </td>
                            <td class="text-left fs-13">
                              {{ moment(comment.created_at).fromNow() }}
                            </td>
                            <td class="text-right">
                              <div>
                                {{ comment.body.substring(0, 30) }}
                                {{ comment.body.length > 30 ? "..." : "" }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="tab-pane " id="tab7">
                    <div
                      class="table-responsive recent_jobs pt-2 pb-2 pl-2 pr-2 card-body"
                    >
                      <table class="table mb-0 text-nowrap">
                        <tbody>
                          <tr class="border-bottom">
                            <td>
                              <div class="d-flex">
                                <img
                                  src="@@/assets/admin/images/users/16.jpg"
                                  alt="img"
                                  class="avatar avatar-md brround ml-3"
                                />
                                <div class="mr-3 mt-0 mt-sm-1 d-block">
                                  <h6 class="mb-0">Faith Harris</h6>
                                  <div class="clearfix"></div>
                                  <small class="text-muted">UI designer</small>
                                </div>
                              </div>
                            </td>
                            <td class="text-left fs-13">5 years</td>
                            <td class="text-left fs-13">
                              <i
                                class="feather feather-map-pin text-muted mr-2"
                              ></i
                              >USA
                            </td>
                            <td class="text-right">
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Contact"
                                ><i
                                  class="feather feather-phone-call text-primary"
                                ></i
                              ></a>
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Mail"
                                ><i
                                  class="feather feather-mail  text-primary"
                                ></i
                              ></a>
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                ><i
                                  class="feather feather-trash-2 text-danger"
                                ></i
                              ></a>
                            </td>
                          </tr>
                          <tr class="border-bottom">
                            <td>
                              <div class="d-flex">
                                <img
                                  src="@@/assets/admin/images/users/1.jpg"
                                  alt="img"
                                  class="avatar avatar-md brround ml-3"
                                />
                                <div class="mr-3 mt-0 mt-sm-1 d-block">
                                  <h6 class="mb-0">James Paige</h6>
                                  <div class="clearfix"></div>
                                  <small class="text-muted">UI designer</small>
                                </div>
                              </div>
                            </td>
                            <td class="text-left fs-13">2 years</td>
                            <td class="text-left fs-13">
                              <i
                                class="feather feather-map-pin text-muted mr-2"
                              ></i
                              >India
                            </td>
                            <td class="text-right">
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Contact"
                                ><i
                                  class="feather feather-phone-call text-primary"
                                ></i
                              ></a>
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Mail"
                                ><i
                                  class="feather feather-mail  text-primary"
                                ></i
                              ></a>
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                ><i
                                  class="feather feather-trash-2 text-danger"
                                ></i
                              ></a>
                            </td>
                          </tr>
                          <tr class="border-bottom">
                            <td>
                              <div class="d-flex">
                                <img
                                  src="@@/assets/admin/images/users/4.jpg"
                                  alt="img"
                                  class="avatar avatar-md brround ml-3"
                                />
                                <div class="mr-3 mt-0 mt-sm-1 d-block">
                                  <h6 class="mb-0">Liam Miller</h6>
                                  <div class="clearfix"></div>
                                  <small>WireFrameing</small>
                                </div>
                              </div>
                            </td>
                            <td class="text-left fs-13">1 years</td>
                            <td class="text-left fs-13">
                              <i
                                class="feather feather-map-pin text-muted mr-2"
                              ></i
                              >Germany
                            </td>
                            <td class="text-right">
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Contact"
                                ><i
                                  class="feather feather-phone-call text-primary"
                                ></i
                              ></a>
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Mail"
                                ><i
                                  class="feather feather-mail  text-primary"
                                ></i
                              ></a>
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                ><i
                                  class="feather feather-trash-2 text-danger"
                                ></i
                              ></a>
                            </td>
                          </tr>
                          <tr class="border-bottom">
                            <td>
                              <div class="d-flex">
                                <img
                                  src="@@/assets/admin/images/users/8.jpg"
                                  alt="img"
                                  class="avatar avatar-md brround ml-3"
                                />
                                <div class="mr-3 mt-0 mt-sm-1 d-block">
                                  <h6 class="mb-0">Kimberly Berry</h6>
                                  <div class="clearfix"></div>
                                  <small>Senior Prototyper</small>
                                </div>
                              </div>
                            </td>
                            <td class="text-left fs-13">3 years</td>
                            <td class="text-left fs-13">
                              <i
                                class="feather feather-map-pin text-muted mr-2"
                              ></i
                              >USA
                            </td>
                            <td class="text-right">
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Contact"
                                ><i
                                  class="feather feather-phone-call text-primary"
                                ></i
                              ></a>
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Mail"
                                ><i
                                  class="feather feather-mail  text-primary"
                                ></i
                              ></a>
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                ><i
                                  class="feather feather-trash-2 text-danger"
                                ></i
                              ></a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex">
                                <img
                                  src="@@/assets/admin/images/users/9.jpg"
                                  alt="img"
                                  class="avatar avatar-md brround ml-3"
                                />
                                <div class="mr-3 mt-0 mt-sm-1 d-block">
                                  <h6 class="mb-0">Kimberly Berry</h6>
                                  <div class="clearfix"></div>
                                  <small>Senior Prototyper</small>
                                </div>
                              </div>
                            </td>
                            <td class="text-left fs-13">3 years</td>
                            <td class="text-left fs-13">
                              <i
                                class="feather feather-map-pin text-muted mr-2"
                              ></i
                              >USA
                            </td>
                            <td class="text-right">
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Contact"
                                ><i
                                  class="feather feather-phone-call text-primary"
                                ></i
                              ></a>
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Mail"
                                ><i
                                  class="feather feather-mail  text-primary"
                                ></i
                              ></a>
                              <a
                                href="#"
                                class="action-btns"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                ><i
                                  class="feather feather-trash-2 text-danger"
                                ></i
                              ></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12  mt-5 dCard">
        <div class="dashboardItemHandler">
          
          <button
            class="btn btn-primary"
            @click="showAndFetchData('lastOrders')"
          >
            <i class="fa fa-refresh"></i>
          </button>
        </div>
        <div class="card">
          <div class="card-header border-0">
            <h3 class="card-title">آخرین سفارشات</h3>
            <div class="card-options ">
              <router-link to="/admin/order" class="btn btn-outline-light ml-3"
                >مشاهده همه</router-link
              >
            </div>
          </div>
          <div
            v-if="flags.lastOrders"
            class="table-responsive attendance_table mt-4 border-top"
          >
            <template v-if="!loader.lastOrders && data.lastOrders">
              <table class="table mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th class="text-left">شناسه</th>
                    <th class="text-left">وضعیت</th>
                    <th class="text-center">تعداد محصولات</th>
                    <th class="text-center">مبلغ</th>
                    <th class="text-center">تاریخ</th>
                    <th class="text-center">مشاهده</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="order in data.lastOrders.last_orders"
                    :key="order.id"
                    class="border-bottom"
                  >
                    <td class="text-center">
                      <span class="avatar avatar-sm brround">{{
                        order.id
                      }}</span>
                    </td>
                    <td class="text-center">
                      <order-status :status="order.status" />
                    </td>
                    <td class="text-center">
                      {{ getTotalItemsInOrder(order) }}
                    </td>
                    <td class="text-center">
                      {{ order.total_amount | price }}
                    </td>
                    <td class="text-center">
                      {{ moment(order.created_at).format("YYYY/MM/D HH:mm") }}
                    </td>
                    <td class="text-center">
                      <router-link
                        :to="'admin/order/' + order.id"
                        class="action-btns"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="مشاهده سفارش"
                        ><i class="feather feather-eye text-primary"></i
                      ></router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardImpl from "@@/core/pages/Dashboard/DashboardImpl";
import ActivityLog from "@@/core/components/dashboard/ActivityLog/ActivityLog";
export default {
  components: { ActivityLog },
  extends: DashboardImpl,
};
</script>

<style scoped src="@@/core/pages/Dashboard/DashboardStyle.css"></style>
<style>
.numbers .dashboardItemHandler {
  background: #d2d2d2;
}
.dashboardItemHandler {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-evenly;
  background: white;
  border-radius: 13px 0 0 13px;
}
.numbers .card {
  border-radius: 0 13px 13px 0;
}

.numbers {
  display: flex;
}
.numbers .dashboardItemHandler {
  margin-bottom: 1.5rem;
}

.dashboardItemHandler button {
  transform: scale(0.8);
}
.logs .dashboardItemHandler button {
  transform: scale(1);
}
.logs .dashboardItemHandler,
.dCard .dashboardItemHandler {
  border-radius: 13px 0 0 0;
  flex-direction: row;
  justify-content: space-around;
  padding: 4px;
  width: max-content;
  min-width: 31%;
}
.logs .card-header,
.dCard .card-header {
  border-radius: 0 0 13px 13px;
}
.logs .card,
.dCard .card {
  border-radius: 0;
}

.spinner-border {
  align-self: center;
  margin: auto 30%;
}
</style>
