import {loadScript} from '@@/core/libraries/VueMethods'
// JS


// CSS
import '@@/core/assets/admin/plugins/bootstrap/css/bootstrap.css'
import '@@/core/assets/admin/plugins/p-scrollbar/p-scrollbar.css'
import '@@/core/assets/admin/plugins/sidebar/sidebar.css'
import '@@/core/assets/admin/plugins/select2/select2.min.css'
import '@@/core/assets/admin/plugins/jvectormap/jqvmap.css'
import '@@/core/assets/admin/plugins/datatable/css/dataTables.bootstrap4.min-rtl.css'
import '@@/core/assets/admin/plugins/time-picker/jquery.timepicker.css'
import '@@/core/assets/admin/plugins/jQuery-countdowntimer/jQuery.countdownTimer.css'

import '@@/core/assets/admin/scss-rtl/style.scss'
import '@@/core/assets/admin/custom.css'

export default {
    created() {
        setTimeout(() => {
            [
              '/assets/admin/plugins/jquery/jquery.min.js',
              '/assets/admin/plugins/bootstrap/popper.min.js',
              '/assets/admin/plugins/bootstrap/js/bootstrap.min.js',
              '/assets/admin/plugins/date-picker/jquery-ui.js',
              '/assets/admin/plugins/moment/moment.js',
              '/assets/admin/plugins/p-scrollbar/p-scrollbar.js',
              '/assets/admin/plugins/select2/select2.full.min.js',
              // '/assets/admin/plugins/peitychart/jquery.peity.min.js',
              // '/assets/admin/plugins/peitychart/peitychart.init.js',
              '/assets/admin/plugins/apexchart/apexcharts.js',
              '/assets/admin/plugins/vertical-scroll/jquery.bootstrap.newsbox.js',
              '/assets/admin/plugins/vertical-scroll/vertical-scroll.js',
              '/assets/admin/plugins/chart/chart.bundle.js',
              '/assets/admin/plugins/chart/utils.js',
              '/assets/admin/plugins/time-picker/jquery.timepicker.js',
              '/assets/admin/plugins/time-picker/toggles.min.js',
              '/assets/admin/plugins/chart.min/chart.min.js',
              '/assets/admin/plugins/chart.min/rounded-barchart.js',
              '/assets/admin/plugins/jQuery-countdowntimer/jQuery.countdownTimer.js',
              '/assets/admin/js/accordion.js',
              '/assets/admin/plugins/accordion/accordion.min.js',
              '/assets/admin/plugins/sidebar/sidebar.js',
              '/assets/admin/plugins/sidemenu/sidemenu.js',
              '/assets/admin/plugins/p-scrollbar/p-scroll1.js',
              '/assets/admin/js/custom.js',
              '/assets/admin/js/apexchart-custom.js',
            ].forEach((src) => {
              loadScript(src)
            })
          }, 0)
    }
}
