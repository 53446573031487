<template>
  <div v-if="order && order.order_logs != ''" class="rounded mt-5">
    <h5 class="bg-light text-center rounded py-2 mb-0 font-bold">
      گزارش سفارش
    </h5>
    <b-table
        hover
        bordered
        responsive
        :items="order.order_logs"
        :fields="logFields"
    >
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template v-slot:cell(created_at)="data">
        <span style="word-spacing: 3px">{{ data.item.created_at | persianDate }}</span>
      </template>
      <template v-slot:cell(description)="data">
        <div class="text-right">
                <span
                    v-if="data.item.log_items != ''"
                >
                  <span>
                    آیتم با شناسه {{ data.item.log_items[0].order_item_id }}
                    {{
                      order.items.filter(
                          (item) =>
                              item.id == data.item.log_items[0].order_item_id
                      ) != ""
                          ? " ( " +
                          order.items.filter(
                              (item) =>
                                  item.id == data.item.log_items[0].order_item_id
                          )[0].product.title +
                          " ) "
                          : ""
                    }}
                    به تعداد {{ data.item.log_items[0].quantity }}
                    {{ getOrderLogLabel(data.item.log_items[0].type) }}
                  </span>
                  <!-- <span v-else-if="data.item.log_items[0].type == 'new'">
                    {{
                      order.items.filter(
                        (item) =>
                          item.id == data.item.log_items[0].order_item_id
                      ) != ""
                        ? " ( " +
                          order.items.filter(
                            (item) =>
                              item.id == data.item.log_items[0].order_item_id
                          )[0].product.title +
                          " ) "
                        : ""
                    }}
                    به تعداد {{ data.item.log_items[0].quantity }} به سفارش
                    اضافه شد
                  </span> -->
                </span>
          <span v-else-if="data.item.coupon">
                  به سفارش {{ data.item.coupon | price }} تومان تخفیف اعمال شد
                </span>

          <span v-if="data.item.address" class="d-block">
                  آدرس سفارش به آدرس :
                  {{
              JSON.parse(data.item.address).city
                  ? JSON.parse(data.item.address).city.province.name +
                  " - " +
                  JSON.parse(data.item.address).city.name +
                  " - " +
                  JSON.parse(data.item.address).address
                  : ""
            }}
                  تغییر کرد
                </span>
          <span v-if="data.item.shipping" class="d-block">
                  شیوه ارسال به {{ data.item.shipping.name }} تغییر کرد
                </span>
          <span v-if="data.item.status" class="d-block">
                  وضعیت سفارش به {{getStatusLabel(data.item.status)}} نغییر یافت
                </span>
          <span v-if="data.item.amount" class="d-block">
                  {{ data.item.amount > 0 ? "به" : "از" }} مبلغ سفارش
                  {{ data.item.amount | price }} تومان
                  {{ data.item.amount > 0 ? "اضافه" : "کم" }} شد
                </span>
        </div>
      </template>
    </b-table>

  </div>

</template>

<script>
import OrderLogsImpl from "@@/core/components/order/OrderLogs/OrderLogsImpl";

export default {
  name: "OrderLogs",
  extends: OrderLogsImpl,
}
</script>

<style scoped>

</style>
