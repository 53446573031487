<template>
  <div>
    <treeselect :options="discountOptions"
                noResultsText="موردی یافت نشد"
                @input="setType"
                v-model="realValue.discountType"
                :multiple="false"
                noOptionsText="موردی وجود ندارد"
                placeholder="تخفیف"></treeselect>
    <b-form-input class="mt-3 mb-3"
                  v-if="type === 'flat'"
                  :value="realValue.discount | priceinput" @input="value=>{realValue.discount = value !== '' ? +value.replace(/\D/g, '') : ''}"
                  type="text" placeholder="قیمت"/>
    <b-form-input class="mt-3 mb-3"
                  v-else-if="type === 'percentage'" min="1" max="100"
                  @change="discountChanged"
                  v-model="realValue.discount" type="number" placeholder="درصد تخفیف"/>

    <b-form-group v-if="type != 'none'">
      <date-picker type="datetime" v-model="realValue.discountUntil" format="YYYY/MM/DD HH:mm"
                   display-format="jYYYY/jMM/jDD HH:mm" color="#3366ff" />
    </b-form-group>
  </div>
</template>

<script>
import DiscountImpl from '@@/core/components/product/shared/Discount/DiscountImpl'
export default {
  extends: DiscountImpl
}
</script>

<style scoped>

</style>
