<template>
  <aside class="app-sidebar">
    <div class="app-sidebar__logo">
      <router-link to="/admin" class="header-brand">
        <img :src="$options.configProvider.get('logo')" class="header-brand-img desktop-lgo" alt="Logo">
        <img :src="$options.configProvider.get('logo')" class="header-brand-img dark-logo " alt="Logo">
        <!-- <img src="$options.configProvider.get('logo')" class="header-brand-img mobile-logo" alt="Logo"> -->
        <img :src="$options.configProvider.get('logo')" class="header-brand-img darkmobile-logo" alt="Logo">
      </router-link>
    </div>
    <div class="app-sidebar3">
      <Menu></Menu>
    </div>
  </aside>
</template>

<script>
import SidebarImpl from '@@/core/components/shared/Sidebar/SidebarImpl'
export default {
  extends: SidebarImpl
}
</script>

<style src="@@/core/components/shared/Sidebar/SidebarStylePublic.css"></style>
