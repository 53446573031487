import OrderPrintItem from '@@/core/components/order/OrderPrintItem/OrderPrintItem'
export default {
  components: {
    OrderPrintItem
  },
  data() {
    return {
      orders: [],
      settings:[]
    };
  },
  mounted() {
    if (this.$route.query) {
      this.getOrders(this.$route.query.ids);
      this.loadSettings()
    }
  },
  computed: {},
  methods: {
    async getOrders(ids) {
      let ordersId = ids.split(",");
      try {
        const response = await this.$axios.post("admin/orders/print/details", {
          ids: ordersId,
        });

        let orders = response.data.data.orders;
        orders.forEach((order) => {
          order.items.forEach((item) => {
            item.extra = JSON.parse(item.extra);
          });

          if (order.reserved == 1) {
            order.reservations.forEach((item) => {
              item.items.forEach((p) => {
                p.extra = JSON.parse(p.extra);
                order.items.push(p);
              });
            });
          }
          return order
        });
        this.orders = orders
      } catch (error) {
        this.$root.notify(error);
      }
    },
    loadSettings() {
        this.$axios.get('admin/settings/shop_info').then(response => {
            this.settings = response.data.data.settings
        })
    }
  },
};
