import {BCollapse, VBToggle} from "bootstrap-vue";

export default {
  components: {
    BCollapse
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    order: Object
  },
  computed: {
    invoices() {
      let fromOrderLogs = this.order.order_logs.map(orderLog => {
        return {
          amount: orderLog.amount,
          type: 'wallet',
          status: 'success',
          created_at: orderLog.created_at,
          by_admin: true
        }
      });

      let invoices = this.order.invoices.filter(
          (invoice) => invoice.status == "success"
      );
      this.order.reservations.forEach(reservation => {
        invoices = [...invoices, ...reservation.invoices.filter(invoice => invoice.status === 'success')]
      })
      let finalInvoices = [...invoices, ...fromOrderLogs]
      finalInvoices.sort((a,b) => {
        return this.moment(a.created_at, 'YYYY-MM-DD hh:mm:ss').unix() -
            this.moment(b.created_at, 'YYYY-MM-DD hh:mm:ss').unix()
      })

      return finalInvoices
    },
    sumPaidInvoices() {
      return this.invoices.reduce((amount, item) => (amount + item.amount), 0)
    },
  },
  methods: {
    getInvoiceSuccessPayment(invoice) {
      return invoice.payments.filter(payment => payment.status == 'success')[0] || null
    },
  }
}
