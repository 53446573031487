<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link :disabled="hasPermission('write_page') ? false : true" to="/admin/pages/create"
                   class="btn btn-success">صفحه جدید</router-link>
    </span>
    <b-tooltip v-if="!hasPermission('write_page')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>

    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">صفحات</h5>
      </div>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <router-link :disabled="!hasPermission('modify_page') ? true : false "
                             :to="{name:'page.edit' , params:{ id: data.item.id}}" class="action-btns1  pt-1 px-2">
                  <i class="feather feather-edit  text-primary"></i>
                </router-link>
              </span>
              <b-tooltip v-if="!hasPermission('modify_page')" :target="'edit-btn-' + data.item.id" triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a :disabled="!hasPermission('delete_page') ? true : false " class="action-btns1  pt-1 px-2 "
                   @click="deleteItem(data.item.id)">
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip v-if="!hasPermission('delete_page')" :target="'delete-btn-' + data.item.id" triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)"/>
          <pagination v-else-if="items.data != ''" :disabled="disabled" :limit="2" :data="items"
                      @pagination-change-page="changePage">
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>

  </main>

</template>

<script>
import PagesImpl from '@@/core/pages/page/Pages/PagesImpl'
export default {
  extends: PagesImpl
}
</script>

<style scoped>

</style>
