import {BFormGroup, BFormInput, BModal, BFormTextarea} from "bootstrap-vue";
import { getShippingPrice, setShipping } from "@@/core/libraries/functions";
export default {
  components: {
    BFormGroup, BFormInput, BModal, BFormTextarea
  },
  props: { value: Object, addresses: Array, shippings: Array },
  data() {
    return {
      order: this.value,
      addressShippings: [],
      disabled: false,
    };
  },
  computed: {
    getShippingPrice() {
      return getShippingPrice(this.order.address, this.order.shipping, null);
    },
  },
  watch: {
    value(newVal) {
      this.order = newVal;
      this.setShippingReal()
    },
    order(newVal) {
      this.$emit("input", newVal);
    },
    'order.address'(newVal) {
       this.setShippingReal()
    },
    addresses(newVal) {
      let addresses = newVal.map((ad) => {
        ad.label =
          ad.city.province.name +
          " ، " +
          ad.city.name +
          " ، " +
          ad.address +
          " - گیرنده : " +
          ad.first_name +
          " " +
          ad.last_name +
          " - کد پستی : " +
          ad.postal_code;
        return ad;
      });
      return addresses;
    },
  },
  methods: {
    setShipping(item) {
      // this.order.shipping = null;
      // this.addressShippings = setShipping(item, this.shippings);
    },
    setShippingReal() {
      this.$nextTick(() => {
        this.addressShippings = setShipping(this.order.address, this.shippings);
      })
    },
    async submit() {
      this.disabled = true;
      try {
        const response = await this.$axios.post(
          "admin/orders/" + this.order.id,
          {
            address_id: this.order.address ? this.order.address.id : "",
            shipping_id: this.order.shipping ? this.order.shipping.id : "",
            discount_amount: this.order.discount_amount,
            description: this.order.description,
            _method: "put",
          }
        );
        this.$emit("order-update", response.data.data.order , this.getShippingPrice);
        this.$root.notify(response.data.message, "success");
        this.$root.$emit("bv::hide::modal", "order-edit");
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
  },
};
