export default {
  data() {
    return {
      sortBy: null,
      sortDesc: null,
    }
  },
  methods: {
    sortChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.loadData()
    },
    getSortFilters() {
      return {
        sort_by: this.sortBy,
        sort_desc: this.sortDesc ? 1 : 0,
      }
    }
  }
}
