<template>
  <div id="sidebar" class="sidebar index-notification sidebar-right sidebar-animate">
    <div class="card-header border-bottom pb-5">
      <h4 class="card-title">اعلانات</h4>
      <div class="card-options">
        <a
          href="#"
          class="btn btn-sm btn-icon btn-light text-primary"
          data-toggle="sidebar-right"
          data-target=".sidebar-right"
          ><i class="feather feather-x"></i>
        </a>
      </div>
    </div>
    <div class="admin-notification" @scroll="onScroll">
      <template v-if="notifications != '' && notifications">
        <router-link
          class="notification-item list-group-item  align-items-center px-3 border-bottom"
          v-for="notification in notifications"
          :key="notification.id"
          :to="notification.to"
           data-toggle="sidebar-right" data-target=".sidebar-right"
        >
          <div class="notification-title">
            <span class="font-bold"
              >{{ notification.title }}
              <span
                class="badge badge-danger mx-1"
                style="transform: translateY(-1px)"
                v-if="!notification.read"
                >جدید</span
              ></span
            >
            <span class="created-at">{{
              moment(notification.created_at).format("HH:mm YYYY/MM/DD")
            }}</span>
          </div>
          <div class="notification-description">
            {{ notification.description }}
          </div>
        </router-link>
      </template>
      <h5 class="text-center font-bold mt-5" v-else>هنوز هیچ اعلانی ثبت نشده</h5>
      <Loading v-if="loading" />
    </div>
  </div>
</template>
<script>
import NotificationsImpl from "@@/core/components/shared/Notifications/NotificationsImpl";

export default {
  extends: NotificationsImpl,
};
</script>
<style scoped src="@@/core/components/shared/Notifications/NotificationsStyle.css"></style>
