import {BTable} from "bootstrap-vue";

export default {
  components: {BTable},
  props: {
    order: Object,
    disabled: Boolean,
    total: Number,
    totalPrice: Number
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "image",
          label: "تصویر",
        },
        {
          key: "product",
          label: "محصول",
        },
        {
          key: "amount",
          label: "قیمت واحد (تومان)",
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "discount_amount",
          label: "تخفیف واحد (تومان)",
        },
        {
          key: "flash",
          label: "کمپین",
        },
        {
          key: "total_price",
          label: "قیمت نهایی (تومان)",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
      ],
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === 0) return "table-order-status";
    },
    editItem(index) {
      let item = { ...this.order.items[index] };
      item.index = index;
      item.old_quantity = item.quantity;
      item.old_amount = item.amount * item.old_quantity;
      this.$parent.orderItem = item;
      this.$root.$emit("bv::show::modal", "update-order-item");
    },
  }
}
