import {BFormTextarea, BModal} from "bootstrap-vue";

export default {
    components: {BModal, BFormTextarea},
    props: ['id', 'body'],
    data() {
        return {
            answer: '',
            disabled: false
        }
    },
    methods: {
        async commentAnswer() {
            this.disabled = true
            try {
                const response = await this.$axios.post(`admin/comments/${this.id}/answer`, {
                    body: this.answer
                })
                this.$parent._data.items.children++
                this.$root.notify(response.data.message, 'success')
                this.$root.$emit('bv::hide::modal', 'comment-answer')
            } catch (error) {
                this.$root.notify(error)
            } finally {
                this.disabled = false
            }
        }
    }
}
