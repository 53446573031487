<template>
  <treeselect :class="uniqueId + ' ' + customClass + (hideOptions ? 'hide-options-treeselect' : '')" :options="options" ref="treeselect" :searchable="true"
              noResultsText="موردی یافت نشد"
              v-model="realValue" :multiple="true"
              noOptionsText="موردی وجود ندارد"
              @select="selected"
              :placeholder="placeholder">
    <div slot="value-label" slot-scope="{ node }">{{ node.label }}</div>
  </treeselect>
</template>

<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import TreeselectAddableImpl from '@@/core/components/product/shared/TreeselectAddable/TreeselectAddableImpl'
export default {
  extends: TreeselectAddableImpl
}
</script>
<style src="@@/core/components/product/shared/TreeselectAddable/TreeselectAddableStylePublic.css"></style>
