<template>
  <main v-if="reports != null" class="mt-5">
    <OrdersInfoModal :orders-info="ordersInfo" />
    <AttributesInfoModal :attributes-info="attributesInfo" />
    <div class="card mt-3">
      <div class="card-header  border-0 justify-content-between d-flex">
        <h5 class="card-title font-bold py-3">گزارش کاربران</h5>
        <ExcelDownload :extraQuery="extraQuery" url="admin/reports/customers" />
      </div>
      <div class="card-body">
        <OrderReportFilter
          v-model="filter"
          :disabled="disabled"
          class="card p-4 row"
          style="flex-direction: row"
        />
        <div class="card p-4">
          <h5 class="font-weight-bold mb-4">آمار کلی</h5>
          <div class="row">
            <div v-if="statistics.order_statuses != ''" class="col-12">
              <OrderStatusesView :order-statuses="statistics.order_statuses" />
            </div>
            <div v-else class="col-12">
              درحال دریافت اطلاعات ...
            </div>
            <KeyValues class="col-12 mt-4 row" :items="statistics" />
          </div>
        </div>
        <SelectPageCount
          :count="reports ? reports.total : false"
          v-model="per_page"
        />
        <b-table
          @sort-changed="sortChanged"
          :style="{ opacity: disabled ? '0.6' : 1 }"
          bordered
          responsive
          striped
          v-if="reports"
          :fields="fields"
          :items="reports.data"
        >
          <template v-slot:cell(index)="data">
            {{
              data.index +
                1 +
                (!disabled
                  ? (page - 1) * per_page
                  : (previousPage - 1) * per_page)
            }}
          </template>
          <template v-slot:cell(_total)="data">
            {{ data.item._total | price }}
          </template>
          <template v-slot:cell(latest_order)="data">
            {{ data.item.latest_order_date | persianDate }}
          </template>
          <template v-slot:cell(mobile)="data">
            <router-link
              :to="'/admin/customers/show/' + data.item.customer.id"
              >{{ data.item.customer.mobile }}</router-link
            >
          </template>
          <template v-slot:cell(attributes)="data">
            <button
              @click="showAttributesInfo(data.item)"
              class="btn btn-secondary"
              style="line-height: 0.8;"
            >
              جزییات
            </button>
          </template>
          <template v-slot:cell(orders)="data">
            <button
              @click="showOrdersInfo(data.item)"
              class="btn btn-primary"
              style="line-height: 0.8;"
            >
              {{ data.item.count }}
            </button>
          </template>
        </b-table>
        <div v-else-if="reports == ''" class="alert alert-danger">
          موردی یافت تشد
        </div>
        <div v-else class="alert alert-primary">در حال بارگزاری</div>
        <pagination
          :router="true"
          v-if="reports && reports.data != ''"
          :disabled="disabled"
          :limit="2"
          :data="reports"
          @pagination-change-page="changePage"
        />
      </div>
    </div>
  </main>
</template>

<script>
import {BTable} from 'bootstrap-vue'
import {getStatusLabel, getStatusColor} from "@@/core/libraries/adminFunctions";
import OrderStatusesView from "@@/core/components/report/OrderStatusesView";
import KeyValues from "@@/core/components/report/KeyValues";
import SelectPageCount from "@@/core/components/shared/SelectPageCount/SelectPageCount";
import OrdersInfoModal from "@@/core/components/report/OrdersInfoModal";
import {OrdersInfo} from "@@/core/components/report/OrdersInfoModal";
import AttributesInfoModal from "@@/core/components/report/AttributesInfoModal";
import OrderReportFilter from "@@/core/components/report/OrderReportFilter";
import orderReportFilterMixin from "@@/core/components/report/orderReportFilterMixin";
import sortMixin from "@@/core/libraries/mixins/sortMixin";
import ExcelDownload from "@@/core/components/excelDownload/ExcelDownload";

export default {
  name: "CustomersReport",
  components: {
    ExcelDownload,
    OrderReportFilter,
    AttributesInfoModal,
    SelectPageCount, OrdersInfoModal,
    KeyValues, OrderStatusesView, BTable},
  mixins: [orderReportFilterMixin, sortMixin],
  data() {
    return {
      show:false,
      ordersInfo: null,
      attributesInfo: null,
      page: 1,
      previousPage: 1,
      per_page: 50,
      disabled: true,
      reports: null,
      statistics: {
        order_statuses: '',
        total_income: null,
        total_shipping_amount: null,
        total_discount_amount: null,
        total_order_items_count: null,
      },
      fields: [
        {
          key: 'index',
          label: 'ردیف'
        },
        {
          key: 'full_name',
          label: 'نام مشتری',
        },
        {
          key: 'mobile',
          label: 'شماره موبایل',
          sortable: true
        },
        {
          key: 'orders_count',
          label: 'تعداد سفارشات',
          sortable: true
        },
        {
          key: 'order_items_count',
          label: 'تعداد قلم های خریداری شده',
          sortable: true
        },
        {
          key: 'attributes',
          label: 'ویژگی'
        },
        {
          key: 'orders',
          label: 'سفارشات'
        },
        {
          key: 'latest_order_date',
          label: 'تاریخ اخرین سفارش',
          sortable: true

        },
        {
          key: '_total',
          label: 'میزان خرید کل',
          sortable: true

        },
      ]
    }
  },
  watch: {
    per_page() {
      this.changePage(1)
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    getStatusLabel,
    getStatusColor,
    showOrdersInfo(report) {
      this.ordersInfo = new OrdersInfo(report.order_info, report.statuses_info);
      this.$root.$emit('bv::show::modal', 'orders-info')
    },
    showAttributesInfo(report) {
      this.attributesInfo = report.attribute_info
      this.$root.$emit('bv::show::modal', 'attributes-info')
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData()
    },
    async loadExtraContent(){
      const response = await this.$axios.get('admin/reports/customers/incomes-detail')
      for (let [key,value] of Object.entries(response.data.data)) {
          
          this.statistics[key] = value;
        }
    },
    async loadData() {
      this.loadExtraContent();
      try {
        this.disabled = true;
        const response = await this.$axios.get('admin/reports/customers',{
          params: {
            page: this.page,
            per_page: this.per_page,
            ...this.getFilters(),
            ...this.getSortFilters()
          }
        })
        this.reports = response.data.data.reports;

        
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.disabled = false;
      }
    }
  },
  computed:{
    extraQuery(){
      return {
            page: this.page,
            per_page: this.per_page,
            ...this.getFilters(),
            ...this.getSortFilters()
          }
    }
  },
}
</script>
