<template>
  <div id="page-maker-container">
    <textarea v-show="false" class="page-maker-content" id="page-maker-content">{{ content }}</textarea>
    <div class="page-maker" id="page-maker"></div>
  </div>
</template>

<script>
import PageMakerImpl from '@@/core/components/page/PageMaker/PageMakerImpl'
export default {
  extends: PageMakerImpl
}
</script>

<style src="@@/core/components/page/PageMaker/PageMakerStylePublic.css"></style>
