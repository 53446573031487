import {BForm, BFormCheckbox, BFormGroup, BFormInput, BModal} from "bootstrap-vue";

export default {
  name: 'AttributeForm',
  components: {
    BModal, BForm, BFormGroup, BFormInput, BFormCheckbox
  },
  props: {
    mode: {
      default: "create",
      type: String,
    },
    value: Object,
    url: String,
  },
  data() {
    return {
      disabled: false,
      typeOptions: [
        {
          value: "select",
          name: "انتخابی",
        },
        {
          value: "text",
          name: "متنی",
        },
      ],
      styleOptions: [
        {
          value: "select",
          name: "کومبو",
        },
        {
          value: "box",
          name: "مربعی",
        },
        {
          value: "image",
          name: "مربعی با عکس",
        },
      ],
      btnDisabled: false,
      values: this.attribute ? this.attribute.values : [],
      attribute: this.value,
    };
  },
  watch: {
    attribute(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.attribute = newVal;
    },
    "attribute.type": function (newVal) {
      if (newVal.value === "select" && this.attribute.values == "") {
        this.addValue(0);
      }
    },
  },
  methods: {
    setDisabled(val) {
      this.disabled = val;
    },
    addValue(index) {
      this.attribute.values.splice(index + 1, 0, {
        value: "",
      });
    },
    async submit(e) {
      e.preventDefault();
      this.disabled = true;
      try {
        let formData = new FormData(this.$refs.form);
        formData.append(
            "type",
            this.attribute.type.value == "select" ? "select" : "text"
        );
        formData.append("_method", this.mode == "edit" ? "PUT" : "POST");
        formData.append("style", this.attribute.style.value);
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response.data.message, "success");
        if (this.mode == "edit") {
          this.$root.$set(
              this.$parent._data.items.data,
              this.attribute.index,
              response.data.data.attribute
          );
        } else {
          this.$parent._data.items.data.unshift(response.data.data.attribute);
        }
        this.$root.$emit("bv::hide::modal", "attribute-modal");
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
  },
}
