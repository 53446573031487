<template>
  <div class="card h-100">
    <div class="card-header border-0 responsive-header">
      <h4 class="card-title">آمار فروش</h4>
      <div class="card-options">
        <div class="btn-list">
          <a href="#" class="btn  btn-outline-light text-dark float-left d-flex my-auto"><span
              class="dot-label bg-light4 ml-2 my-auto" style="background-color: #8492a6!important"></span>هزینه حمل و نقل</a>
          <a href="#" class="btn  btn-outline-light text-dark float-left d-flex my-auto"><span
              class="dot-label bg-primary ml-2 my-auto"></span>فروش کل</a>
          <Dropdown :disabled="loading" v-if="type == 'month'" v-model="selectedMonth" class="d-inline ml-2"
                    :options="monthOptions" />
          <Dropdown :disabled="loading" v-if="type == 'month' || type == 'year'" v-model="selectedYear" class="d-inline ml-2"
                    :options="yearOptions" />
          <Dropdown :disabled="loading" v-model="type" class="d-inline ml-2"
                    :options="[{name: 'year', label:'سال'}, {name: 'month', label: 'ماه'}, {name: 'week', label: 'هفته'}]" />
        </div>
      </div>
    </div>
    <div class="card-body position-relative">
      <Loading v-if="loading" :position-absolute="true" />
      <canvas :id="id"></canvas>
    </div>
  </div>
</template>

<script>
import Chart1Impl from '@@/core/components/dashboard/Chart1/Chart1Impl'
export default {
  extends: Chart1Impl
}
</script>

<style scoped>

</style>
