import {BFormGroup, BFormInput, BTable, BFormFile, BFormTextarea} from "bootstrap-vue";
import Ckeditor from "@@/core/components/shared/Ckeditor/Ckeditor.vue";

export default {
    name: 'SettingForm',
    props: ['settings', 'url'],
    components: {
        BFormGroup, BFormInput, BTable, BFormFile, BFormTextarea, Ckeditor
    },
    data() {
        return {
            disabled: false,
            items: this.settings
        }
    },
    methods: {
        async submit(e) {
            e.preventDefault()
            this.disabled = true
            try {
                let formData = new FormData(e.target)
                formData.append('_method', 'PUT')
                this.settings.forEach(item => {
                    if(item.type == 'price') {
                        console.log(formData.get(item.name));
                        formData.set(item.name , formData.get(item.name).replace(/\D/g, ''))
                    }
                });
                const response = await this.$axios.post('admin/settings', formData);
                this.$root.notify(response, 'success')
            } catch (error) {
                this.$root.notify(error)
            }
            this.disabled = false
        },
    }
}
