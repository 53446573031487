export default class variety {
  constructor(options = {}) {
    this.data = {
      name: '',
      price: options.price || '',
      purchasePrice: '',
      quantity: '',
      images: [],
      barcode: '',
      SKU: '',
      discount: '',
      discountType: 'none',
      discountUntil: '',
      productGifts: {
        gifts: [],
        shouldMerge: false,
      }
    }
    this.present = false // الان تو لیست داریمش یا نه
  }
}
