import {loadScript} from '@@/core/libraries/VueMethods'
import '@@/core/assets/page-maker/grideditor.css'

export default {
  name: "PageMaker",
  props: {
    value: String,
  },
  data() {
    return {
      content: this.value
    }
  },
  mounted() {
    setTimeout(() => {
      this.init()
    }, 300)
  },
  methods: {
    init() {
      this.$root.loadCkeditor()

      setTimeout(() => {
        let interval;
        [
          '/assets/page-maker/jquery.grideditor.min.js',
        ].forEach(src => loadScript(src))
        interval = setInterval(() => {
          if ($("#page-maker").gridEditor) {
            $("#page-maker").gridEditor({
              new_row_layouts: [[12], [6,6], [9,3]],
              ckeditor: {
                config: { language: 'fa' }
              },
              content_types: ['ckeditor'],
              source_textarea: 'textarea.page-maker-content',
            });
            clearInterval(interval);
          }
        }, 0);
      },400)

    },
    readData() {
      return $('#page-maker').gridEditor('getHtml');
    }
  },
}
