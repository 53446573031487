<template>
  <div class="row">
    <b-form-group class="px-5" label="نوع سایز چارت">
      <treeselect v-if="sizeChartTypes" :options="realSizeChartTypes" v-model="realValue.type_id" :searchable="true" :show-count="true"
                  noChildrenText="فرزندی وجود ندارد" noOptionsText="موردی وجود ندارد" noResultsText="موردی یافت نشد"
                  :default-expand-level="1" label="label">
        <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
               :class="labelClassName">
          {{ node.label }}
          <span v-show="false" v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
        </label>
      </treeselect>
    </b-form-group>
    <div class="col-md-12 mt-5 pb-5">
      <table v-if="realValue && realValue.type_id" class="table card-table table-responsive table-vcenter text-nowrap mb-0">
        <thead>
        <tr v-if="$options.configProvider.get('sizeChart.changeColumn') && realValue.chart && realValue.chart[0]">
          <th v-for="(cell , index) in realValue.chart[0]" :key="index" class="border-bottom-0">
            <div class="plus-negative-container">
              <button type="button" tabindex="-1" class="btn btn-success" @click="addColumn(index)">+</button>
              <button type="button" tabindex="-1" :disabled="realValue.chart[0].length == 1 ? true : false" class="btn btn-danger neg"
                      @click="realValue.chart = $root.deleteArrayColumn(index , realValue.chart)">‒
              </button>
            </div>
          </th>
        </tr>
        <tr v-if="realValue.chart && realValue.chart[0]">
          <th v-for="(cell , index) in realValue.chart[0]" :key="index">
            <b-form-input :disabled="disabled || ($options.configProvider.get('sizeChart.hasType') && index !== 0)" v-model="realValue.chart[0][index]"></b-form-input>
          </th>
          <th>
            <div class="plus-negative-container">
              <button type="button" tabindex="-1" class="btn btn-success" @click="addRow(0)">+</button>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row , rowIndex) in realValue.chart.slice(1)" :key="rowIndex" class="position-relative">
          <th v-for="(cell , cellIndex) in row" :key="cellIndex" scope="row">
            <b-form-input :disabled="disabled" v-model="realValue.chart[rowIndex + 1][cellIndex]"></b-form-input>
          </th>
          <th>
            <div class="plus-negative-container">
              <button type="button" tabindex="-1" class="btn btn-success" @click="addRow(rowIndex + 1)">+</button>
              <button type="button" tabindex="-1" :disabled="realValue.chart.length == 2 ? true : false" class="btn btn-danger neg"
                      @click="realValue.chart = $root.deleteArrayRow(rowIndex + 1 , realValue.chart)">‒
              </button>
            </div>
          </th>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import SizeChartFormImpl from '@@/core/components/size/SizeChartForm/SizeChartFormImpl'
export default {
  extends: SizeChartFormImpl
}
</script>
<style scoped src="@@/core/components/size/SizeChartForm/SizeChartFormStyle.css"></style>
