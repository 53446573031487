<template>
  <button @click="refresh" class="btn btn-outline-info"><span class="fa fa-refresh"></span></button>
</template>

<script>
export default {
  name: "Refresh",
  methods: {
    refresh() {
      this.$parent.items = {}
      this.$parent.loadItems()
    }
  }
}
</script>

<style scoped>

</style>
