import SettingForm from '@@/core/components/settings/SettingForm/SettingForm'
export default {
    components: {
        SettingForm
    },
    data() {
        return {
            url: 'admin/settings',
            settings: []
        }
    },
    created() {
        this.url = this.url + '/' + this.$route.params.group
        this.loadSettings()
    },
    methods: {
        async loadSettings() {
            try {
                const res = await this.$axios.get(this.url)
                this.settings = res.data.data.settings

            } catch (error) {
                this.$root.notify(error)
            }
        }
    }
}
