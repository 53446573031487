<template>
  <div class="card">
    <div class="card-header border-0">
      <h4 class="card-title">وضعیت سفارشات</h4>
      <div class="card-options">
        <Dropdown :disabled="loading" v-if="type == 'month'" v-model="selectedMonth" class="d-inline ml-2"
                  :options="monthOptions" />
        <Dropdown :disabled="loading" v-if="type == 'month' || type == 'year'" v-model="selectedYear" class="d-inline ml-2"
                  :options="yearOptions" />
        <Dropdown :disabled="loading" v-model="type"
                  :options="[{name: 'year', label:'سال'}, {name: 'month', label: 'ماه'}, {name: 'week', label: 'هفته'}]"/>

      </div>
    </div>
    <div class="card-body">
      <div class="mt-5">
        <canvas id="sales-summary" class=""></canvas>
      </div>
      <div class="sales-chart mt-4 row text-center">
        <div v-for="status in statuses" :key="status" class="d-flex my-auto col-sm-4 mb-2 mx-auto text-center justify-content-center"
        style="font-size: 13px"><span
            :style="{backgroundColor: getStatusColor(status)}"
            class="dot-label ml-1 my-auto"></span><span>{{ getStatusLabel(status) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart2Impl from '@@/core/components/dashboard/Chart2/Chart2Impl'
import Dropdown from "@@/core/components/shared/Dropdown/Dropdown";
export default {
  components: {Dropdown},
  extends: Chart2Impl
}
</script>

<style scoped src="@@/core/components/dashboard/Chart2/Chart2Style.css"></style>
