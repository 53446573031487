<template>
  <b-modal id="coupon-modal" :title="mode === 'edit' ? 'ویرایش کد تخفیف' : 'ثبت کد تخفیف' " :ok-disabled="disabled" :cancel-disabled="disabled" @ok="submit" class="in-form-modal" ok-title="ثبت"
           cancel-title="لغو" size="lg">
    <form @submit.prevent="submit" >
      <div class="row">
        <b-form-group class="col-md-6" >
          <label for="title"> عنوان <i class="text-danger">*</i></label>
          <b-form-input id="title" ref="title" :value="coupon.title"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" >
          <label for="code"> کد <i class="text-danger">*</i></label>
          <b-form-input id="code" ref="code" :value="coupon.code"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" label="" label-for="start-date">
          <label for=""> تاریخ شروع  <i class="text-danger">*</i></label>
          <date-picker type="datetime" v-model="coupon.start_date"  format="YYYY-MM-DD HH:mm" display-format="jYYYY-jMM-jDD HH:mm" />
        </b-form-group>
        <b-form-group class="col-md-6" >
          <label for="end-date"> تاریخ پایان <i class="text-danger">*</i></label>
          <date-picker type="datetime" v-model="coupon.end_date" format="YYYY-MM-DD HH:mm" display-format="jYYYY-jMM-jDD HH:mm" />
        </b-form-group>
        <b-form-group class="col-md-6" label="" label-for="">
          <label for="type"> نوع  <i class="text-danger">*</i></label>
          <b-form-select id="type" v-model="coupon.type" @change="coupon.amount = 0">
            <b-form-select-option value="flat">مبلغ</b-form-select-option>
            <b-form-select-option value="percentage">درصد</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group class="col-md-6" v-if="coupon.type == 'flat'" label="مبلغ (تومان)" label-for="flat">
          <b-form-input :value="coupon.amount | priceinput" @input="value=>{coupon.amount = value !== '' ? +value.replace(/\D/g, '') : ''}">
          </b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" v-else-if="coupon.type == 'percentage'" label="درصد" label-for="percentage">
          <b-form-input id="percentage" ref="amount" :value="coupon.amount"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" >
          <label for="usage-limit"> سقف استفاده <i class="text-danger">*</i></label>
          <b-form-input ref="usage_limit" id="usage-limit" :value="coupon.usage_limit"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" >
          <label for="user-limit"> سقف استفاده برای هر کاربر <i class="text-danger">*</i></label>
          <b-form-input ref="user_limit" id="user-limit" :value="coupon.usage_per_user_limit"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" >
          <label for="min-order-amount"> حداقل مبلغ سبد خرید</label>
          <b-form-input ref="min_order_amount" id="min-order-amount"
                        :value="coupon.min_order_amount | priceinput"
                        @input="value=>{coupon.min_order_amount = value !== '' ? +value.replace(/\D/g, '') : ''}"></b-form-input>
        </b-form-group>
       
        <b-form-group class="col-md-6" >
          <b-form-checkbox ref="allow_with_discount" id="allow-with-discount" v-model="coupon.allow_with_discount" name="allow_with_discount" value="1"
                           unchecked-value="0">
            <span class="pr-5">اعمال کد روی تخفیف سفارش</span>
          </b-form-checkbox>
        </b-form-group>
             
      </div>
    </form>
  </b-modal>
</template>
<script>
import CouponFormImpl from '@@/core/components/coupon/CouponForm/CouponFormImpl'
export default {
  extends: CouponFormImpl
}
</script>
