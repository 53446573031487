<template>
  <main>
    <b-card class="mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">گزارش پیامک (سبد خرید)</h5>
        <div style="flex:1"></div>
        <router-link to="/admin/reports/sms-reports" class="btn btn-secondary"
          >لیست سفارشات</router-link
        >
      </div>
      <div class="card-body mt-5">
        <div v-if="reportDashboard" class="row">
          <div class="col-md-6 col-xl-3">
            <b-card class="h-0">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-muted mb-0">
                    تعداد فروش
                  </h5>
                  <span class="d-table h2 font-weight-bold p-5">{{
                    reportDashboard.buy_count | price
                  }}</span>
                </div>
                <div class="col-auto">
                  <div class="rounded-circle shadow bg-gradient-danger ">
                    <i
                      class="fe fe-pie-chart text-white p-4"
                      style="font-size: x-large;"
                    ></i>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
          <div class="col-md-6 col-xl-3">
            <b-card>
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-muted mb-0">
                    تعداد مشتریان
                  </h5>
                  <span class="d-table h2 font-weight-bold p-5">{{
                    reportDashboard.total_customers | price
                  }}</span>
                </div>
                <div class="col-auto">
                  <div class="rounded-circle shadow bg-gradient-warning ">
                    <i
                      class="fe fe-users text-white p-4"
                      style="font-size: x-large;"
                    ></i>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
          <div class="col-md-6 col-xl-3">
            <b-card>
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-muted mb-0">
                    تعداد پیام ها
                  </h5>
                  <span class="d-table h2 font-weight-bold p-5">{{
                    reportDashboard.total_sms | price
                  }}</span>
                </div>
                <div class="col-auto">
                  <div class="rounded-circle shadow bg-gradient-success ">
                    <i
                      class="fe fe-message-circle text-white p-4"
                      style="font-size: x-large;"
                    ></i>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
          <div class="col-md-6 col-xl-3">
            <b-card>
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-muted mb-0">
                    تعداد پیام های مشاهده شده
                  </h5>
                  <span class="d-table h2 font-weight-bold p-5">{{
                    reportDashboard.visited | price
                  }}</span>
                </div>
                <div class="col-auto">
                  <div class="rounded-circle shadow bg-gradient-info">
                    <i
                      class="fe fe-eye text-white p-4"
                      style="font-size: x-large;"
                    ></i>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
          <div class="col-md-6 col-xl-3">
            <b-card>
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-muted mb-0">
                    تعداد پیام های مشاهده نشده
                  </h5>
                  <span class="d-table h2 font-weight-bold p-5">{{
                    reportDashboard.not_visited | price
                  }}</span>
                </div>
                <div class="col-auto">
                  <div class="rounded-circle shadow bg-gradient-warning">
                    <i
                      class="fe fe-eye-off text-white p-4"
                      style="font-size: x-large;"
                    ></i>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <div v-else class="alert alert-primary">در حال بارگزاری</div>
      </div>
    </b-card>
  </main>
</template>
<script>
import { BButton, BCard } from "bootstrap-vue";

export default {
  components: {
    BButton,
    BCard,
  },
  data() {
    return {
      disabled: false,
      reportDashboard: null,
    };
  },
  created() {
    this.loadData();
  },

  methods: {
    async loadData() {
      try {
        this.disabled = true;
        const response = await this.$axios.get("admin/reports/cart");
        this.reportDashboard = response.data.data;
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
<style scoped>
main .card {
  min-height: unset;
}
</style>