import {BFormGroup, BFormInput, BModal} from "bootstrap-vue";

export default {
  components: {
    BFormGroup, BFormInput, BModal
  },
  props: {
    value: Object,
  },
  data() {
    return {
      disabled: false,
      orderItem: this.value,
    };
  },
  watch: {
    orderItem(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.orderItem = newVal;
    },
  },
  methods: {
    async updateOrderItem() {
      if (+this.orderItem.quantity > +this.orderItem.variety.quantity + +this.orderItem.old_quantity) {
        window.swal({
          title: "",
          showConfirmButton: true,
          text: "تعداد درخواستی بیشتر از موجودی محصول می باشد",
          button: "باشه",
        });
      } else {
        this.disabled = true;

        try {
          const response = await this.$axios.put(
              "admin/orders/items/" + this.orderItem.id,
              {
                variety_id: this.orderItem.variety_id,
                quantity: this.orderItem.quantity,
              }
          );

          this.$root.notify(response.data.message, "success");
          this.$root.$emit("bv::hide::modal", "update-order-item");
          this.$emit("update-item", this.orderItem);
          this.$emit("update-log-item", this.orderItem);
        } catch (error) {
          this.$root.notify(error);
        }
        this.disabled = false;
      }
    },
    async updateStatus(status) {
      this.disabled = true;
      try {
        const response = await this.$axios.put(
            `admin/orders/items/${this.orderItem.id}/status`,
            {
              status: status,
            }
        );

        this.$root.notify(response.data.message, "success");
        this.$root.$emit("bv::hide::modal", "update-order-item");
        this.orderItem.status = status
        this.$emit("update-item", this.orderItem);
        let orderItemLog = response.data.data.orderItemLog
        this.$emit("update-log-status", orderItemLog);
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
  },
};
