<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">نظرات محصول</h5>
        <div style="flex:1"></div>
        <b-button
            v-b-toggle.filter
            :variant="hasFilter() ? 'secondary' : 'primary'"
        >
          <span class="fe fe-search pt-1" style="font-size: 17px;"></span>
        </b-button>
      </div>
      <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="row mt-5">
              <b-form-group v-if="widgetData.products" class="col-md-6">
                <multiselect
                    placeholder="محصول"
                    v-model="filter.product_id"
                    open-direction="bottom"
                    :options="widgetData.products"
                    label="title"
                    track-by="id"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :disabled="disabled"
                >
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-6">
                <multiselect
                    placeholder="وضعیت"
                    v-model="filter.status"
                    open-direction="bottom"
                    :options="statusOptions"
                    label="title"
                    track-by="value"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :disabled="disabled"
                >
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-6">
                <date-picker
                    placeholder="از تاریخ"
                    v-model="filter.start_date"
                    format="YYYY-MM-DD"
                    display-format="jYYYY-jMM-jDD"
                />
              </b-form-group>
              <b-form-group class="col-md-6">
                <date-picker
                    placeholder="تا تاریخ"
                    v-model="filter.end_date"
                    format="YYYY-MM-DD"
                    display-format="jYYYY-jMM-jDD"
                />
              </b-form-group>
            </div>
            <div class="text-center mt-3">
              <button
                  :disabled="disabled"
                  type="submit"
                  class="btn btn-primary px-5"
              >
                جستجو
              </button>
              <button
                  :disabled="disabled"
                  @click="resetFilter"
                  class="btn btn-warning px-5 mr-3"
              >
                برداشتن فیلتر
              </button>
              <button
                  :disabled="disabled"
                  @click="$root.$emit('bv::toggle::collapse', 'filter')"
                  class="btn btn-secondary px-5 mr-3"
              >
                بستن
              </button>
            </div>
          </form>
        </b-card>
      </b-collapse>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(status)="data">
              <span
                  v-if="data.item.status == 'approved'"
                  class="badge badge-success status"
              >تایید شده</span
              >
              <span
                  v-else-if="data.item.status == 'reject'"
                  class="badge badge-danger status"
              >رد شده</span
              >
              <span
                  v-else-if="data.item.status == 'pending'"
                  class="badge badge-warning status"
              >در انتظار بررسی</span
              >
            </template>
            <template v-slot:cell(product)="data">
              <router-link :to="'/admin/products/edit/' + data.item.product.id"
                           v-if="data.item.product"
                           v-b-tooltip.hover="{
                  placement: 'bottom',
                  customClass:
                    data.item.product.title.length <= 25 ? 'd-none' : '',
                }"
                           :title="data.item.product.title"
              >
                {{ data.item.product.title.substr(0, 25) }}
                {{ data.item.product.title.length > 25 ? "..." : "" }}
              </router-link>
            </template>
            <template v-slot:cell(body)="data">
              <span v-if="data.item.body">
                {{ data.item.body.substr(0, 50) }}
                {{ data.item.body.length > 50 ? "..." : "" }}
              </span>
            </template>
            <template v-slot:cell(customer)="data">
              <span v-if="data.item.creator">{{ data.item.creator.first_name && data.item.creator.last_name
                  ? data.item.creator.first_name +
                  " " +
                  data.item.creator.last_name
                  : data.item.creator.mobile
                }}</span>
            </template>
            <template v-slot:cell(rate)="data">
              <b-form-rating
                  v-model="data.item.rate"
                  variant="warning"
                  :readonly="true"
                  class="border-0 comment-rate pb-0 pt-0"
              >
              </b-form-rating>
            </template>
            <template v-slot:cell(show)="data">
              <a
                  class="action-btns1  pt-1 px-2"
                  @click="showComment(data.index)"
              >
                <i class="fe fe-eye text-primary"></i>
              </a>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                    :disabled="!hasPermission('delete_productComment') ? true : false"
                    class="action-btns1  pt-1 px-2 "
                    @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip
                  v-if="!hasPermission('delete_productComment')"
                  :target="'delete-btn-' + data.item.id"
                  triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
              v-else-if="items.data != ''"
              :disabled="disabled"
              :limit="2"
              :data="items"
              @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
    <ProductComment :comment="comment" :url="url" />
  </main>
</template>
<script>
import ProductCommentImpl from '@@/core/pages/product/ProductComment/ProductCommentImpl'
export default {
  extends: ProductCommentImpl
}
</script>

<style src="@@/core/pages/product/ProductComment/ProductCommentStylePublic.css"></style>
