<template>
    <main class="mt-5 admin-faq">
        <span id="add-btn">
            <button :disabled="hasPermission('write_faq') ? false : true" @click="createItem" v-b-modal.faq-modal
                class="btn btn-success">سوال متداول جدید</button>
        </span>
        <b-tooltip v-if="!hasPermission('write_faq')" target="add-btn" triggers="hover">
            شما به این بخش دسترسی ندارید
        </b-tooltip>
        <FormModal :edit-model="edit" :mode="mode" @create="onCreate" @edit="onEdit" :url="url"
            :modal-name="$options.name" :create-rules="createRules" :edit-rules="editRules"></FormModal>
        <div class="card mt-3">
            <div class="card-header  border-0">
                <h5 class="card-title font-bold">سوالات متداول</h5>
            </div>
            <div class="card-body ">
                <table :style="disabled ? 'opacity: 0.8' : ''"
                    class="table table-responsive-sm table-bordered table-hover">
                    <thead>
                        <tr>
                            <th scope="col"><i class="fas fa-arrows-alt-v"></i> شناسه</th>
                            <th scope="col">سوال</th>
                            <th scope="col">پاسخ</th>
                            <th scope="col">وضعیت</th>
                            <th scope="col">تاریخ ثبت</th>
                            <th scope="col">ویرایش</th>
                            <th scope="col">حذف</th>
                        </tr>
                    </thead>
                    <draggable v-model="faqs" tag="tbody" @change="sortfaq" v-bind="dragOptions">
                        <tr class='flip-list' v-for="(item, index) in faqs" :key="item.id">
                            <td scope="row">{{ item.id }}</td>
                            <td scope="row">{{ item.question }}</td>
                            <td scope="row">{{ item.answer }}</td>
                            <td scope="row">
                                <span v-if="item.status == 1 " class="badge badge-success status">فعال</span>
                                <span v-else-if="item.status == 0" class="badge badge-danger status">غیرفعال</span>
                            </td>
                            <td scope="row">{{ item.created_at | persianDate }}</td>
                            <td>
                                <span :id="'edit-btn-' + item.id">
                                    <a :disabled="disabled || !hasPermission('modify_faq') ? true : false "
                                        class="action-btns1  pt-1 px-2" @click="editItem(index)">
                                        <i class="feather feather-edit  text-primary"></i>
                                    </a>
                                </span>
                                <b-tooltip v-if="!hasPermission('modify_faq')" :target="'edit-btn-' + item.id"
                                    triggers="hover">
                                    شما به این بخش دسترسی ندارید
                                </b-tooltip>

                            </td>
                            <td>
                                <span :id="'delete-btn-' + item.id">
                                    <a :disabled="!hasPermission('delete_faq') ? true : false "
                                        class="action-btns1  pt-1 px-2 " @click="deleteItem(item.id)">
                                        <i class="feather feather-trash-2 text-danger"></i>
                                    </a>
                                </span>
                                <b-tooltip v-if="!hasPermission('delete_faq')" :target="'delete-btn-' + item.id"
                                    triggers="hover">
                                    شما به این بخش دسترسی ندارید
                                </b-tooltip>
                            </td>
                        </tr>
                    </draggable>
                </table>
                <Loading v-if="faqs == null" />
                <div class="alert alert-danger text-right" v-if="faqs == ''">
                    <span class="text-right">موردی یافت نشد ! </span>
                </div>
            </div>
        </div>
        <!-- <faqModal :mode="mode" :linkables="linkables" :group="group" v-model="faq"
            :labelMultiselect="modelLable" /> -->
    </main>
</template>
<script>
import FaqImpl from '@@/core/pages/Faq/FaqImpl'
export default {
    extends: FaqImpl
}
</script>

<style scoped src="@@/core/pages/Faq/FaqStyle.css"></style>