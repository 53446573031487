<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link
        to="/admin/sets/create"
        :disabled="hasPermission('write_product_set') ? false : true"
        class="btn btn-success"
      >
        ست جدید
      </router-link>
    </span>
    <b-tooltip
      v-if="!hasPermission('write_product_set')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header justify-content-between border-0">
        <h5 class="card-title font-bold">ست ها</h5>
        <b-button v-b-toggle.filter-sets variant="primary">
          <i class="fe fe-search"></i>
        </b-button>
      </div>

      <div class="card-body">
        <b-collapse id="filter-sets">
          <b-card>
            <b-form class="row" @submit.prevent="filterItem">
              <b-form-group class="col-12 col-md-4" label="شناسه محصول">
                <ProductSearch
                  full-width
                  @selectProduct="selectProduct"
                  :show-label="false"
                  :product-only="true"
                />
              </b-form-group>
              <b-form-group class="col-12 col-md-3" label="از تاریخ">
                <date-picker
                  type="datetime"
                  v-model="filter.start_date"
                  format="YYYY-MM-DD HH:mm"
                  display-format="jYYYY-jMM-jDD HH:mm"
                />
              </b-form-group>
              <b-form-group class="col-12 col-md-3" label="تا تاریخ">
                <date-picker
                  type="datetime"
                  v-model="filter.end_date"
                  format="YYYY-MM-DD HH:mm"
                  display-format="jYYYY-jMM-jDD HH:mm"
                />
              </b-form-group>
              <div class="col-md-12">
                <b-button variant="success" type="submit">جستجو</b-button>
              </div>
            </b-form>
          </b-card>
        </b-collapse>
        <div>
          <b-table
            responsive
            hover
            bordered
            :items="items.data"
            :fields="fields"
          >
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <router-link
                  :to="{ name: 'set.edit', params: { id: data.item.id } }"
                  :disabled="
                    disabled || !hasPermission('modify_product_set')
                      ? true
                      : false
                  "
                  class="action-btns1 px-2 mr-2"
                >
                  <i class="feather feather-edit  text-primary"></i>
                </router-link>
              </span>
              <b-tooltip
                v-if="!hasPermission('modify_product_set')"
                :target="'edit-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  :disabled="
                    !hasPermission('delete_product_set') ? true : false
                  "
                  class="action-btns1  pt-1 px-2 "
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('delete_product_set')"
                :target="'delete-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SetImpl from "@@/core/pages/sets/Set/SetImpl";
export default {
  extends: SetImpl,
};
</script>
