import Loading from "@@/core/components/shared/Loading/Loading";
import StoreModal from "@@/core/components/store/StoreForm/StoreForm";
import mixins from "@@/core/mixins/mixins";
import {
  BButton,
  BCard,
  BCollapse,
  BFormGroup,
  BFormInput,
  BTable,
  BTooltip,
  VBToggle,
} from "bootstrap-vue";

export default {
  name: "Store",
  components: {
    Loading,
    StoreModal,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/store_transactions",
      mode: "increment",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "product",
          label: "محصول",
        },
        {
          key: "description",
          label: "توضیح",
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "type",
          label: "نوع تغییرات",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
      ],
      store: {
        selectedProduct: null,
        selectedVariety: null,
      },
      typeOptions: [
        {
          value: "increment",
          title: "افزایش",
        },
        {
          value: "decrement",
          title: "کاهش",
        },
        {
          value: "",
          title: "همه",
        },
      ],
      filter: {
        product_id: "",
        variety_id: "",
        type: "",
        start_date: "",
        end_date: "",
      },
      clearDataProductSearch: false,
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {
    "filter.type": function(newVal) {
      if (typeof newVal === "string" || typeof newVal === "number") {
        this.filter.type = this.typeOptions.find((item) => {
          return item.value == newVal;
        });
      }
    },
  },
  methods: {
    resetFilter() {
      this.filter = this.$options.data.apply(this).filter;
      this.filter.product_id = null;
      this.filter.variety_id = null;
      this.clearDataProductSearch = true;
      this.$router.replace({ name: this.$route.name });
      setTimeout(() => {
        this.clearDataProductSearch = false;
      }, 500);
    },
    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.product_id ? this.filter.product_id : "",
        searchBy1: "product_id",
        variety_id: this.filter.variety_id ? this.filter.variety_id : "",
        search2: this.filter.type ? this.filter.type.value : "",
        searchBy2: "type",
        start_date: this.filter.start_date
          ? window.moment(this.filter.start_date, "YYYY.MM.DD").unix()
          : "",
        end_date: this.filter.end_date
          ? window.moment(this.filter.end_date, "YYYY.MM.DD").unix()
          : "",
      };
    },
    selectProduct(product) {
      this.filter.product_id = product.id;
      this.filter.variety_id = null;

    },
    selectVariety(variety) {
      this.filter.variety_id = variety.id;
    },
    incrementModal() {
      this.store = {
        selectedProduct: null,
        selectedVariety: null,
        quantity: 0,
        varietyQuantity: 0,
        description: "",
      };
      this.mode = "increment";
      this.$root.$emit("bv::show::modal", "store-modal");
    },
    decrementModal() {
      this.store = {
        selectedProduct: null,
        selectedVariety: null,
        quantity: 0,
        varietyQuantity: 0,
        description: "",
      };
      this.mode = "decrement";
      this.$root.$emit("bv::show::modal", "store-modal");
    },
  },
};
