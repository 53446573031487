<template>
  <div>
    <treeselect :options="realOptions" :searchable="true" :multiple="true"
                v-model="realValue.gifts"
                noResultsText="موردی یافت نشد"
                noOptionsText="موردی وجود ندارد"
                placeholder="انتخاب هدایا">
      <div slot="value-label" slot-scope="{ node }">{{ node.label }}</div>
    </treeselect>
    <InputGroup v-if="!noMerge" class="mt-4" label-padding="0px" col1="3" col2="9" label="ادغام با تنوع">
      <label>
        <b-form-checkbox v-model="realValue.shouldMerge" />
      </label>
    </InputGroup>
  </div>
</template>

<script>
import {Treeselect} from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import InputGroup from "@@/core/components/product/shared/InputGroup/InputGroup";
import {BFormCheckbox} from 'bootstrap-vue'

export default {
  name: "Gift",
  components: {
    BFormCheckbox,
    InputGroup,
    Treeselect
  },
  props: {
    value: Object,
    gifts: Array,
    noMerge: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    realOptions() {
      return this.$root.addLabel(this.gifts)
    }
  },
  data() {
    return {
      realValue: this.value
    }
  },
  watch: {
    value() {
      this.realValue = this.value;
    }
  }
}
</script>

<style scoped>

</style>
