import Loading from '@@/core/components/shared/Loading/Loading';
import AnswerModal from '@@/core/components/post/PostCommentAnswer/PostCommentAnswer';
import EditModal from '@@/core/components/post/PostCommentEdit/PostCommentEdit';
import Back from '@@/core/components/shared/Back/Back';
import mixins from "@@/core/mixins/mixins";
import {BTable, BTooltip} from "bootstrap-vue";

export default {
  name: "PostComment",
  components: {
    Loading,
    AnswerModal,
    EditModal,
    Back,
    BTooltip, BTable
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/comments",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "body",
          label: "نظر",
        },
        {
          key: "name",
          label: " کاربر",
        },
        {
          key: "email",
          label: " ایمیل",
        },
        {
          key: "post",
          label: " مطلب",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "answer",
          label: "پاسخ",
        },
        {
          key: "comments",
          label: "مشاهده",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      postDetails: "",
      comment: {},
    };
  },
  mounted() {
    if (this.$route.params.post) {
      this.$axios
          .get(`admin/posts/${this.$route.params.post}`)
          .then((response) => {
            this.postDetails = response.data.data.post;
          });
      this.url = `admin/posts/${this.$route.params.post}/comments`;

      this.fields = [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "body",
          label: "نظر",
        },
        {
          key: "name",
          label: " کاربر",
        },
        {
          key: "email",
          label: " ایمیل",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "answer",
          label: "پاسخ",
        },
        {
          key: "comments",
          label: "مشاهده",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ];
    }
    if (this.$route.params.id) {
      this.url = `/admin/comments/${this.$route.params.id}`;
      this.fields = [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "body",
          label: "نظر",
        },
        {
          key: "name",
          label: " کاربر",
        },
        {
          key: "email",
          label: " ایمیل",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        // {
        //     key: 'answer',
        //     label: 'پاسخ'
        // },
        // {
        //     key: 'comments',
        //     label: 'مشاهده'
        // },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ];
    }
    this.loadItems();
  },
  methods: {
    answerComments(index) {
      this.comment = {
        ...this.items.data[index],
      };
      this.$root.$emit("bv::show::modal", "comment-answer");
    },
    editComment(index) {
      let item = this.$route.params.id
          ? {
            ...this.items.children[index],
          }
          : {
            ...this.items.data[index],
          };
      item.status == "approved"
          ? (item.status = {value: "approved", name: "تایید شده"})
          : "";
      item.status == "unapproved"
          ? (item.status = {value: "unapproved", name: "تایید نشده"})
          : "";
      item.status == "pending"
          ? (item.status = {value: "pending", name: "در انتظار بررسی"})
          : "";
      item.index = index;

      this.comment = item;
      this.$root.$emit("bv::show::modal", "edit-modal");
    },
  },
};
