<template>
  <main class="mt-5 admin-recommendation">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">گروه های پیشنهادی</h5>
      </div>
      <div class="card-body row">
        <template v-if="groups !== null">
          <router-link  :to="{name: 'recommendation' , params: {group: group.name}}" class="col-md-3 recommendation-group text-center" v-for="(group, index) in groups" :key="index">
            <h5 class="d-block my-3 font-bold">{{group.label}}</h5>
          </router-link>
        </template>
        <div v-else  class="d-flex justify-content-center w-100">
          <Loading/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Loading from '@@/core/components/shared/Loading/Loading'
export default {
  name: 'RecommendationGroups',
  components: {Loading},
  data() {
    return {
      url:'admin/recommendations/create',
      groups: null
    }
  },
  created() {
    this.loadGroup()
  },
  methods: {
    async loadGroup () {
      const response = await this.$axios.get(this.url)

      this.groups = response.data.data.allGroups
    },
  }
}

</script>

<style scoped>
.admin-recommendation .recommendation-group {
  border: 1px solid #dadada;
  border-radius: 15px;
  box-shadow: 0 0 10px #dadada;
  padding: 3rem 1.5rem;
  margin-left: 17px;
}
.admin-recommendation .recommendation-group:hover {
  border: 1px solid #3366ff;
  cursor: pointer;
}
.admin-recommendation .recommendation-group > a{
  font-size: 16px;
}
</style>
