<template>
  <div class="mb-2">
    <span>صفحه بندی: </span>
    <select v-model="per_page">
      <option v-for="option in $options.pages" :value="option">{{ option }}</option>
    </select>
    <span v-if="count"> از {{ count }} مورد</span>
  </div>
</template>

<script>
export default {
  name: "SelectPageCount",
  pages: [25, 50, 100, 200, 400, 800],
  watch: {
    per_page() {
      this.$emit('input', this.per_page)
    }
  },
  props: {
    value: Number,
    count: [Number, Boolean]
  },
  data() {
    return {
      per_page: this.value
    }
  }
}
</script>

<style scoped>

</style>
