import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from '@@/core/mixins/mixins'
import FormModal from "@@/core/libraries/form/FormModal";
import {BButton, BCard, BCollapse, BFormGroup, BFormInput, BTable, BTooltip, VBModal, VBToggle} from "bootstrap-vue";

export default {
  name: "Cities",
  components: {
    FormModal,
    Loading,
    BTooltip, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput
  },
  directives: {
    'b-toggle': VBToggle, 'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/cities',
      widgets: [
        'provinces'
      ],
      fields: [{
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'name',
          label: 'نام'
        },
        {
          key: 'status',
          label: 'وضعیت'
        },
        {
          key: 'province',
          label: 'استان'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      filter: {
        name: '',
        status: '',
        province_id: ''
      },
      status_options: [{
        name: 'فعال',
        value: '1'
      },
        {
          name: 'غیرفعال',
          value: '0'
        },
        {
          name: 'همه',
          value: ''
        },
      ],
    }
  },
  mounted() {
    this.loadItems()
  },
  watch: {
    'filter.status': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.status = this.status_options.find(item => {
          return item.value == newVal
        })
      }
    },
    'filter.province_id': function (newVal) {
      this.onLoadHandlers.push(() => {
        if (typeof newVal === 'string' || typeof newVal === 'number') {
          this.filter.province_id = this.widgetData.provinces.find(item => {
            return item.id == newVal
          })
        }
      })
    },
  },
  methods: {
    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.name,
        searchBy1: 'name',
        search2: this.filter.province_id != null ? this.filter.province_id.id : '',
        searchBy2: 'province_id',
        search3: this.filter.status ? this.filter.status.value : '',
        searchBy3: 'status'
      }
    }
  }
}
