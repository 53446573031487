<template>
    <b-form @submit.prevent="submit">
        <div class="row">
            <b-form-group class="col-md-6" >
                <label for="name"> نام <i class="text-danger">*</i></label>
                <b-form-input :disabled="disabled" ref="name" id="name" name="name" v-model="specification.name"></b-form-input>
            </b-form-group>
            <b-form-group class="col-md-6" label="لیبل" label-for="label">
                <b-form-input :disabled="disabled" ref="label" id="label" name="label" v-model="specification.label"></b-form-input>
            </b-form-group>
            <b-form-group class="col-md-6" >
                <label for="type"> نوع <i class="text-danger">*</i></label>
                <multiselect v-model="specification.type" placeholder="انتخاب کنید" :allow-empty="false" open-direction="bottom" :options="items"
                    label="name" track-by="value" :searchable="true" :close-on-select="true" :show-labels="false"
                    :disabled="disabled">
                    <template slot="noOptions">
                        موردی یافت نشد
                      </template>
                      <template slot="noResult">
                        موردی یافت نشد
                      </template>
                </multiselect>
            </b-form-group>
            <b-form-group class="col-md-6" label="گروه" label-for="group">
                <b-form-input :disabled="disabled" ref="group" id="group" name="group" v-model="specification.group"></b-form-input>
            </b-form-group>
            <div class="col-md-12 row mt-3 mb-5">
                <div class="col-md-3">
                    <b-form-checkbox id="status" :disabled="disabled" v-model="specification.status" name="status" value="1"
                        unchecked-value="0">
                        <span class="pr-5">وضعیت</span>
                    </b-form-checkbox>
                </div>
                <div class="col-md-3">
                    <b-form-checkbox id="show_filter" :disabled="disabled" v-model="specification.show_filter" name="show_filter" value="1"
                        unchecked-value="0">
                        <span class="pr-5"> نمایش در فیلتر</span>
                    </b-form-checkbox>
                </div>
                <div class="col-md-3">
                    <b-form-checkbox id="required" :disabled="disabled" v-model="specification.required" name="required" value="1"
                        unchecked-value="0">
                        <span class="pr-5"> الزامی</span>
                    </b-form-checkbox>
                </div>
                <div class="col-md-3">
                    <b-form-checkbox id="public" :disabled="disabled" v-model="specification.public" name="public" value="1"
                        unchecked-value="0">
                        <span class="pr-5"> عمومی</span>
                    </b-form-checkbox>
                </div>
            </div>
            <template v-if=" specification.type != null && (specification.type.value == 'select' || specification.type.value == 'multi_select') && specification.values != ''">
                    <b-form-group class="col-md-6 " v-for="(item, index) in specification.values" :key="index" >
                        <label for="values"> مقادیر <i class="text-danger">*</i></label>
                        <div class="d-flex plus-negative-container mt-2">
                            <button type="button" class="btn btn-success" @click="addValue(index)">+</button>
                            <button type="button" class="btn btn-danger neg" :disabled="specification.values.length == 1 ? true : false"
                                @click="specification.values = $root.deleteArrayRow(index , specification.values)">‒</button>
                            <b-form-input placeholder="مقدار" :disabled="disabled" v-model="item.value" :name="'values['+index+']'">
                            </b-form-input>
                        </div>
                    </b-form-group>
            </template>
            <div class="col-md-12 text-center mt-5">
                <button :disabled="disabled" type="submit" class="btn btn-success">
                    {{ mode == 'edit' ? ' ویرایش مشخصات' : ' ثبت مشخصات' }}</button>
            </div>
        </div>
    </b-form>
</template>
<script>
   import SpecificationFormImpl from '@@/core/components/specification/SpecificationForm/SpecificationFormImpl'
   export default {
       extends: SpecificationFormImpl
   }
</script>

<style scoped src="@@/core/components/specification/SpecificationForm/SpecificationFormStyle.css"></style>