<template>
  <main v-if="visit != null" class="mt-5">
    <div class="card mt-3">
      <div class="card-body">
        <b-table
          :style="{ opacity: disabled ? '0.6' : 1 }"
          bordered
          responsive
          striped
          v-if="visit"
          :fields="fields"
          :items="visit.site_views.data"
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(date)="data">
            {{ new Date(data.item.date).toLocaleDateString("fa") }}
          </template>
          <template v-slot:cell(visit)="data">
            {{ data.item.visit }}
          </template>

          <template v-slot:cell(see_more)="data">
            <router-link
              :to="`/admin/reports/visit/${data.item.date}`"
              class="btn btn-primary"
              style="line-height: 0.8;"
            >
              مشاهده بیشتر
            </router-link>
          </template>
        </b-table>
        <div v-else-if="visit == ''" class="alert alert-danger">
          موردی یافت تشد
        </div>
        <div v-else class="alert alert-primary">در حال بارگزاری</div>
        <pagination
          :router="true"
          v-if="visit && visit.site_views.data != ''"
          :disabled="disabled"
          :limit="2"
          :data="visit.site_views"
          @pagination-change-page="changePage"
        />
      </div>
    </div>
  </main>
</template>

<script>
import { BTable } from "bootstrap-vue";
export default {
  name: "CustomersReport",
  components: {
    BTable,
  },
  data() {
    return {
      page: 1,
      disabled: true,
      visit: null,
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "date",
          label: "تاریخ",
          sortable: false,
        },
        {
          key: "visit",
          label: "تعداد بازدید",
          sortable: false,
        },
        {
          key: "see_more",
          label: "مشاهده جزئیات",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    per_page() {
      this.changePage(1);
    },
  },
  created() {
    this.page = this.$route.query.page ?? 1;
    this.loadData();
  },
  methods: {
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData();
    },
    async loadData() {
      try {
        this.disabled = true;
        const response = await this.$axios.get("admin/siteviews", {
          params: {
            page: this.page,
          },
        });
        let items = [];
        for (const key in response.data.data.site_views.data) {
          items.push({
            date: key,
            visit: response.data.data.site_views.data[key],
          });
        }
        response.data.data.site_views.data = items;
        console.log(response);
        this.visit = response.data.data;
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
