import Loading from '@@/core/components/shared/Loading/Loading'
import advertiseModal from '@@/core/components/advertise/AdvertisementForm/AdvertisementForm'
import Back from '@@/core/components/shared/Back/Back'
import mixins from "@@/core/mixins/mixins";
import {BTooltip, BFormInput, VBModal} from "bootstrap-vue";

export default {
  components: {
     Loading,
     advertiseModal,
    Back,BTooltip, BFormInput
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      position: null,
      linkables: [],
      positions: [],
      advertise: {},
      mode: "create",
      modelLable: "",
      axiosCancelSource: null,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadPosition(this.$route.params.id);
      this.loadPositionsAll();
    }
  },
  methods: {
    async loadPosition(id) {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(`admin/positions/${id}`, {
          cancelToken: this.axiosCancelSource.token,
        });
        this.position = response.data.data.positions;
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error);
      }
    },
    async loadPositionsAll() {
      const response = await this.$axios.get(
        `admin/positions?all=1&widgets=["linkables"]`
      );

      this.positions = response.data.data.positions;
      this.linkables = response.data.data.widgets.linkables;
      this.linkables.push({
        linkable_type: "link_url",
        unique_type: "link_url",
        label: "لینک دلخواه",
        models: "link_url",
      });
    },
    async updatePossibility(e) {
      this.disabled = true;

      let formData = new FormData(e.target);
      try {
        const response = await this.$axios.post(
          `admin/positions/${this.$route.params.id}/update_possibility`,
          formData
        );
        this.$root.notify(response.data.message, "success");
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
    deleteAdvertisement(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.$axios
              .delete(`admin/advertise/${id}`)
              .then((response) => {
                this.position.advertisements = this.position.advertisements.filter(
                  (item) => item.id != id
                );
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        })
        .catch(() => {});
    },
    newAdvertise() {
      this.advertise = {
        position: null,
        image: "",
        link: null,
        linkable_type: "",
        linkable_id: "",
        start: "",
        end: "",
        new_tab: 0,
      };
      this.mode = "create";
      this.$root.$emit("bv::show::modal", "advertise-modal");
    },
    editAdvertise(index) {
      let advertise = { ...this.position.advertisements[index] };
      advertise.index = index;

      if (advertise.link == null) {
        advertise.linkable_type = this.linkables.filter(
          (item) => item.unique_type == advertise.unique_type
        )[0];

        advertise.linkable_id != null && advertise.linkable_type.models
          ? (advertise.linkable_id = advertise.linkable_type.models.filter(
              (item) => item.id == advertise.linkable_id
            )[0])
          : "";

        if (
          advertise.linkable_type.models != null &&
          advertise.linkable_type.models[0].title
        ) {
          this.modelLable = "title";
        } else if (
          advertise.linkable_type.models != null &&
          advertise.linkable_type.models[0].name
        ) {
          this.modelLable = "name";
        }
      } else {
        advertise.linkable_type = {
          linkable_type: "link_url",
          unique_type: "link_url",
          label: "لینک دلخواه",
          models: "link_url",
        };
      }
      this.mode = "edit";

      advertise.new_tab ? (advertise.new_tab = 1) : (advertise.new_tab = 0);

      this.advertise = advertise;
      this.$root.$emit("bv::show::modal", "advertise-modal");
    },
    setAdvertise(advertise) {
      if (this.mode == "create") {
        this.position.advertisements.push(advertise);
      } else {
        this.$set(
          this.position.advertisements,
          this.advertise.index,
          advertise
        );
      }
    },
  },
};
