import {Treeselect} from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "ProductSpecification",
  components: {Treeselect},
  props: {
    selectedCategories: Array,
    publicSpecifications: Array,
    value: Array,
  },
  data() {
    return {
      realValue: this.value,
    }
  },
  created() {

  },
  mounted() {

  },
  computed: {
    realUnits() {
      return this.$root.addLabel(this.units)
    },
    realTags() {
      return this.$root.addLabel(this.tags)
    },
    realOptions() {
      return this.$root.addLabel(this.categories)
    },
    realSpecifications() {
      let fromCats = [];
      if (this.selectedCategories && this.selectedCategories.length) {
        fromCats = this.readSpecifications(this.selectedCategories)
      }

      return this.$root.mergeArrayObjects(fromCats, this.publicSpecifications)
    }
  },
  watch: {
    value(newVal) {
      this.realValue = newVal
    },
    realValue(newVal) {
      this.$emit('input', newVal)
    },
  },
  methods: {
    selected(spec, node, id) {
      console.log(node, id)
    },
    deselected(spec, node, id) {
      console.log(node, id)
    },
    readSpecifications(categories) {
      let specifications = [];
      let addedSpecificationIds = [];
      categories.forEach(cat => {
        cat.specifications.forEach((specification) => {
          if (addedSpecificationIds.includes(specification.id)) {
            return;
          }
          addedSpecificationIds.push(specification.id);
          specifications.push(specification);
        });
        // اگر لازم بود از دسته های فرزند هم خوانده شود:
        // let specificationsFromChildren = this.readSpecifications(cat.children);
        // specificationsFromChildren.forEach((s) => {
        //   if (addedSpecificationIds.includes(s.id)) {
        //     return;
        //   }
        //   addedSpecificationIds.push(s.id);
        //   specifications.push(s);
        // })
      });

      return specifications;
    },
    getRealValues(val) {
      if (!val) {
        return []
      }
      return this.$root.addLabel(val)
    }
  }
}
