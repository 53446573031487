<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">تماس با ما</h5>
      </div>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(status)="data">
              <span
                v-if="data.item.status == 1"
                class="badge badge-success status"
                >خوانده شده</span
              >
              <span
                v-else-if="data.item.status == 0"
                class="badge badge-danger status"
                >خوانده نشده</span
              >
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(show)="data">
              <a
                @click="showItem(data.index)"
                :disabled="disabled ? true : false"
                class="action-btns1  pt-1 px-2"
              >
                <i class="fe fe-eye   text-primary"></i>
              </a>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a
                  :disabled="!hasPermission('delete_contact') ? true : false"
                  class="action-btns1  pt-1 px-2 "
                  @click="deleteItem(data.item.id)"
                >
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip
                v-if="!hasPermission('delete_contact')"
                :target="'delete-btn-' + data.item.id"
                triggers="hover"
              >
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-if="items.data != ''"
            :limit="2"
            :disabled="disabled"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else-if="items.data == ''" class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
        <b-modal id="modal-show-contact" hide-footer title="مشاهده تماس با ما">
          <div class="d-flex py-1">
            <h6 class="font-bold">نام :</h6>
            <h6 class="pr-1">{{ edit.name }}</h6>
          </div>
          <div class="d-flex py-1">
            <h6 class="font-bold">شماره همراه :</h6>
            <h6 class="pr-1">{{ edit.phone_number }}</h6>
          </div>
          <div class="d-flex py-1">
            <h6 class="font-bold">موضوع :</h6>
            <h6 class="pr-1">{{ edit.subject }}</h6>
          </div>
          <div class="d-flex py-1">
            <h6 class="font-bold">متن :</h6>
            <h6 class="pr-1">
              {{ edit.body }}
              </h6>
          </div>
          <div class="d-flex py-1">
            <h6 class="font-bold">وضعیت :</h6>
            <h6 class="pr-1">
              <span v-if="edit.status == 1" class="badge badge-success status"
                >خوانده شده</span
              >
              <span
                v-else-if="edit.status == 0"
                class="badge badge-danger status"
                >خوانده نشده</span
              >
            </h6>
          </div>
        </b-modal>
      </div>
    </div>
  </main>
</template>
<script>
import ContactImpl from '@@/core/pages/Contact/ContactImpl'
export default {
  extends: ContactImpl
}
</script>
