import Loading from '@@/core/components/shared/Loading/Loading'
import {BButton, BCard, BCollapse, BFormGroup, BFormInput, BTable, BTooltip} from "bootstrap-vue";
import mixins from "@@/core/mixins/mixins";
import FormModal from "@@/core/libraries/form/FormModal";

export default {
  name: 'Seller',
  components: {
    Loading,
    BButton, BCard, BCollapse, BFormGroup, BFormInput, BTable, BTooltip, FormModal
  },
  mixins: [mixins],
  mounted() {
    this.loadItems()
  },
  data() {
    return {
      url: 'admin/sellers',
      fields: [{
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'full_name',
          label: 'نام و نام خانوادگی'
        },
        {
          key: 'national_code',
          label: 'کد ملی'
        },
        {
          key: 'active',
          label: 'وضعیت'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      filter: {
        name: '',
        show_index: null,
        status: null
      },
      status_options: [{
        name: 'فعال',
        value: '1'
      },
        {
          name: 'غیرفعال',
          value: '0'
        },
        {
          name: 'همه',
          value: ''
        },
      ],
    }
  }
}
