import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import configProvider from "@@/core/configProvider";
import {BFormGroup, BFormInput} from "bootstrap-vue";

export default {
  components: {Treeselect,BFormGroup, BFormInput},
  configProvider,
  props: {
    value: Object,
    sizeChartTypesProps: {
      type: Array,
      default: () => null
    },
    propDisabled: {
      type:Boolean,
      default: true
    }
  },
  computed: {
    realSizeChartTypes() {
      if (!this.sizeChartTypes) {
        return null;
      }
      return this.$root.addLabel(this.sizeChartTypes)
    }
  },
  data() {
    return {
      disabled: this.propDisabled,
      realValue: this.value,
      sizeChartTypes: this.sizeChartTypesProps,
    }
  },
  watch: {
    value: function (newVal) {
      this.realValue = newVal;
    },
    realValue: function (newVal) {
      this.$emit('input', newVal);
    },
    'realValue.chart': function (newVal) {
      this.$emit('input', this.realValue);
    },
    'realValue.type_id': function (newVal) {
      let selectedSizeChartType = this.sizeChartTypes.find(sC => sC.id == newVal);
      this.makeDefaultSizeChart(selectedSizeChartType);
      this.$emit('input', this.realValue);
    },
  },
  methods: {
    makeDefaultSizeChart(selectedSizeChartType) {
      let arr = [];
      let values = selectedSizeChartType.values.map(val => val.name);
      values.unshift('سایزبندی');
      arr.push(values);
      arr.push([...values].fill(''));
      this.realValue.chart = arr;
    },
    setDisabled(val) {
      this.disabled = val;
    },
    addRow(index) {
      let arrLength = this.realValue.chart[index].length;
      this.realValue.chart.splice(index + 1, 0, Array(arrLength).fill(''));
    },
    addColumn(index) {
      this.realValue.chart.forEach(InArr => {
        InArr.splice(index + 1, 0, '');
      });
    },
  },
  async mounted() {
    if (!this.sizeChartTypes) {
      try {
        const response = await this.$axios.get('admin/size_chart_types');
        this.sizeChartTypes = response.data.data.size_chart_types;
      } catch (error) {
        this.$root.notify(error);
      }
    }
  }
}
