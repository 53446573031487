<template>
  <b-form-group :class="customClass">
    <b-row>
      <b-col :style="{padding: labelPadding ? labelPadding : ''}" :md="col1" cols="12">
        <label @click="labelClicked">{{ label }} <span v-if="required" class="text-danger">*</span></label>
      </b-col>
      <b-col :md="col2" cols="12">
        <slot></slot>
      </b-col>
    </b-row>
  </b-form-group>
</template>

<script>
import InputGroupImpl from '@@/core/components/product/shared/InputGroup/InputGroupImpl'

export default {
  extends: InputGroupImpl
}

</script>

<style scoped>

</style>
