<template>
  <div class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title">هدایا</h5>
    </div>
    <div class="card-body">
      <Gift v-model="realValue" :gifts="gifts" />
    </div>
  </div>
</template>

<script>
import Gift from '@@/core/components/product/shared/Gift/Gift'

export default {
  name: "ProductGifts",
  components: {Gift},
  data() {
    return {
      realValue: this.value
    }
  },
  props: {
    value: Object,
    gifts: Array
  }
}
</script>

<style scoped>

</style>
