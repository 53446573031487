<template>
  <b-modal
      ok-only
      class="in-form-modal"
      ok-title="بستن"
      cancel-title="لغو"
      id="modal-show-SizeChartType"
      :title="'نمایش نوع سایزچارت'"
  >
    <div v-if="sizeChartType"
        class="d-flex plus-negative-container mt-2"
        v-for="(itemValue, index) in sizeChartType.values"
        :key="index">
      <p>{{ itemValue.name }}</p>
    </div>
  </b-modal>
</template>
<script>
import SizeChartTypeShowModalImpl from "@@/core/components/size/SizeChartTypeShowModal/SizeChartTypeShowModalImpl";
export default {
  extends: SizeChartTypeShowModalImpl
}
</script>
