import Loading from '@@/core/components/shared/Loading/Loading'
import '@@/assets/admin/vue-nestable.css'
import crudMixin from "@@/core/mixins/crudMixin";
import mixins from "@@/core/mixins/mixins";
import {BTooltip, BButton} from "bootstrap-vue";
import { VueNestable, VueNestableHandle } from 'vue-nestable'

export default {
    components: {
      Loading,
      BTooltip,BButton,
      VueNestable, VueNestableHandle
    },
    name: "Category",
    mixins: [crudMixin, mixins],
    data() {
      return {
        url: 'admin/categories',
        items: null,
        disabled: false,
        change: false,
        mode: 'create',
      }
    },
    created() {
      this.loadItems();
    },
    methods: {
      loadItems() {
        this.$axios.get(this.url + '?all=1')
          .then(response => {
            this.items = response.data.data.categories
          })
      },
      onChange() {
        this.change = true
      },
      async sortCategory() {

        let sanitizedCategories = this.items.map(i => this.sanitizeCategory(i))
        try {
          this.disabled = true;
          const response = await this.$axios.post('admin/category/sort', {
            categories: sanitizedCategories,
          })
          this.$root.notify(response.data.message, 'success')
          this.change = false
        } catch (error) {
          this.$root.notify(error)
        }
        this.disabled = false
      },
      deleteItem(id) {
        window.swal({
            title: "آیتم حذف شود؟",
            text: "این عملیات غیر قابل بازگشت خواهد بود",
            icon: "warning",

            showCancelButton: true,
            showConfirmButton: true,
            dangerMode: true,
            buttons: ["خیر", "بله حذف کن"]
          })
          .then((willDelete) => {
            if (willDelete) {
              this.$axios.delete(this.url + `/${id}`)
                .then((response) => {
                  this.items = this.items.filter(item => item.id != id)
                  window.swal({
                    icon: "success",
                    showConfirmButton: true,
                    text: response.data.message,
                    button: "باشه",
                  })
                })
                .catch(error => {
                  window.swal({
                    title: 'خطا',
                    showConfirmButton: true,
                    text: error.response.data.message,
                    button: "باشه",
                  })
                })
            }
          })
          .catch(() => {

          })
      },
      sanitizeCategory(c) {
        return {
          id: c.id,
          children: c.children.map((cc) => this.sanitizeCategory(cc))
        }
      }
    }
  }
