import {
  BTooltip,
  BFormRating,
  BModal,
  BForm,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BTooltip,
    BFormRating,
    BModal,
    BForm,
    BFormTextarea,
    BButton,
  },
  props: {
    comment: Object,
    url: {
      default: "admin/product-comment",
      type: String,
    },
  },
  data() {
    return {
      disabled: false,
      answer: "",
    };
  },
  methods: {
    async changeStatus(status) {
      this.disabled = true;
      try {
        const response = await this.$axios.post(this.url, {
          id: this.comment.id,
          status: status,
        });

        if (this.$parent._data.items && this.$parent._data.items.data) {
          this.$parent._data.items.data[this.comment.index].status = status;
        }
        this.$emit("statusChanged", { id: this.comment.id, status });
        this.$root.notify(response.data.message, "success");
        this.$root.$emit("bv::hide::modal", "comment-modal");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
    async submitAnswer() {
      if (this.comment) {
        this.disabled = true;
        try {
          let formData = new FormData();
          formData.append("answer", this.comment.answer);
          const response = await this.$axios.post(
            `admin/product-comment/${this.comment.id}/answer`,
            formData
          );
          this.$root.notify(response.data.message, "success");
          this.$root.$emit("bv::hide::modal", "comment-modal");
        } catch (error) {
          this.$root.notify(error);
        } finally {
          this.disabled = false;
        }
      }
    },
  },
};
