import InputGroup from "@@/core/components/product/shared/InputGroup/InputGroup";
import {BFormCheckbox, BFormInput} from "bootstrap-vue";

export default {
  name: "ProductOtherConfig",
  components: {InputGroup, BFormCheckbox, BFormInput},
  props: {
    value: Object,
  },
  data() {
    return {
      realValue: this.value,
    }
  },
  watch: {
    value(newVal) {
      this.realValue = newVal;
    },
    realValue(newVal) {
      this.$emit('input', newVal);
    },
  }
}
