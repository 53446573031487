import Loading from '@@/core/components/shared/Loading/Loading'
import RoleModal from '@@/core/components/role/RoleForm/RoleForm'
import mixins from '@@/core/mixins/mixins'
import {BTable, BTooltip, VBModal} from "bootstrap-vue";

export default {
  name: "Role",
  components: {
    Loading,
    RoleModal,
    BTable, BTooltip
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/roles',
      fields: [{
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'name',
          label: 'نام'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      submit_url: 'admin/roles',
      mode: 'create',
      role: {
        name: '',
        label: '',
        guard_name: '',
        permissions: null,
      },
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    loadItems() {
      this.$axios.get(this.url)
          .then(response => {
            this.items = {
              data: response.data.data.roles
            }
          })
    },
    async editItem(id, index) {
      this.disabled = true
      try {
        this.role = this.items.data.find((item) => {
          return item.id == id;
        })
        this.mode = 'edit'
        this.submit_url = this.url + `/${id}`
        this.$root.$emit('bv::show::modal', 'role-modal')
        this.role.index = index
      } catch (error) {
        this.$root.notify(error)
      } finally {
        this.disabled = false
      }
    },
    newRole() {
      this.mode = 'create'
      this.submit_url = this.url
      this.role = {
        name: '',
        label: '',
        guard_name: '',
        permissions: null,
      }
    },
  }
}
