<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">{{ $route.params.id ? 'ویرایش مشخصات' : 'مشخصات جدید' }}</h5>
        <div style="flex:1"></div>
        <Back />
      </div>
      <div class="card-body">
        <specificationForm ref="specificationForm" :mode="$route.params.id ? 'edit' : 'create'" :url="url"
          :specification="specification" />
      </div>
    </div>
  </main>
</template>
<script>
import SpecificationCreateEditImpl from '@@/core/pages/specification/SpecificationCreateEdit/SpecificationCreateEditImpl'
export default {
  extends: SpecificationCreateEditImpl
}
</script>