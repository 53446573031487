<template>
  <div
    class="modal fade add-address-modal mobile-friendly"
    id="AddAddress1"
    tabindex="-1"
    aria-labelledby="AddAddressLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content border-radius15">
        <div class="modal-header text-right">
          <button
            type="button"
            class="close ml-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h6
            class="modal-title weight-bold text-color-444 mx-auto"
            id="AddAddressLAbel"
          >
            {{ mode === "create" ? "افزودن آدرس جدید" : "ویرایش آدرس" }}
          </h6>
        </div>
        <div class="modal-body text-center px-4">
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form
              id="address-form"
              @submit.prevent="(e) => handleSubmit(() => submitAddress(e))"
            >
              <b-row>
                <b-col md="6" v-if="provinces != null" class="text-right">
                  <b-form-group label="استان" label-align="right">
                    <multiselect
                      v-model="address.province"
                      :allow-empty="false"
                      placeholder="استان"
                      open-direction="bottom"
                      :options="provinces"
                      label="name"
                      track-by="id"
                      :searchable="true"
                      :multiple="false"
                      :close-on-select="true"
                      :show-labels="false"
                      :disabled="disabled"
                    >
                      <template slot="noOptions">
                        موردی یافت نشد
                      </template>
                      <template slot="noResult">
                        موردی یافت نشد
                      </template>
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="text-right">
                  <ValidationProvider
                    v-if="cities != null"
                    name="city"
                    mode="passive"
                    class="mb-3 d-flex flex-column text-right"
                    rules="required"
                    v-slot="v"
                  >
                    <label for="">شهر :</label>
                    <div class="variety-title ">
                      <multiselect
                        v-model="address.city"
                        :allow-empty="false"
                        placeholder="شهر"
                        open-direction="bottom"
                        :options="cities"
                        label="name"
                        track-by="id"
                        :searchable="true"
                        :multiple="false"
                        :close-on-select="true"
                        :show-labels="false"
                        :disabled="disabled"
                      >
                        <template slot="noOptions">
                          موردی یافت نشد
                        </template>
                        <template slot="noResult">
                          موردی یافت نشد
                        </template>
                      </multiselect>
                    </div>
                    <!-- <input name="categories" @change="v.validate" type="hidden"
                        :value="selected_category != null ? selected_category : ''"> -->
                    <div v-if="address.city != null">
                      <input
                        name="city"
                        @change="v.validate"
                        type="hidden"
                        :value="address.city.id"
                      />
                    </div>
                    <span class="text-right text-danger mt-2 mx-2">{{
                      v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col md="6" class="text-right">
                  <ValidationProvider
                    name="first_name"
                    mode="passive"
                    rules="required"
                    v-slot="v"
                  >
                    <label for="">نام :</label>
                    <input
                      v-model="address.first_name"
                      :disabled="disabled"
                      name="first_name"
                      @change="v.validate"
                      type="text"
                      placeholder="نام"
                      class="border-radius7 form-control"
                      :class="v.errors[0] ? 'border-danger' : '' "
                    />
                    <span class="text-right text-danger mt-2 mx-2">{{
                      v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col md="6" class="text-right">
                  <ValidationProvider
                    name="last_name"
                    mode="passive"
                    rules="required"
                    v-slot="v"
                  >
                    <label for="">نام خانوادگی :</label>
                    <input
                      v-model="address.last_name"
                      :disabled="disabled"
                      name="last_name"
                      @change="v.validate"
                      type="text"
                      placeholder="نام خانوادگی"
                      class="border-radius7 form-control"
                      :class="v.errors[0] ? 'border-danger' : '' "
                    />
                    <span class="text-right text-danger mt-2 mx-2">{{
                      v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col md="6" class="text-right">
                  <ValidationProvider
                    name="postal_code"
                    mode="passive"
                    rules="required"
                    v-slot="v"
                  >
                    <label for="">کد پستی :</label>
                    <input
                      v-model="address.postal_code"
                      :disabled="disabled"
                      name="postal_code"
                      @change="v.validate"
                      type="text"
                      placeholder="کد پستی"
                      class="border-radius7 form-control"
                      :class="v.errors[0] ? 'border-danger' : '' "
                    />
                    <span class="text-right text-danger mt-2 mx-2">{{
                      v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col md="6" class="text-right">
                  <ValidationProvider
                    name="mobile"
                    mode="passive"
                    rules="required"
                    v-slot="v"
                  >
                    <label for="">موبایل :</label>
                    <input
                      v-model="address.mobile"
                      :disabled="disabled"
                      name="mobile"
                      @change="v.validate"
                      type="text"
                      placeholder="موبایل"
                      class="border-radius7 form-control"
                      :class="v.errors[0] ? 'border-danger' : '' "
                    />
                    <span class="text-right text-danger mt-2 mx-2">{{
                      v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
                <b-col md="12" class="text-right">
                  <ValidationProvider
                    name="address"
                    mode="passive"
                    rules="required"
                    v-slot="v"
                    class="form-group"
                  >
                    <label for="">آدرس :</label>
                    <textarea
                      v-model="address.address"
                      :disabled="disabled"
                      name="address"
                      @change="v.validate"
                      type="text"
                      class="border-radius7 form-control"
                      :class="v.errors[0] ? 'border-danger' : '' "
                    ></textarea>
                    <span class="text-right text-danger mt-2 mx-2">{{
                      v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <div class="text-center mt-3">
                <button
                  class="btn"
                  :disabled="disabled"
                  :class="mode == 'create' ? 'btn-success' : 'btn-primary'"
                  type="submit"
                >
                  {{ mode == "create" ? "ثبت" : "ویرایش" }} آدرس
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddressModalImpl from '@@/core/components/address/AddressModal/AddressModalImpl'
export default {
  extends: AddressModalImpl
}
</script>
<style src="@@/core/components/address/AddressModal/AddressModalStylePublic.css"></style>
