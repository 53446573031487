import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from '@@/core/mixins/mixins'
import FormModal from "@@/core/libraries/form/FormModal";
import {BTooltip, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput, VBToggle, VBModal} from "bootstrap-vue";

export default {
  name: "Admin",
  components: {
    FormModal,
    Loading,
    BTooltip, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput
  },
  directives: {
    'b-toggle': VBToggle, 'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/admin',
      widgets: [
          'roles'
        ],
        fields: [{
            key: 'id',
            label: 'شناسه'
          },
          {
            key: 'name',
            label: 'نام'
          },
          {
            key: 'username',
            label: 'نام کاربری'
          },
          {
            key: 'email',
            label: 'ایمیل'
          },
          {
            key: 'mobile',
            label: 'شماره تماس'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          {
            key: 'edit',
            label: 'ویرایش'
          },
          {
            key: 'delete',
            label: 'حذف'
          },
        ],
        filter: {
          name: '',
          username: ''
        },
      }
    },
    mounted() {
      this.loadItems()
    },
    methods: {
      async filterItem(page = 1) {
        this.searchParams = {
          search1: this.filter.name,
          searchBy1: 'name',
          search2: this.filter.username,
          searchBy2: 'username',
        }
      }
    }
  }
