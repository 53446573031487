import Back from '@@/core/components/shared/Back/Back'
import SetForm from '@@/core/components/set/SetForm/SetForm'

    export default {
        components: {
            Back,
            SetForm
        },
        data() {
            return {
                url: '',
                axiosCancelSource: null,
                set: {
                    name:'',
                    products: []
                },
                products: [],
            }
        },
        watch: {
            products(newVal) {
                newVal.map((product,index) => {
                    product.index = index
                    product.label = "شناسه : " + product.id + ' | ' +
                    product.title
                    return product;
                });
                return newVal;
            },
        },
        mounted() {
            this.loadProducts()
            if (this.$route.params.id) {
                this.url = 'admin/product_sets/' + this.$route.params.id
                this.getProductSet()
            } else {
                this.$refs.setForm.setDisabled(false)
                this.url = 'admin/product_sets'
            }
        },
        methods: {
            async loadProducts() {
                try {
                    const res = await this.$axios.get('admin/products?all=1')
                    this.products = res.data.data.products
                } catch (error) {
                    this.$root.notify(error)
                }
            },
            async getProductSet() {
                try {
                    this.axiosCancelSource = this.$axios.CancelToken.source();
                    const response = await this.$axios.get(this.url, {
                        cancelToken: this.axiosCancelSource.token
                    })
                    this.set = response.data.data.product_set
                    this.$refs.setForm.setDisabled(false)
                } catch (error) {
                    if (this.$axios.isCancel(error)) {
                        return;
                    }
                    this.$root.notify(error)
                }
            }
        }
    }
