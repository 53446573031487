<template>
  <div class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title">کمپین فروش</h5>
    </div>
    <div class="card-body">

    </div>
  </div>
</template>

<script>
export default {
  name: "ProductFlashConfig"
}
</script>

<style scoped>

</style>