<template>
  <main v-if="visit != null" class="mt-5 bg-transparent">
    <div class="card mt-3">
      <div class="card-header justify-content-between">
        <h3 id="visitDetailTitle">
          {{
            `مشاهده آمار بازدید های سایت در تاریخ :${new Date(
              $route.params.date
            ).toLocaleDateString("fa")}`
          }}
        </h3>
        <router-link class="btn btn-primary" to="/admin/reports/visit">
          بازگشت به لیست
        </router-link>
      </div>
      <div class="card-body">
        <b-table
          @sort-changed="sortChanged"
          :style="{ opacity: disabled ? '0.6' : 1 }"
          bordered
          responsive
          striped
          v-if="visit"
          :fields="fields"
          :items="visit"
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(date)="data">
            {{ data.item.hour }}
          </template>
          <template v-slot:cell(visit)="data">
            {{ data.item.count }}
          </template>
        </b-table>
        <div v-else-if="visit == ''" class="alert alert-danger">
          موردی یافت تشد
        </div>
        <div v-else class="alert alert-primary">در حال بارگزاری</div>
        <!-- <pagination
      :router="true"
      v-if="visit && visit != ''"
      :disabled="disabled"
      :limit="2"
      :data="visit"
      @pagination-change-page="changePage"
    /> -->
      </div>
    </div>
  </main>
</template>

<script>
import { BTable } from "bootstrap-vue";
export default {
  name: "CustomersReport",
  components: {
    BTable,
  },
  data() {
    return {
      page: 1,
      previousPage: 1,
      per_page: 50,
      disabled: true,
      visit: null,
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "hour",
          label: "ساعت",
          sortable: false,
        },
        {
          key: "count",
          label: "تعداد نفرات",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    per_page() {
      this.changePage(1);
    },
  },
  created() {
    this.page = this.$route.query.page ?? 1;
    this.loadData();
  },
  methods: {
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData();
    },
    async loadData() {
      try {
        this.disabled = true;
        const response = await this.$axios.get(
          `admin/siteviews/${this.$route.params.date}`
        );

        this.visit = response.data.data.site_views;
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
