<template>
  <div class="border rounded mt-4">
    <h5 class="bg-light text-center rounded py-2 font-bold">
      اطلاعات دریافت کننده
    </h5>
    <div class="row px-4 pb-4">
      <div class="col-md-4 d-flex mt-2" v-if="order.address">
        <h6 class="font-bold">نام و نام خانوادگی :</h6>
        <h6 class="pr-1">
          {{ order.address.first_name + " " + order.address.last_name }}
        </h6>
      </div>
      <div class="col-md-4 d-flex mt-2 align-items-baseline" v-if="order.address">
        <h6 class="font-bold">موبایل :</h6>
        <h6 class="pr-1">
          {{ order.address.mobile }}
        </h6>
        <div class="whatsapp_links d-flex flex-row px-1 align-items-center" style="gap: 8px;">
            <a target="_blank" :href="'whatsapp://send?phone=98' + order.address.mobile.substring(1)" class="badge badge-pill badge-success-light position-relative">
            <span class="font-weight-bold"> <i class="fa fa-whatsapp fs-19 px-1 rounded-circle" style="
    position: absolute;
    bottom: -8px;
    right: -8px;
"></i>اپلیکیشن</span>
              </a> 
          <a target="_blank" :href="'https://web.whatsapp.com/send?phone=98' + order.address.mobile.substring(1)" class="badge badge-pill badge-success-light position-relative">
            <span class="font-weight-bold"> <i class="fa fa-whatsapp fs-19 px-1 rounded-circle" style="
    position: absolute;
    bottom: -8px;
    right: -8px;
"></i>وب</span>
            </a> 
        
        </div>
      </div>
      <div class="col-md-4 d-flex mt-2" v-if="order.address">
        <h6 class="font-bold">کد پستی :</h6>
        <h6 class="pr-1">
          {{ order.address.postal_code }}
        </h6>
      </div>
      <div class="col-md-12 d-flex mt-2" v-if="order.address">
        <h6 class="font-bold">آدرس :</h6>
        <h6 class="pr-1">
                <span v-if="order.address.city"
                >{{
                    order.address.city.province
                        ? order.address.city.province.name + " - "
                        : ""
                  }}
                  {{ order.address.city.name + " - " }}</span
                >
          <span>{{ order.address.address }}</span>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderAddress",
  props: {
    order: Object
  }
}
</script>

<style scoped>

</style>
