import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from "@@/core/mixins/mixins";
import {BTooltip, BTable, BModal} from "bootstrap-vue";

export default {
  name: "contact",
  components: {
    Loading,
    BTooltip, BTable, BModal
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/contacts",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "subject",
          label: "موضوع",
        },
        {
          key: "body",
          label: "متن",
        },
        {
          key: "name",
          label: "نام",
        },
        {
          key: "phone_number",
          label: "شماره همراه",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "show",
          label: "مشاهده",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
      showItem(index) {
      let item = this.items.data[index]
      console.log('ss');
      item.index = index
      this.edit = item
      if(this.edit.status == 0) {
          this.readContact()
      } else {
          this.$root.$emit('bv::show::modal', 'modal-show-contact')
      }
    },
    async readContact() {
        this.disabled = true
        try {
            const response = await this.$axios.post(`admin/contacts/${this.edit.id}/read`)
            this.edit.status = 1
            this.items.data[this.edit.index].status = 1
            this.$root.$emit('bv::show::modal', 'modal-show-contact')
        } catch(error) {
            this.$root.notify(error)
        }
        this.disabled = false
    }
  },
};
