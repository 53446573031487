<template>
  <main class="mt-5">
    <AttributesInfoModal :attributes-info="attributesInfo" />
    <div class="card mt-3">
      <div class="card-header justify-content-between border-0">
        <h5 class="card-title font-bold py-3">گزارش سفارشات(تکی)</h5>
        <ExcelDownload :extraQuery="extraQuery" url="admin/reports/pretty_orders" />
      </div>
      <div class="card-body">
        <OrderReportFilter v-model="filter" :disabled="disabled" class="card p-4 row" style="flex-direction: row" />
        <SelectPageCount :count="reports ? reports.total : false" v-model="per_page" />
        <b-table @sort-changed="sortChanged" :style="{opacity: disabled ? '0.6' : 1}" bordered responsive striped
                 v-if="reports" :fields="fields" :items="reports.data" :disabled="disabled">
          <template v-slot:cell(index)="data">
            {{ data.index + 1 + (!disabled ? (page - 1) * per_page: (previousPage - 1) * per_page)  }}
          </template>
          <template v-slot:cell(id)="data">
            <a :href="'/admin/order/' + data.item.id" target="_blank">{{ data.item.id }}</a>
          </template>
          <template v-slot:cell(_total)="data">
            {{ data.item._total | price }}
          </template>
          <template v-slot:cell(mobile)="data">
            <router-link :to="'/admin/customers/show/' + data.item.customer.id">{{ data.item.customer.mobile }}</router-link>
          </template>
          <template v-slot:cell(attributes)="data">
            <button @click="showAttributesInfo(data.item)" class="btn btn-secondary" style="line-height: 0.8;">جزییات</button>
          </template>
          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
        </b-table>
        <div v-else-if="reports == ''" class="alert alert-danger">موردی یافت تشد</div>
        <div v-else class="alert alert-primary">در حال بارگزاری</div>
        <pagination
            :router="true"
            v-if="reports && reports.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="reports"
            @pagination-change-page="changePage" />
      </div>
    </div>
  </main>
</template>

<script>
import {BTable} from 'bootstrap-vue'
import {getStatusLabel, getStatusColor} from "@@/core/libraries/adminFunctions";
import OrderStatusesView from "@@/core/components/report/OrderStatusesView";
import KeyValues from "@@/core/components/report/KeyValues";
import SelectPageCount from "@@/core/components/shared/SelectPageCount/SelectPageCount";
import AttributesInfoModal from "@@/core/components/report/AttributesInfoModal";
import OrderReportFilter from "@@/core/components/report/OrderReportFilter";
import orderReportFilterMixin from "@@/core/components/report/orderReportFilterMixin";
import {priceFilter} from "@@/core/libraries/filters";
import sortMixin from "@@/core/libraries/mixins/sortMixin";
import ExcelDownload from "@@/core/components/excelDownload/ExcelDownload.vue";
export default {
  name: "OrdersReport",
  components: {
    OrderReportFilter,
    AttributesInfoModal,
    SelectPageCount,
    KeyValues, OrderStatusesView, BTable, ExcelDownload},
  mixins: [orderReportFilterMixin, sortMixin],
  data() {
    return {
      ordersInfo: null,
      attributesInfo: null,
      page: 1,
      previousPage: 1,
      per_page: 50,
      disabled: true,
      reports: null,
      fields: [
        {
          key: 'index',
          label: 'ردیف'
        },
        {
          key: 'id',
          label: 'شناسه سفارش',
          sortable: true,
        },
        {
          key: 'attributes',
          label: 'ویژگی'
        },
        {
          key: 'order_items_count',
          label: 'تعداد قلم',
          sortable: true,
        },
        {
          key: 'discount_amount',
          label: 'تخفیف',
          formatter: priceFilter,
          sortable: true,
        },
        {
          key: 'shipping_amount',
          label: 'هزینه حمل و نقل',
          formatter: priceFilter,
          sortable: true,
        },
        {
          key: 'total',
          label: 'جمع کل',
          formatter: priceFilter,
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'تاریخ',
          sortable: true,
        },
      ]
    }
  },
  watch: {
    per_page() {
      this.changePage(1)
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    getStatusLabel,
    getStatusColor,
    showAttributesInfo(report) {
      this.attributesInfo = report.attribute_info
      this.$root.$emit('bv::show::modal', 'attributes-info')
    },
    changePage(page = 1) {
      this.previousPage = this.page;
      this.page = page;
      this.loadData()
    },
    async loadData() {
      try {
        this.disabled = true;
        const response = await this.$axios.get('admin/reports/pretty_orders',{
          params: {
            page: this.page,
            per_page: this.per_page,
            ...this.getFilters(),
            ...this.getSortFilters()
          }
        })
        this.reports = response.data.data.reports;
      } catch (e) {
        this.$root.notify(e)
      } finally {
        this.disabled = false;
      }
    }
  },
  computed: {
    extraQuery() {
      return {
        ...this.getFilters()
      }
    }
  },
}
</script>


