import Back from '@@/core/components/shared/Back/Back'
import flashForm from '@@/core/components/flash/FlashForm/FlashForm'

export default {
  components: {
    Back,
    flashForm,
  },
  data() {
    return {
      title: "",
      url: "",
      axiosCancelSource: null,
      flash: {
        title: "",
        start_date: "",
        end_date: "",
        discount_type: { value: "flat", name: "مبلغ" },
        discount: 0,
        status: 1,
        products: [],
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.url = "admin/flashes/" + this.$route.params.id;
      this.getFlash();
    } else {
      this.$refs.flashForm.setDisabled(false);
      this.url = "admin/flashes";
    }
  },
  methods: {
    async getFlash() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(this.url, {
          cancelToken: this.axiosCancelSource.token,
        });
        this.flash = response.data.data.flash;
        // this.flash.start_date.split('-').join('/')

        this.$refs.flashForm.setDisabled(false);
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error);
      }
    },
  },
};
