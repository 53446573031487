<template>
  <main class="mt-5">
    <span id="add-btn">
      <button
        :disabled="hasPermission('write_store') ? false : true"
        type="button"
        tabindex="-1"
        class="btn btn-success"
        @click="incrementModal()"
      >
        افزایش
      </button>
      <button
        :disabled="hasPermission('write_store') ? false : true"
        type="button"
        tabindex="-1"
        class="btn btn-danger mr-2"
        @click="decrementModal()"
      >
        کاهش
      </button>
    </span>
    <b-tooltip
      v-if="!hasPermission('write_store')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <p v-if="false" id="storeReport">
          <strong>
            تعداد اقلام موجود در انبار:
          </strong>
          <span>{{ 10000 }}</span>
          <svg
            fill="#004bff"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="16"
            height="16"
            viewBox="0 0 416.979 416.979"
            xml:space="preserve"
          >
            <g>
              <path
                d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85
		c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786
		c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576
		c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765
		c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
              />
            </g>
          </svg>
          <strong>
            مبلغ کل موجودی:
          </strong>
          <span> {{ 1500000 }} ریال </span>
        </p>
        <h5 class="card-title font-bold">انبار</h5>
        <div style="flex:1"></div>
        <b-button
          v-b-toggle.filter
          :variant="hasFilter() ? 'secondary' : 'primary'"
        >
          <span class="fe fe-search pt-1" style="font-size: 17px;"></span>
        </b-button>
      </div>
      <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="row mt-5">
              <ProductSearch
                :clearData="clearDataProductSearch"
                class="col-md-12 row"
                :selectable="true"
                :disabled="disabled"
                @selectProduct="selectProduct"
                @selectVariety="selectVariety"
              />
              <b-form-group class="col-md-4">
                <multiselect
                  placeholder="نوع تغییرات"
                  v-model="filter.type"
                  open-direction="bottom"
                  :options="typeOptions"
                  label="title"
                  track-by="value"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :disabled="disabled"
                >
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-4">
                <date-picker
                  placeholder="از تاریخ"
                  v-model="filter.start_date"
                  format="YYYY-MM-DD"
                  display-format="jYYYY-jMM-jDD"
                />
              </b-form-group>
              <b-form-group class="col-md-4">
                <date-picker
                  placeholder="تا تاریخ"
                  v-model="filter.end_date"
                  format="YYYY-MM-DD"
                  display-format="jYYYY-jMM-jDD"
                />
              </b-form-group>
            </div>
            <div class="text-center mt-3">
              <button
                :disabled="disabled"
                type="submit"
                class="btn btn-primary px-5"
              >
                جستجو
              </button>
              <button
                :disabled="disabled"
                @click="resetFilter"
                class="btn btn-warning px-5 mr-3"
              >
                برداشتن فیلتر
              </button>
              <button
                :disabled="disabled"
                @click="$root.$emit('bv::toggle::collapse', 'filter')"
                class="btn btn-secondary px-5 mr-3"
              >
                بستن
              </button>
            </div>
          </form>
        </b-card>
      </b-collapse>
      <div class="card-body">
        <div>
          <b-table
            responsive
            hover
            bordered
            :items="items.data"
            :fields="fields"
          >
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(product)="data">
              <span
                v-if="
                  data.item.store &&
                    data.item.store.variety &&
                    data.item.store.variety.product
                "
              >
                {{ data.item.store.variety.product.title }}
                {{
                  data.item.store.variety.color
                    ? " - " + data.item.store.variety.color.name
                    : ""
                }}
                <template v-if="data.item.store.variety.attributes.length > 1">
                  <span
                    v-for="attr in data.item.store.variety.attributes"
                    :key="attr.id"
                  >
                    {{ attr.pivot ? " - " + attr.pivot.value : "" }}
                  </span>
                </template>
                <span v-else>
                  {{
                    data.item.store.variety.attributes[0] &&
                    data.item.store.variety.attributes[0].pivot
                      ? " - " +
                        data.item.store.variety.attributes[0].pivot.value
                      : ""
                  }}
                </span>
              </span>
            </template>
            <template v-slot:cell(type)="data">
              <span
                v-if="data.item.type == 'increment'"
                class="badge badge-success status"
                >افزایش</span
              >
              <span v-else class="badge badge-danger status">کاهش</span>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
    <StoreModal :mode="mode" v-model="store" />
  </main>
</template>
<script>
import StoreImpl from "@@/core/pages/Store/StoreImpl";
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";
export default {
  components: { ProductSearch },
  extends: StoreImpl,
};
</script>
<style>
#storeReport {
  position: absolute;
  left: 0;
  top: -36px;
  background: white;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}
p#storeReport span {
  color: #000078;
  font-size: 18px;
}
#storeReport svg {
  animation: puls 1.5s linear infinite;
}
@keyframes puls {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}
@media (min-width: 768px) {
}
</style>
