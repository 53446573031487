<template>
  <div class="form-container">
    <b-form ref="form" @submit.prevent="submit">
      <Loading v-if="!inputs.length" />
      <template v-for="input in inputs">
        <Input :disabled="disabled" :label="input.label"
               :required="input.required"
               :key="input.name" :name="input.name" :type="input.type" v-model="input.value" :items="input.items"
               :select_label="input.select_label" :id="input.id" :custom_selects="input.customSelects"
               :options="input.options"
        />
      </template>
      <b-button type="submit" :disabled="disabled" v-if="inputs.length" variant="primary" v-text="mode==='create' ? 'ثبت' : 'ویرایش'" />
    </b-form>
  </div>
</template>

<script>
import Loading from '@@/core/components/shared/Loading/Loading'
import formMixin from "@@/core/libraries/form/mixins/formMixin";
import {BButton, BForm} from "bootstrap-vue";

export default {
  name: 'Form',
  components: {Loading,BButton, BForm},
  mixins: [formMixin]
}
</script>

<style scoped>

</style>
