import {BRow, BCol, BFormGroup} from "bootstrap-vue";

export default {
  name: "InputGroup",
  components: {
    BRow, BCol, BFormGroup
  },
  props: {
    label: String,
    required: {
      type: Boolean,
      default: false,
    },
    customClass: {
      default: '',
      type: String
    },
    col1: {
      default: 2,
      type: [String, Number]
    },
    col2: {
      default: 10,
      type: [String, Number]
    },
    labelPadding: {
      default: null,
      type: String
    }
  },
  methods: {
    labelClicked() {
      this.$emit('label-click')
    }
  }
}
