<template>
  <main class="mt-5 shipping">
    <span id="add-btn">
      <button :disabled="hasPermission('write_shipping') ? false : true" @click="newShipping" v-b-modal.shipping-modal
        class="btn btn-success"> حمل و نقل جدید </button>
    </span>
    <b-tooltip v-if="!hasPermission('write_shipping')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold"> حمل و نقل </h5>
      </div>
      <div class="card-body">
        <table :style="disabled ? 'opacity: 0.8' : ''" class="table table-responsive-sm table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col"><i class="fas fa-arrows-alt-v"></i> شناسه</th>
              <th scope="col">نام</th>
              <th scope="col">لوگو</th>
              <th scope="col">مبلغ پیش فرض(تومان)</th>
              <!-- <th scope="col">حداقل زمان ارسال (روز) </th> -->
              <th scope="col">وضعیت</th>
              <th scope="col">شهرها</th>
              <th scope="col">تاریخ ثبت</th>
              <th scope="col">ویرایش</th>
              <th scope="col">حذف</th>
            </tr>
          </thead>
          <draggable v-model="shippings" tag="tbody" @change="sortShipping" v-bind="dragOptions">
            <tr class='flip-list' v-for="(item, index) in shippings" :key="item.id">
              <td scope="row">{{ item.id }}</td>
              <td scope="row">{{ item.name }}</td>
              <td scope="row">
                <a v-if="item.logo" :href="item.logo.url" target="_blank">
                  <ImageHolder style="max-height: 70px" v-if="item.logo">
                    <img :src="item.logo.url">
                  </ImageHolder>
                </a>
              </td>
              <td scope="row">{{ item.default_price | price }}</td>
              <!-- <td scope="row">{{item.minimum_delay}}</td> -->
              <td scope="row">
                <span v-if="item.status == 1 " class="badge badge-success status">فعال</span>
                <span v-else-if="item.status == 0" class="badge badge-danger status">غیرفعال</span>
              </td>
              <td scope="row">
                <router-link :to="{name: 'shipping.cities' , params: {id: item.id}}" class="btn btn-warning">
                  <i class="fe fe-align-justify"></i>
                </router-link>
              </td>
              <td scope="row">{{ item.created_at | persianDate }}</td>
              <td>
                <span :id="'edit-btn-' + item.id">
                  <a :disabled="!hasPermission('modify_shipping') ? true : false " class="action-btns1  pt-1 px-2"
                    @click="editItem(item.id , index)">
                    <i class="feather feather-edit  text-primary"></i>
                  </a>
                </span>
                <b-tooltip v-if="!hasPermission('modify_shipping')" :target="'edit-btn-' + item.id" triggers="hover">
                  شما به این بخش دسترسی ندارید
                </b-tooltip>
              </td>
              <td>
                <span :id="'delete-btn-' + item.id">
                  <a :disabled="!hasPermission('delete_shipping') ? true : false " class="action-btns1  pt-1 px-2 "
                    @click="deleteItem(item.id)">
                    <i class="feather feather-trash-2 text-danger"></i>
                  </a>
                </span>
                <b-tooltip v-if="!hasPermission('delete_shipping')" :target="'delete-btn-' + item.id" triggers="hover">
                  شما به این بخش دسترسی ندارید
                </b-tooltip>
              </td>
            </tr>
          </draggable>
        </table>
        <Loading v-if="shippings == null" />
        <div class="alert alert-danger text-right" v-if="shippings == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </div>
    </div>
    <shippingModal :mode="mode" :shipping="shipping" :url="submit_url" :value="publicValue" />
  </main>
</template>
<script>
import ShippingImpl from '@@/core/pages/shipping/Shipping/ShippingImpl'
export default {
  extends: ShippingImpl
}
</script>