<template>
  <div>
    <button :disabled="disabled" class="btn btn-outline-light" data-toggle="dropdown" aria-expanded="false">
      <span v-if="value == option.name" v-for="option in options">{{ option.label }}</span>
      <i
          class="feather feather-chevron-down"></i> </button>
    <ul class="dropdown-menu dropdown-menu-left" role="menu">
      <li @click="select(option.name)" v-for="option in options"><a style="cursor: pointer">{{ option.label }}</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    value: [String, Number],
    options: Array,
    disabled: {
      default: false, type: Boolean
    }
  },
  methods: {
    select(name) {
      if (!this.disabled) {
        this.$emit('input', name)
      }
    }
  }
}
</script>

<style scoped>

</style>
