import {Treeselect} from '@riophae/vue-treeselect'

export default {
  name: "TreeselectAddable",
  components: {
    Treeselect
  },
  props: {
    value: Array,
    options: {
      default: () => ([]),
      type: Array
    },
    placeholder: String,
    customClass: {
      default: '',
      type: String
    },
    hideOptions: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      realValue: this.value,
      oldValue: [],
      oldOldValue: [],
      uniqueId: 'addable-' + this.$root.uniqueId()
    }
  },
  watch: {
    value(newVal) {
      this.realValue = newVal
    },
    'realValue': function (newVal, oldVal) {
      this.$emit('input', newVal)
      if (Array.isArray(oldVal) && newVal.length < oldVal.length) {
        this.oldValue = newVal
      } else {
        this.oldValue = oldVal
      }
    },
    oldValue(newVal, oldVal) {
      this.oldOldValue = oldVal
    }
  },
  mounted() {
    this.oldValue = this.value
    const input = document.querySelector('.' + this.uniqueId + ' input');
    input.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        this.onSubmit();
      }
    })
  },
  methods: {
    onSubmit() {
      this.realValue = this.oldOldValue
      const input = document.querySelector('.' + this.uniqueId + ' .vue-treeselect__sizer').innerHTML
      let alreadyExist = this.options.find(opt => opt.label == input);
      if (!alreadyExist) {
        this.$emit('add-option', {
          id: input,
          name: input,
          label: input
        })
      }

      this.$nextTick(() => {
        this.selectValue(input)
      })
      this.$refs.treeselect.resetSearchQuery()
    },
    selectValue(value) {
      if (value === '') {
        return
      }
      let found = false;
      this.realValue.forEach((item) => {
        if (item == value) {
          found = true;
        }
      })
      if (!found) {
        let c = [...this.realValue]
        c.push(value)
        this.$emit('input', c)
      }
    },
    selected(val) {
      this.realValue = this.realValue.filter(item => {
        return item != val;
      })
    }
  },
}
