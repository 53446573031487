import Loading from '@@/core/components/shared/Loading/Loading'
import ImageHolder from '@@/core/components/shared/ImageHolder/ImageHolder'
import mixins from '@@/core/mixins/mixins'
import {BTable, BTooltip} from "bootstrap-vue";

export default {
  name: "Flash",
  components: {
    Loading,
    ImageHolder,
    BTable, BTooltip
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/flashes',
      fields: [{
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'title',
          label: 'عنوان'
        },
        {
          key: 'image',
          label: 'تصویر'
        },
        {
          key: 'start_date',
          label: 'تاریخ شروع'
        },
        {
          key: 'end_date',
          label: 'تاریخ پایان'
        },
        {
          key: 'status',
          label: 'وضعیت'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      filter: {
        name: '',
        group: '',
        public: null,
        required: null,
        show_filter: null,
        status: null
      },
      status_options: [{
        name: 'فعال',
        value: '1'
      },
        {
          name: 'غیرفعال',
          value: '0'
        },
        {
          name: 'همه',
          value: ''
        },
      ],
    }
  },
  mounted() {
    this.loadItems()
  },
}
