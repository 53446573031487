<template>
  <main>
    <b-card class="mt-5">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">جزئیات سبد خرید {{ id }}</h5>
        <div style="flex:1"></div>
        <Back url="/admin/reports/sms-reports" />
      </div>
      <div class="card-body">
        <b-table
          id="main-table"
          v-if="reportDetail"
          bordered
          responsive
          striped
          :fields="fields"
          :items="reportDetail"
        >
          <template v-slot:cell(id)="data">
            {{ data.item.id }}
          </template>
          <template v-slot:cell(variety_id)="data">
            {{ data.item.variety_id ? data.item.variety_id : "" }}
          </template>
          <template v-slot:cell(variety_name)="data">
            {{
              data.item.variety && data.item.variety.product
                ? data.item.variety.product.title
                : ""
            }}
          </template>
          <template v-slot:cell(variety_color)="data">
            {{
              data.item.variety && data.item.variety.color
                ? data.item.variety.color.name
                : ""
            }}
          </template>
          <template v-slot:cell(created_at)="data">
            <span v-if="data.item.created_at">{{
              data.item.created_at | persianDate
            }}</span>
          </template>
        </b-table>
        <div v-else class="alert alert-primary">در حال بارگزاری</div>
      </div>
    </b-card>
  </main>
</template>
<script>
import { BButton, BCard, BTable } from "bootstrap-vue";
import Back from "@@/core/components/shared/Back/Back";

export default {
  components: {
    BButton,
    BCard,
    Back,
    BTable,
  },
  data() {
    return {
      disabled: false,
      reportDetail: null,
      id: this.$route.params.id,
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "variety_id",
          label: "شناسه تنوع",
        },
        {
          key: "variety_name",
          label: "نام تنوع",
        },
        {
          key: "variety_color",
          label: "رنگ تنوع",
        },
        {
          key: "created_at",
          label: "تاریخ",
        },
      ],
    };
  },
  created() {
    this.loadData();
  },

  methods: {
    async loadData() {
      try {
        this.disabled = true;
        const response = await this.$axios.get(`admin/reports/cart/${this.id}`);
        this.reportDetail = response.data.data.cartReportItems;
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
