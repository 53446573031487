<template>
  <div class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title" >توضیحات</h5>
    </div>
    <div class="card-body pt-0">
      <b-form-group v-if="$options.configProvider.get('product.hasShortDescription', true)" class="mt-5 mb-5">
        <h6>توضیحات کوتاه</h6>
        <b-form-textarea v-model="realValue.shortDescription" cols="20" rows="5"></b-form-textarea>
      </b-form-group>
      <h6 v-if="$options.configProvider.get('product.hasShortDescription', true)">توضیحات کامل</h6>
      <Ckeditor id="product_description" v-model="realValue.description" />
    </div>
  </div>
</template>
<script>
import ProductDescriptionImpl from '@@/core/components/product/ProductDescription/ProductDescriptionImpl'
export default {
  extends: ProductDescriptionImpl
}
</script>

<style scoped>

</style>
