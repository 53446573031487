<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold"> تراکنش های کیف پول</h5>
        <div style="flex:1"></div>
        <b-button v-b-toggle.filter :variant="hasFilter() ? 'secondary' : 'primary'">
          <span class="fe fe-search pt-1" style="font-size: 17px;"></span>
        </b-button>
      </div>
      <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="row mt-5">
              <b-form-group label="فقط پرداخت شده توسط کاربر" class="col-md-12">
                <b-form-checkbox unchecked-value="0" v-model="filter.deposit" />
              </b-form-group>
              <b-form-group class="col-md-6">
                <date-picker placeholder="از تاریخ" v-model="filter.start_date" format="YYYY-MM-DD"
                             display-format="jYYYY-jMM-jDD" />
              </b-form-group>
              <b-form-group class="col-md-6">
                <date-picker placeholder="تا تاریخ" v-model="filter.end_date" format="YYYY-MM-DD"
                             display-format="jYYYY-jMM-jDD" />
              </b-form-group>
            </div>
            <div class="text-center mt-3">
              <button :disabled="disabled" type="submit" class="btn btn-primary px-5">جستجو</button>
              <button :disabled="disabled" @click="resetFilter" class="btn btn-warning px-5 mr-3">برداشتن
                فیلتر</button>
              <button :disabled="disabled" @click="$root.$emit('bv::toggle::collapse', 'filter')"
                      class="btn btn-secondary px-5 mr-3">بستن</button>
            </div>
          </form>
        </b-card>
      </b-collapse>
      <div class="card-body">
        <b-table  v-if="items.data"
                  hover
                  bordered
                  responsive
                  :items="items.data"
                  :fields="transactionsFields"
        >
          <template v-slot:cell(amount)="data">
            {{data.item.amount | price}}
          </template>
          <template v-slot:cell(type)="data">
            <span class="badge badge-success" v-if="data.item.type == 'deposit'">افزایش کیف پول</span>
            <span class="badge badge-danger" v-else-if="data.item.type == 'withdraw'">برداشت از کیف پول</span>
          </template>
          <template v-slot:cell(tracking_code)="data">
            <span v-if="data.item.deposit && data.item.deposit.active_payment">
              {{ data.item.deposit.active_payment.tracking_code }}
            </span>
            <span v-else>
              –
            </span>
          </template>
          <template v-slot:cell(confirmed)="data">
            <span class="badge badge-success" v-if="data.item.confirmed">موفقیت آمیز</span>
            <span class="badge badge-danger" v-else>ناموفق</span>
          </template>
          <template v-slot:cell(description)="data">
            {{data.item.meta ? data.item.meta.description : ''}}
          </template>
          <template v-slot:cell(created_at)="data">
            {{data.item.created_at | persianDate}}
          </template>
        </b-table>
        <pagination v-if="items.data" :disabled="disabled" :limit="2" :data="items"
                    @pagination-change-page="changePage">
        </pagination>
        <div v-else class="alert alert-danger">
          موردی یافت نشد
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import WalletImpl from "@@/core/pages/Wallet/WalletImpl";

export default {
  name: "Wallet",
  extends: WalletImpl
}
</script>

<style scoped>

</style>
