<template>
  <b-modal id="order-edit" hide-footer title="ویرایش سفارش">
    <form @submit.prevent="submit()">
      <b-form-group v-if="order.customer">
        <div class="d-flex justify-content-between mb-1">
          <label for=""> آدرس <i class="text-danger">*</i></label>
        </div>

        <multiselect
          v-model="order.address"
          :allow-empty="false"
          placeholder="انتخاب کنید"
          open-direction="bottom"
          :options="addresses"
          label="label"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
          @input="setShipping($event)"
        >
          <template slot="noOptions">
            موردی یافت نشد
          </template>
          <template slot="noResult">
            موردی یافت نشد
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group v-if="order.address">
        <div class="d-flex justify-content-between mb-1">
          <label class="pb-1" for="">
            حمل و نقل <i class="text-danger">*</i></label
          >
          <!-- <span
            >هزینه ارسال :
            <span v-if="getShippingPrice"
              >{{ getShippingPrice | priceinput }} تومان</span
            >
            <span v-else>رایگان</span>
          </span> -->
        </div>
        <multiselect
          v-model="order.shipping"
          :allow-empty="false"
          placeholder="انتخاب کنید"
          open-direction="bottom"
          :options="addressShippings"
          label="name"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions">
            موردی یافت نشد
          </template>
          <template slot="noResult">
            موردی یافت نشد
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group>
        <label class="pb-1" for=""> تخفیف (تومان) </label>
        <b-form-input
          :value="order.discount_amount | priceinput"
          @input="
            (value) => {
              order.discount_amount = value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
        >
        </b-form-input>
      </b-form-group>
      <b-form-group>
        <label class="pb-1" for=""> توضیحات </label>
        <b-form-textarea
          v-model="order.description"
          name="description"
        ></b-form-textarea>
      </b-form-group>
      <div class="text-center mt-3">
        <button class="btn btn-primary" :disabled="disabled">
          ویرایش
        </button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import OrderEditImpl from '@@/core/components/order/OrderEdit/OrderEditImpl'
export default {
  extends: OrderEditImpl
}
</script>
