<template>
  <div class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title">اطلاعات سئو</h5>
    </div>
    <div class="card-body">
      <b-form-group>
        <h6>عنوان متا</h6>
        <b-form-input v-model="realValue.metaTitle" type="text" placeholder="عنوان متا"/>
      </b-form-group>
      <b-form-group>
        <h6>توضیحات متا</h6>
        <b-form-textarea placeholder="توضیحات متا" v-model="realValue.metaDescription" rows="4"></b-form-textarea>
      </b-form-group>
<!--      <b-form-group>-->
<!--        <h6>نامک(اسلاگ)</h6>-->
<!--        <b-form-input v-model="realValue.slug" type="text" placeholder="نامک"/>-->
<!--      </b-form-group>-->
    </div>
  </div>
</template>

<script>
import ProductSeoImpl from '@@/core/components/product/ProductSeo/ProductSeoImpl'
export default {
  extends: ProductSeoImpl
}
</script>

<style scoped>

</style>
