import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from '@@/core/mixins/mixins'
import {BTooltip, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput, VBToggle} from "bootstrap-vue";
import draggable from "vuedraggable";

export default {
  name: "Specification",
  components: {
    Loading,
    BTooltip, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput,
    draggable
  },
  directives: {
    'b-toggle': VBToggle
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/specifications?all=1',
      fields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'name',
          label: 'نام'
        },
        {
          key: 'label',
          label: 'لیبل'
        },
        {
          key: 'status',
          label: 'وضعیت'
        },
        {
          key: 'group',
          label: 'گروه'
        },
        {
          key: 'public',
          label: 'عمومی'
        },
        {
          key: 'required',
          label: 'الزامی'
        },
        {
          key: 'show_filter',
          label: 'نمایش در فیلتر'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      filter: {
        name: '',
        group: '',
        public: null,
        required: null,
        show_filter: null,
        status: null
      },
      status_options: [
        {
          name: 'فعال',
          value: '1'
        },
        {
          name: 'غیرفعال',
          value: '0'
        },
        {
          name: 'همه',
          value: ''
        },
      ],
      hasPagination: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: this.disabled,
        ghostClass: "ghost"
      }
    }
  },
  mounted() {
    this.loadItems()
  },
  watch: {
    'filter.status': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.status = this.status_options.find(item => {
          return item.value == newVal
        })
      }
    },
    'filter.show_filter': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.show_filter = this.status_options.find(item => {
          return item.value == newVal
        })
      }
    },
    'filter.public': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.public = this.type_options.find(item => {
          return item.value == newVal
        })
      }
    },
    'filter.required': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.required = this.type_options.find(item => {
          return item.value == newVal
        })
      }
    },
  },
  methods: {
    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.name,
        searchBy1: 'name',
        search2: this.filter.group,
        searchBy2: 'group',
        search3: this.filter.public ? this.filter.public.value : '',
        searchBy3: 'public',
        search4: this.filter.required ? this.filter.required.value : '',
        searchBy4: 'required',
        search5: this.filter.show_filter ? this.filter.show_filter.value : '',
        searchBy5: 'show_filter',
        search6: this.filter.status ? this.filter.status.value : '',
        searchBy6: 'status'
      }
    },
    async sortSpecifications() {
      let orders = [];

      this.items.forEach((item) => {
        orders.push(item.id)
      });
      try {
        this.disabled = true;
        const response = await this.$axios.post('admin/specifications/sort', {
          group: this.group,
          ids: orders,
        })
        this.$root.notify(response.data.message, 'success')
        // window.swal({
        //     title: response.data.message,
        // });
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    }
  }
}
