<template>
  <td>
    <div  class="item" v-for="(r,index) in row" :key="r.model.id">
      <template v-if="r.type === 'attribute'">
        <span v-if="r.model.type === 'select'"><span v-if="index !== 0"> - </span>{{ r.values[r.value].value }}</span>
        <span v-else><span v-if="index !== 0"> - </span>{{ r.values[r.value] }}</span>
      </template>
      <template v-else>
        <span><span v-if="index !== 0"> - </span>{{ colors.find(c => c.id === r.values[r.value]).name  }}</span>
      </template>
    </div>
  </td>
</template>

<script>
import VarietyRowImpl from '@@/core/components/product/varieties/VarietyRow/VarietyRowImpl'
export default {
  extends: VarietyRowImpl
}
</script>

<style scoped src="@@/core/components/product/varieties/VarietyRow/VarietyRowStyle.css"></style>
