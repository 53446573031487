import MenuItem from '@@/core/components/sidebar/MenuItem/MenuItem'
import {BFormInput} from "bootstrap-vue";

export default {
  components: {
    MenuItem, BFormInput
  },
  data() {
    return {
      empty: false,
      search: false,
      searchMenu: []
    }
  },
  computed: {
    menus() {
      return this.$store.state.admin.menus
    }
  },
  watch: {
    '$route.path'(newValue) {
      this.setActiveMenu()
    }
  },
  mounted() {
    this.setActiveMenu()
  },
  methods: {
    setActiveMenu() {
      window.$(".app-sidebar li a").each(function () {
        var pageUrl = window.location.href.split(/[?#]/)[0];
        if (this.href == pageUrl) {
          // window.$(this).addClass("active");
          // window.$(this).parent().addClass("is-expanded");
          // window.$(this).parent().parent().prev().addClass("active");
          // window.$(this).parent().parent().addClass("open");
          // window.$(this).parent().parent().prev().addClass("is-expanded");
          // window.$(this).parent().parent().parent().addClass("is-expanded");
          // window.$(this).parent().parent().parent().parent().addClass("open");
          // window.$(this).parent().parent().parent().parent().prev().addClass("active");
          // window.$(this).parent().parent().parent().parent().parent().addClass("is-expanded");
          // window.$(this).parent().parent().parent().parent().parent().parent().prev().addClass("active");
          // window.$(this).parent().parent().parent().parent().parent().parent().parent().addClass(
          //     "is-expanded");
        }
      });
    },
    menuSearch(e) {
      let filtered = []
      this.search = true
      if (this.$store.state.admin.menus.filter(el => el.title.includes(e)) != '') {
        filtered = this.$store.state.admin.menus.filter(el => el.title.includes(e))
        this.searchMenu = filtered
      } else {
        this.searchMenu = []
        this.$store.state.admin.menus.forEach(m => {
          if (m.children.filter(ch => ch.title.includes(e)) != '') {
            this.searchMenu.push(m)
          } else {
            m.children.forEach(item => {
              if (item.children.filter(i => i.title.includes(e)) != '') {
                this.searchMenu.push(m)
              }
            })
          }
        });
      }
    }
  }
}
