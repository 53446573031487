<template>
  <div class="row">
    <div class="col-md-4 d-flex align-items-center">
      <h6 class="font-bold">شناسه :</h6>
      <h6 class="pr-1">{{ order.id }}</h6>
    </div>
    <div class="col-md-4 d-flex align-items-center">
      <h6 class="font-bold">تاریخ ثبت :</h6>
      <h6 class="pr-1">{{ order.created_at | persianDate }}</h6>
    </div>
    <div class="col-md-4 d-flex align-items-center">
      <h6 class="font-bold">مشتری :</h6>
      <h6 class="pr-1">
        {{ order.customer ? order.customer.full_name : "" }}
        <button
          class="btn btn-light py-0"
          @click="showCustomer(order.customer)"
        >
          <i class="fe fe-user"></i>
        </button>
      </h6>
    </div>
    <div class="col-md-4 d-flex align-items-center mt-3">
      <h6 class="font-bold">وضعیت سفارش :</h6>
      <h6 class="pr-1 d-flex">
        <order-status :status="order.status"></order-status>
        <span
          id="edit-order-status"
          class="badge badge-light cursor-pointer py-0 mr-1"
          @click="updateOrderStatus"
        >
          <i class="fe fe-edit " style="font-size: 16px; margin-top:3px"></i>
        </span>
        <b-tooltip target="edit-order-status" triggers="hover">
          ویرایش وضعیت سفارش
        </b-tooltip>
      </h6>
    </div>


    <div class="col-md-4 d-flex mt-3">
      <h6 class="font-bold">تاریخ تحویل :</h6>
      <h6 class="pr-1" v-if="order.delivered_at">
        {{ order.delivered_at | persianDate }}
      </h6>
      <h6 class="pr-1" v-else>-</h6>
    </div>
    <div class="col-md-4 d-flex mt-3">
      <h6 class="font-bold">شیوه ارسال :</h6>
      <h6 class="pr-1">
        {{ order.shipping ? order.shipping.name : "" }}
      </h6>
    </div>
    <div
      v-if="order.status == 'failed'"
      class="col-md-4 d-flex align-items-center mt-3"
    >
      <h6 class="font-bold">دلیل خطا :</h6>
      <h6 class="pr-1">
        {{ order.status_detail }}
      </h6>
    </div>
    <div v-if="order.status == 'reserved'" class="col-md-4 d-flex mt-3">
      <h6 class="font-bold">مشاهده سفارش اصلی :</h6>
      <h6  class="pr-1 d-flex">
        <router-link
          class="badge badge-primary"
          :to="`/admin/order/${order.reserved_id}`"
        >
          {{
            order.reserved_id
          }}
        </router-link>
      </h6>
    </div>
    <div class="col-md-4 d-flex align-items-center mt-3">
      <h6 class="font-bold">رزرو شده :</h6>
      <h6 class="pr-1">
        <i
          v-if="order.reserved == 1"
          class="fe fe-check text-success"
          style="font-size: 23px !important;"
        ></i>
        <i
          v-else
          class="fe fe-x text-danger"
          style="font-size: 20px !important;"
        ></i>
      </h6>
    </div>

    <div class="col-md-8 d-flex mt-2">
      <h6 class="font-bold">توضیحات :</h6>
      <h6 class="pr-1">
        {{ order.description ? order.description : "ندارد" }}
      </h6>
    </div>
  </div>
</template>

<script>
import OrderInfoImpl from "@@/core/components/order/OrderInfo/OrderInfoImpl";

export default {
  extends: OrderInfoImpl,
};
</script>

<style scoped></style>
