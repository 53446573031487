<template>
  <form @submit.prevent="submit" style="min-height: 500px;">
    <div class="row">
      <b-form-group class="col-md-12">
        <label for="title"> عنوان <i class="text-danger">*</i></label>
        <b-form-input
          id="title"
          name="title"
          :value="flash.title"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-6" label="" label-for="start-date">
        <label for=""> تاریخ شروع <i class="text-danger">*</i></label>
        <date-picker
          type="datetime"
          v-model="flash.start_date"
          format="YYYY-MM-DD HH:mm"
          display-format="jYYYY-jMM-jDD HH:mm"
        />
      </b-form-group>
      <b-form-group class="col-md-6">
        <label for="end-date"> تاریخ پایان <i class="text-danger">*</i></label>
        <date-picker
          type="datetime"
          v-model="flash.end_date"
          format="YYYY-MM-DD HH:mm"
          display-format="jYYYY-jMM-jDD HH:mm"
        />
      </b-form-group>
      <b-form-group
        v-if="mode == 'create'"
        class="col-md-6"
        label=""
        label-for=""
      >
        <label for="type"> نوع <i class="text-danger">*</i></label>
        <multiselect
          v-model="flash.discount_type"
          :allow-empty="false"
          placeholder="انتخاب کنید"
          open-direction="bottom"
          :options="discountTypes"
          label="name"
          track-by="value"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
          @input="flash.discount = 0"
        >
          <template slot="noOptions">
            موردی یافت نشد
          </template>
          <template slot="noResult">
            موردی یافت نشد
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group
        class="col-md-6"
        v-if="
          mode == 'create' &&
            flash.discount_type &&
            flash.discount_type.value == 'flat'
        "
      >
        <label for="flat">
          مبلغ تخفیف (تومان) <i class="text-danger">*</i></label
        >
        <b-form-input
          :value="flash.discount | priceinput"
          @input="
            (value) => {
              flash.discount = value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        class="col-md-6"
        v-else-if="
          mode == 'create' &&
            flash.discount_type &&
            flash.discount_type.value == 'percentage'
        "
      >
        <label for="percentage"> درصد تخفیف <i class="text-danger">*</i></label>
        <b-form-input id="percentage" :value="flash.discount"></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-6">
        <label for="type"> تصویر <i class="text-danger">*</i></label>
        <!-- <b-form-file placeholder="فایلی انتخاب نشده" v-model="files" accept="image/*"  @change="addImages($event)"></b-form-file> -->
        <b-form-file
          placeholder="فایلی انتخاب نشده"
          name="image"
          accept="image/*"
        ></b-form-file>
      </b-form-group>
      <b-form-group class="col-md-6">
        <label for="type"> تصویر موبایل</label>
        <!-- <b-form-file placeholder="فایلی انتخاب نشده" v-model="files" accept="image/*"  @change="addImages($event)"></b-form-file> -->
        <b-form-file
          v-if="$options.configProvider.get('flash.hasMobileImage')"
          placeholder="فایلی انتخاب نشده"
          name="mobile_image"
          accept="image/*"
        ></b-form-file>
      </b-form-group>
      <div class="col-md-6">
        <b-form-group class="mt-5">
          <b-form-checkbox
            id="status"
            :disabled="disabled"
            v-model="flash.status"
            name="status"
            value="1"
            unchecked-value="0"
          >
            <span class="pr-5">وضعیت</span>
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="col-md-12 mt-2">
        <label for="type"> محصولات <i class="text-danger">*</i></label>
        <multiselect
          v-model="productSelect"
          placeholder="انتخاب کنید"
          open-direction="bottom"
          :options="products"
          label="title"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
          @input="setDiscount"
        >
          <template slot="noOptions">
            موردی یافت نشد
          </template>
          <template slot="noResult">
            موردی یافت نشد
          </template>
        </multiselect>
      </div>
      <b-table
        v-if="flash.products != ''"
        class="mt-3"
        responsive
        hover
        bordered
        :items="flash.products"
        :fields="fields"
      >
        <template v-slot:cell(id)="data">
          {{ data.item.id }}
          <input
            type="hidden"
            :name="'products[' + data.index + '][id]'"
            :value="data.item.id"
          />
        </template>
        <template v-slot:cell(pure_price)="data">
          {{ (data.item.price + data.item.discount) | priceinput }} تومان
        </template>
        <template v-slot:cell(quantity)="data">
          <b-form-input
            placeholder="تعداد"
            v-model="data.item.pivot.salable_max"
          ></b-form-input>
          <input
            type="hidden"
            :name="'products[' + data.index + '][quantity]'"
            :value="data.item.pivot.salable_max"
          />
        </template>
        <template v-slot:cell(sales)="data">
      {{ data.item.pivot.sales_count }}  
        </template>
        <template v-slot:cell(discount_type)="data">
          <b-form-select
            v-model="data.item.pivot.discount_type"
            @input="data.item.pivot.discount = 0"
          >
            <b-form-select-option value="flat">مبلغ</b-form-select-option>
            <b-form-select-option value="percentage">درصد</b-form-select-option>
          </b-form-select>
          <input
            type="hidden"
            :name="'products[' + data.index + '][discount_type]'"
            :value="data.item.pivot.discount_type"
          />
        </template>
        <template v-slot:cell(discount)="data">
          <div v-if="data.item.pivot.discount_type == 'flat'">
            <b-form-input
              placeholder="مبلغ تخفیف (تومان)"
              class="d-inline"
              :value="data.item.pivot.discount | price"
              @input="
                (value) => {
                  data.item.pivot.discount =
                    value !== '' ? +value.replace(/\D/g, '') : '';
                }
              "
            >
            </b-form-input>
          </div>
          <div v-else-if="data.item.pivot.discount_type == 'percentage'">
            <b-form-input
              placeholder="درصد تخفیف"
              class="d-inline"
              id="percentage"
              v-model="data.item.pivot.discount"
            ></b-form-input>
          </div>
          <input
            type="hidden"
            :name="'products[' + data.index + '][discount]'"
            :value="data.item.pivot.discount"
          />
        </template>
        <template v-slot:cell(shipment_free)="data">
          <b-form-checkbox
            size="md"
            switch
            id="`shipment_free_product-${data.item.id}`"
            v-model="data.item.pivot.shipment_free"
            :name="'products[' + data.index + '][shipment_free]'"
            value="1"
            unchecked-value="0"
          >
            ارسال رایگان
            <b
              :class="{
                'text-success': data.item.pivot.shipment_free == 1,
                'text-warning': data.item.pivot.shipment_free != 1,
              }"
              >( {{ data.item.pivot.shipment_free == 1 ? "بله" : "خیر" }} )</b
            >
          </b-form-checkbox>
        </template>
        <template v-slot:cell(delete)="data">
          <a class="action-btns1  pt-1 px-2 " @click="deleteItem(data.index)">
            <i class="feather feather-trash-2 text-danger"></i>
          </a>
        </template>
      </b-table>

      <div class="col-md-12 text-center mt-5">
        <button :disabled="disabled" type="submit" class="btn btn-success">
          {{ mode == "edit" ? " ویرایش کمپین" : " ثبت کمپین" }}
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import FlashFormImpl from "@@/core/components/flash/FlashForm/FlashFormImpl";
export default {
  extends: FlashFormImpl,
};
</script>
