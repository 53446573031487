import Menu from '@@/core/components/sidebar/Menu/Menu'
import configProvider from "@@/core/configProvider";

export default {
  name: "Sidebar",
  configProvider,
  components: {
    Menu,
  }
}
