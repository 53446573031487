<template>
  <div @click="showImageModal" ref="imgHolder" class="img-holder" :class="showImage ? 'img-show' : 'img'">
      <slot @load="fixHeight"></slot>
  </div>
</template>

<script>
import ImageHolderImpl from '@@/core/components/shared/ImageHolder/ImageHolderImpl'
export default {
  extends: ImageHolderImpl
}
</script>

<style scoped src="@@/core/components/shared/ImageHolder/ImageHolderStyle.css"></style>
<style  src="@@/core/components/shared/ImageHolder/ImageHolderStylePublic.css"></style>
