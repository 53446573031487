import {BFormGroup, BFormInput, BFormTextarea} from "bootstrap-vue";

export default {
  name: "ProductSeo",
  components: {
    BFormGroup, BFormInput, BFormTextarea
  },
  props: {
    value: Object,
  },
  data() {
    return {
      realValue: this.value,
    }
  },
  watch: {
    value(newVal) {
      this.realValue = newVal
    },
    realValue(newVal) {
      this.$emit('input', newVal)
    },
  },
}
