import {BModal} from "bootstrap-vue";

export default {
  components: {BModal},
  props: {
    customer: Object
  },
  methods: {
    newWallet() {
      this.$emit('new-wallet')
    }
  }
}
