<template>
    <b-modal id="edit-modal" title="ویرایش نظر" :ok-disabled="disabled" :cancel-disabled="disabled" @ok="submit"
        class="in-form-modal" ok-title="ثبت" cancel-title="لغو">
        <form @submit.prevent="submit">
            <b-form-group>
                <label for="name"> نام : </label>
                <b-form-input id="name" ref="name" :value="comment.name"></b-form-input>
            </b-form-group>
            <b-form-group>
                <label for="email"> ایمیل : </label>
                <b-form-input id="email" ref="email" :value="comment.email"></b-form-input>
            </b-form-group>
            <b-form-group>
                <label for="body"> نظر : </label>
                <b-form-textarea id="body" ref="body" :value="comment.body"></b-form-textarea>
            </b-form-group>
            <b-form-group>
                <label for="body"> وضعیت : </label>
                <multiselect v-model="comment.status" placeholder="انتخاب کنید" :allow-empty="false"
                    open-direction="bottom" :options="statusOptions" label="name" track-by="value" :searchable="false"
                    :show-labels="false" :disabled="disabled">
                    <template slot="noOptions">
                        موردی یافت نشد
                    </template>
                    <template slot="noResult">
                        موردی یافت نشد
                    </template>
                </multiselect>
            </b-form-group>
        </form>
    </b-modal>
</template>
<script>
   import PostCommentEditImpl from '@@/core/components/post/PostCommentEdit/PostCommentEditImpl'
   export default {
       extends: PostCommentEditImpl
   }
</script>