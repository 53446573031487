<template>
  <main>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">گزارش پیامک (سبد خرید)</h5>
        <div style="flex:1"></div>
        <b-button :disabled="disabled" v-b-toggle.filter variant="primary"
          ><i class="fe fe-search pt-1"></i
        ></b-button>
        <Back class="mr-3 px-3 py-2" url="/admin/reports/sms-report" />
      </div>
      <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form autocomplete="chrome-off" @submit.prevent="submitFilter">
            <div class="row align-items-center mt-5">
              <div class="col-6">
                <DatePickerRange v-model="filter.date" />
              </div>
              <b-form-group class="col-6 mt-4">
                <b-form-input
                  type="number"
                  placeholder="جستجو بر اساس شناسه کاربر"
                  v-model="filter.search"
                />
              </b-form-group>
            </div>
            <div class="text-center mt-4">
              <button
                :disabled="disabled"
                type="submit"
                class="btn btn-primary px-5"
              >
                جستجو
              </button>
              <button
                :disabled="disabled"
                @click="$root.$emit('bv::toggle::collapse', 'filter')"
                class="btn btn-secondary px-5 mr-3"
              >
                بستن
              </button>
            </div>
          </form>
        </b-card>
      </b-collapse>

      <div class="card-body">
        <b-table
          id="main-table"
          v-if="cartReports && cartReports.data"
          bordered
          striped
          :fields="fields"
          :items="cartReports.data"
          responsive="sm"
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(id)="data">
            {{ data.item.id }}
          </template>
          <template v-slot:cell(customer)="data">
            {{
              data.item.customer && data.item.customer.full_name
                ? data.item.customer.full_name
                : ""
            }}
          </template>
          <template v-slot:cell(customer_id)="data">
            {{ data.item.customer_id }}
          </template>
          <template v-slot:cell(send_at)="data">
            <span v-if="data.item.send_at">{{
              data.item.send_at | persianDate
            }}</span>
          </template>
          <template v-slot:cell(seen_at)="data">
            <span v-if="data.item.seen_at">{{
              data.item.seen_at | persianDate
            }}</span>
          </template>
          <template v-slot:cell(buy_at)="data">
            <span v-if="data.item.buy_at">{{
              data.item.buy_at | persianDate
            }}</span>
          </template>
          <template v-slot:cell(details)="data">
            <router-link
              :to="`/admin/reports/sms-reports/${data.item.id}`"
              class="btn btn-primary"
              style="line-height: 0.8;"
            >
              جزئیات
            </router-link>
          </template>
        </b-table>

        <div v-else class="alert alert-primary">در حال بارگزاری</div>
        <pagination
          v-if="cartReports && cartReports.data != ''"
          :router="true"
          :disabled="disabled"
          :limit="2"
          :data="cartReports"
          @pagination-change-page="changePage"
        />
      </div>
    </div>
  </main>
</template>
<script>
import {
  BTable,
  BFormGroup,
  BFormInput,
  BCollapse,
  VBToggle,
  VBTooltip,
  BButton,
  BCard,
} from "bootstrap-vue";
import Back from "@@/core/components/shared/Back/Back";
import DatePickerRange from "@@/core/components/shared/DatePickerRange/DatePickerRange";

class Filter {
  constructor() {
    this.search = null;
    this.date = {
      startDate: "",
      endDate: "",
    };
  }
  set(filters) {
    Object.entries(filters).forEach(([index, value]) => {
      this[index] = value;
    });
  }
}

export default {
  name: "SmsReport",
  components: {
    BTable,
    DatePickerRange,
    BFormGroup,
    BFormInput,
    BCollapse,
    VBToggle,
    VBTooltip,
    BButton,
    BCard,
    Back,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      disabled: false,
      cartReports: null,
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "customer_id",
          label: "شناسه کاربر",
        },
        {
          key: "customer",
          label: "کاربر",
        },
        {
          key: "send_at",
          label: "تاریخ ارسال",
        },
        {
          key: "seen_at",
          label: "تاریخ مشاهده",
        },
        {
          key: "buy_at",
          label: "تاریخ خرید",
        },
        {
          key: "details",
          label: "جزئیات",
        },
      ],
      totalRows: 0,
      per_page: 0,
      currentPage: 1,
      previousPage: 1,
      sortBy: null,
      sortDesc: null,
      filter: new Filter(),
      appliedFilter: new Filter(),
    };
  },
  watch: {
    per_page() {
      this.changePage(1);
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    submitFilter() {
      this.loadData();
    },
    changePage(page = 1) {
      this.previousPage = this.currentPage;
      this.currentPage = page;
      this.loadData();
    },
    async loadData() {
      try {
        this.appliedFilter.set(this.filter);
        this.disabled = true;
        const response = await this.$axios.get("admin/reports/cart-detail", {
          params: {
            from_date: this.appliedFilter.date.startDate,
            to_date: this.appliedFilter.date.endDate,
            customer_id: this.appliedFilter.search,
            page: this.currentPage,
          },
        });
        this.cartReports = response.data.data.cartReport;
        this.totalRows = response.data.data.cartReport.total;
        this.perPage = response.data.data.cartReport.per_page;
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
